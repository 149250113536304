// React and related router imports
import React, { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// Material-UI components and hooks
import { Box, Typography, Link, Stack, Avatar, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

// Firebase imports
import { arrayUnion, doc, updateDoc, arrayRemove, collection, where, query, getDocs, serverTimestamp, addDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

// Local components and context/hooks
import ProfileImageUpload from '../Input/ProfileImageUpload';
import ScrollFooterBarAIAndPerson from '../Notification/ScrollFooterBarAIAndPerson';
import { useSnackbar } from '../../Context/SnackbarContext';
import { useUser } from '../../Context/UserContext';
import RegistrationDialog from '../Input/RegistrationDialog';

const CompanyTopNavigationContent = ({ company }) => {
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);

    const theme = useTheme(); // テーマを取得
    const location = useLocation(); // 現在のロケーション情報を取得
    const navigate = useNavigate(); // ルーティング用の関数
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();

    const hasEditPermission = user?.userType === 'company' && user?.companyId === company?.id;
    const isBookmarked = user?.companyBookMarks?.includes(company?.id); // ブックマーク済みかどうかを判定
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const savePath = `company_avatar/${company?.id}/avator.png`;

    // アクティブなリンクのスタイル
    const activeLinkStyle = {
        color: '#0288d1', // アクティブなリンクの色を青に
        textDecoration: 'none',
        fontWeight: 'bold',
    };

    // 通常のリンクのスタイル
    const linkStyle = {
        textDecoration: 'none',
        color: 'inherit',
        fontWeight: 'bold',
    };

    // リンクがアクティブかどうかを判断し、適切なスタイルを返す関数
    const getLinkStyle = (path) => location.pathname === path ? activeLinkStyle : linkStyle;

    // ブックマークの追加または削除
    const toggleBookmark = async () => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }
        const userDocRef = doc(firestore, "users", user.documentId);

        try {
            await updateDoc(userDocRef, {
                companyBookMarks: isBookmarked ? arrayRemove(company?.id) : arrayUnion(company?.id)
            });
        } catch (error) {
            console.error("ブックマークの更新に失敗しました: ", error);
        }
    };

    // チャットドキュメントを追加する関数
    const startOrJoinChat = async ({ chatTag }) => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }

        // ユーザーと企業ID、そしてchatTagにマッチするチャットを検索
        const q = query(collection(firestore, 'chats'),
            where('participantIds', 'array-contains', user.uid),
            where('companyId', '==', company.id),
            where('chatTag', '==', chatTag),
            where('archived', '==', false) // 既に終了していないチャットのみを対象にする
        );

        const querySnapshot = await getDocs(q);

        // 既存のチャットが見つかった場合、そのチャットに移動
        if (!querySnapshot.empty) {
            // 最初のドキュメントにアクセス
            const firstDoc = querySnapshot.docs[0];
            // ドキュメントのIDを取得
            const chatId = firstDoc.id;
            // ドキュメントIDを使用して特定のチャットページにナビゲート
            navigate(`/chat/${chatId}`);
            showSnackbar({ message: "既存のチャットに参加しました", type: 'success' });
            return;
        } else {
            // AI担当者タグがなく、かつ指定の条件に一致するユーザーを検索
            let participantIds = [];
            let participants = [];
            if (chatTag !== 'AI担当者') {
                //ここに権限を追加することで，権限に応じた検索を行える
                const companyUsersQuery = query(collection(firestore, 'users'),
                    where('userType', '==', 'company'),
                    where('companyId', '==', company.id));
                const usersSnapshot = await getDocs(companyUsersQuery);

                usersSnapshot.forEach(doc => {
                    participantIds.push(doc.id);
                    participants.push({ "userId": doc.id, "userType": doc.data().userType });
                });
            }
            // ログインしているユーザーも追加
            participantIds.push(user.uid);
            participants.push({ "userId": user.uid, "userType": user.userType });

            // 既存のチャットがない場合、新しいチャットを作成
            const chatData = {
                participantIds: participantIds,
                participants: participants,
                companyId: company.id, // 企業のIDを保存
                companyRef: doc(firestore, `companies/${company.id}`), // 企業ドキュメントへの参照
                chatTag: chatTag,
                avatarSrc: company?.iconUrl ?? null,
                companyDisplaySrc: user.userIconUrl ?? null,
                lastMessage: '',
                lastMessageTimestamp: serverTimestamp(),
                archived: false,
                startedBy: { "userId": user.uid, "userType": user.userType },
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            };

            // 新しいチャットドキュメントを追加し、追加されたドキュメントの参照を取得
            const docRef = await addDoc(collection(firestore, 'chats'), chatData);
            // 追加されたドキュメントのIDを取得
            const chatId = docRef.id;
            // ドキュメントIDを使用して特定のチャットページにナビゲート
            navigate(`/chat/${chatId}`);
            showSnackbar({ message: "新しいチャットを開始しました", type: 'success' });
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
            setImageModalOpen(true);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            gap: { xs: 2, md: 0 },
            mb: 2,
            flexDirection: 'column',
        }}>
            <ProfileImageUpload
                imageModalOpen={imageModalOpen}
                setImageModalOpen={setImageModalOpen}
                image={image}
                width={250}
                height={250}
                savePath={savePath}
                collectionName={"companies"}
                docId={company?.id}
                saveUrlFieldName={"iconUrl"}
                saveUrlStateFieldName={"hasIcon"}
            />

            <RegistrationDialog
                open={showRegistrationDialog}
                onClose={() => setShowRegistrationDialog(false)}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                {hasEditPermission && (
                    <input
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                )}
                <label htmlFor="icon-button-file">
                    <Avatar sx={{
                        mr: 3,
                        width: 56,
                        height: 56,
                        ...(hasEditPermission && {
                            transition: 'transform .5s ease',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }),
                    }}
                        aria-label="upload picture"
                        component="span"
                        src={company?.iconUrl}
                    >
                        {company?.iconUrl ? <img src={company?.logoUrl} alt="company logo" /> : company?.name?.charAt(0)}
                    </Avatar>
                </label>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: { xs: 'flex-end', md: '' } }}>
                    <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>{company?.name}</Typography>
                    <Link href={company?.websiteUrl} target="_blank" rel="noopener" style={{ textDecoration: 'none' }}>
                        <Typography variant="caption" color='text.secondary'>{company?.websiteUrl}</Typography>
                    </Link>
                </Box>
            </Box>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/dashboard`} style={getLinkStyle(`/company/${company?.id}/dashboard`)}>DASHBOARD</RouterLink>
                    </Typography>
                )}
                {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/search`} style={getLinkStyle(`/company/${company?.id}/search`)}>ユーザー検索</RouterLink>
                    </Typography>
                )}
                {hasEditPermission && (
                    <Typography variant="subtitle1">
                        <RouterLink to={`/company/${company?.id}/learning`} style={getLinkStyle(`/company/${company?.id}/learning`)}>学習</RouterLink>
                    </Typography>
                )}
                <Typography variant="subtitle1">
                    <RouterLink to={`/company/${company?.id}/home`} style={getLinkStyle(`/company/${company?.id}/home`)}>HOME</RouterLink>
                </Typography>
                <Typography variant="subtitle1">
                    <RouterLink to={`/company/${company?.id}/ours`} style={getLinkStyle(`/company/${company?.id}/ours`)}>OURS</RouterLink>
                </Typography>
                {/* <Typography variant="subtitle1">
                    <RouterLink to={`/company/${company?.id}/news`} style={getLinkStyle(`/company/${company?.id}/news`)}>NEWS</RouterLink>
                </Typography> */}
                {/* 以下のリンクも同様に設定 */}
                {user?.userType !== 'company' && !hasEditPermission && (
                    <IconButton
                        size="large"
                        sx={{ ml: 1, color: isBookmarked ? theme.palette.primary.main : 'inherit' }} // ブックマーク済みの場合は色を変更
                        onClick={toggleBookmark}
                    >
                        {isBookmarked ? <TurnedInIcon fontSize="inherit" /> : <TurnedInNotIcon fontSize="inherit" />}
                    </IconButton>
                )}
                {user?.userType !== 'company' && !hasEditPermission && (
                    <>
                        {isSmUp ? (
                            // smサイズ以上で表示したい内容
                            <>
                                <Button variant='contained' onClick={() => startOrJoinChat({ chatTag: 'AI担当者' })}>AIと話す</Button>
                                {company?.allowsDirectChat &&
                                    <Button variant='contained' onClick={() => startOrJoinChat({ chatTag: '企業担当者' })}>担当者と話す</Button>
                                }
                            </>
                        ) : (
                            // xsサイズで表示したい内容
                            <ScrollFooterBarAIAndPerson
                                avatarSrc={company?.logoUrl}
                                company={company}
                                message={company?.allowsDirectChat ? 'AIや担当者と話すことができます' : 'この企業は直接チャットを許可していません。'}
                                startOrJoinChat={startOrJoinChat}
                            />
                        )}
                    </>
                )}
                {hasEditPermission && (
                    <Button variant='contained' onClick={() => { navigate(`/chat/null`); }}>Chatへ</Button>
                )}
            </Stack>
        </Box>
    );
}

export default CompanyTopNavigationContent;

