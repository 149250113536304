import React from 'react';
import { Box, Grid, FormGroup, Stack, Checkbox, FormControlLabel } from '@mui/material';
import IconTypography from './IconTypography';

const SectionWithCheckboxes =({ title, icon: IconComponent, options, checkedValues, onChange, name }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                    <IconTypography icon={IconComponent}>{title}</IconTypography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <FormGroup>
                        <Stack direction="row" spacing={0} flexWrap='wrap'>
                            {options.map((option) => (
                                <FormControlLabel
                                    key={option.value}
                                    control={
                                        <Checkbox
                                            checked={checkedValues.includes(option.value)}
                                            onChange={onChange}
                                            name={name}
                                            value={option.value}
                                        />
                                    }
                                    label={option.label}
                                />
                            ))}
                        </Stack>
                    </FormGroup>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SectionWithCheckboxes;
