import React from 'react';
import { Box, Grid, Pagination, Typography } from '@mui/material';

import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';
import DataCard from '../../components/Company/DataCard';

import devConfig from '../../config';
import NavigationBar from '../../components/Layout/NavigationBar';

const items = [
    // ここにアイテムのデータを配列として入れます
    // 例: { id: 1, title: "アイテム1", descrip弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…tion: "説明1", imageUrl: "/path/to/image" }
    { id: 1, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 2, title: "アイテム1", description: "弊弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 3, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 4, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 5, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 6, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 7, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 8, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 9, title: "アイテム1", description: "弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…弊社が運営するSNSメディアのマーケティング担当を募集しています。 Instagram・TikTokを含めて総フォロワー150万人とこれまで多くの人に美容コンテンツを届けてきました。今回は新規メディアとして立ち上がったばかりの「ペット…", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 10, title: "アイテム1", description: "説明1", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 11, title: "アイテム1", description: "説明1", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
    { id: 12, title: "アイテム1", description: "説明1", imageUrl: "https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
];

function CompanyNewsList() {
    const [page, setPage] = React.useState(1);
    const itemsPerPage = 9;

    const hasEditPermission = devConfig.hasEditPermission;

    const handlePageChange = (event, value) => {
        setPage(value);
        window.scrollTo(0, 0); // ページのトップにスクロールする
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <CompanyTopNavigationContent hasEditPermission={ hasEditPermission } />
            </ResponsiveContainer>
            <ResponsiveContainer>
                <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 'bold', mb: 1 }}>全てのNEWS</Typography>
                <Grid container spacing={4}>
                    {items
                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                        .map((item) => (
                            <Grid item key={item.id} xs={12}>
                                <DataCard item={item} />
                            </Grid>
                        ))}
                </Grid>


                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Pagination
                        count={Math.ceil(items.length / itemsPerPage)}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </ResponsiveContainer>
        </>
    );
}

export default CompanyNewsList;

