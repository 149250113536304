import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, CircularProgress, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { EmailAuthProvider, reauthenticateWithCredential, verifyBeforeUpdateEmail, sendEmailVerification } from "firebase/auth";
import { useSnackbar } from '../../Context/SnackbarContext';
import { auth } from '../../firebase';

const UpdateEmailDialog = ({ open, handleClose }) => {
    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        mode: 'onChange'
    });
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false); // ローディング状態の管理
    const [emailSent, setEmailSent] = useState(false); // メール送信成功の管理
    const [emailError, setEmailError] = useState(null); // エラーの管理
    const [isEmailVerified, setIsEmailVerified] = useState(false); // メール認証状態の管理
    const [resendLoading, setResendLoading] = useState(false); // 再送信時のローディング

    useEffect(() => {
        // ユーザーの認証状況をチェックする
        const checkEmailVerification = () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                setIsEmailVerified(currentUser.emailVerified); // メールが確認済みかどうか
            }
        };
        checkEmailVerification();
    }, []);

    const handleEmailUpdate = async ({ newEmail, password }) => {
        const currentUser = auth.currentUser;
        setLoading(true); // ローディング開始
        setEmailError(null); // 前回のエラーをリセット

        try {
            if (!isEmailVerified) {
                // メールが認証されていない場合、確認メールを送信
                await sendEmailVerification(currentUser);
                setEmailSent(true); // メール送信成功
                showSnackbar({
                    message: 'メールアドレスの確認が必要です。確認メールを送信しました。メールボックスをご確認ください。',
                    type: 'info'
                });
                setLoading(false);
                return;
            }

            // メールが認証されている場合、メールアドレスの更新処理を続行
            const credential = EmailAuthProvider.credential(currentUser.email, password);
            
            // 再認証を実行
            await reauthenticateWithCredential(currentUser, credential);

            // メールアドレスの更新
            await verifyBeforeUpdateEmail(currentUser, newEmail);

            // 新しいメールアドレスに確認メールを送信
            await sendEmailVerification(currentUser);
            await currentUser.reload();

            showSnackbar({ message: 'メールアドレスが更新され、新しい確認メールが送信されました。', type: 'success' });
        } catch (error) {
            console.error("メールアドレスの更新エラー:", error);
            setEmailError(error); // エラーをセット
            if (error.code === 'auth/invalid-credential') {
                showSnackbar({
                    message: '無効な資格情報です。もう一度ログインしてください。',
                    type: 'error'
                });
            } else if (error.code === 'auth/operation-not-allowed') {
                showSnackbar({
                    message: 'メールアドレスの更新が許可されていません。設定を確認してください。',
                    type: 'error'
                });
            } else {
                showSnackbar({
                    message: `メールアドレスの更新に失敗しました。エラー: ${error.message}`,
                    type: 'error'
                });
            }
        } finally {
            setLoading(false); // ローディング終了
        }
    };

    const resendVerificationEmail = async () => {
        const currentUser = auth.currentUser;
        setResendLoading(true);
        try {
            await sendEmailVerification(currentUser);
            showSnackbar({
                message: '確認メールを再送信しました。メールボックスをご確認ください。',
                type: 'info'
            });
        } catch (error) {
            console.error("確認メールの再送信エラー:", error);
            showSnackbar({
                message: `確認メールの再送信に失敗しました。エラー: ${error.message}`,
                type: 'error'
            });
        } finally {
            setResendLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={() => { reset(); handleClose(); }} sx={{ borderRadius: '10px' }}>
            <form onSubmit={handleSubmit(handleEmailUpdate)}>
                <DialogTitle>メールアドレスの更新</DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            '& .MuiTextField-root': { m: 1 },
                        }}
                    >
                        <TextField
                            label="現在のメールアドレス"
                            type="email"
                            fullWidth
                            value={!auth?.currentUser?.email ? 'メールアドレスが登録されていません' : auth.currentUser.email}
                            disabled
                        />
                        <TextField
                            label="新しいメールアドレス"
                            type="email"
                            fullWidth
                            {...register("newEmail", {
                                required: "新しいメールアドレスは必須です。",
                                pattern: {
                                    value: /^\S+@\S+\.\S+$/,
                                    message: "有効なメールアドレスを入力してください。",
                                },
                            })}
                            error={!!errors.newEmail}
                            helperText={errors.newEmail?.message}
                        />
                        <TextField
                            label="現在のパスワード"
                            type="password"
                            fullWidth
                            {...register("password", { required: "現在のパスワードは必須です。" })}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                        />
                    </Box>
                    {loading && (
                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                            <CircularProgress />
                            <Typography variant="body2" sx={{ ml: 2 }}>処理中...</Typography>
                        </Box>
                    )}
                    {emailSent && (
                        <Typography variant="body2" sx={{ mt: 2, color: 'green' }}>
                            確認メールが送信されました。メールボックスをご確認ください。
                        </Typography>
                    )}
                    {emailError && (
                        <Typography variant="body2" sx={{ mt: 2, color: 'red' }}>
                            エラーが発生しました。再試行するか、サポートに連絡してください。
                        </Typography>
                    )}
                    {!isEmailVerified && !loading && (
                        <Button onClick={resendVerificationEmail} disabled={resendLoading} variant='outlined'>
                            {resendLoading ? <CircularProgress size={24} /> : '確認メールを再送信'}
                        </Button>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { reset(); handleClose(); }}>キャンセル</Button>
                    <Button type="submit" variant='contained' disabled={!isValid || loading || !isEmailVerified}>更新</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpdateEmailDialog;
