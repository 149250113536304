import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { Box, Typography, TextField, Container, Button, Card, CardMedia, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { LoadingButton } from '@mui/lab';
import NotesIcon from '@mui/icons-material/Notes';

import { firestore, storage } from './../../firebase'
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

import { getImageUrl } from '../../utils/getImageUrl';
import useApi from '../../Hooks/useApi';

import { useSnackbar } from '../../Context/SnackbarContext';
import { useUser } from '../../Context/UserContext';

import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';
import LeftBottomFloatBtn from '../../components/Company/LeftBottomFloatBtn';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';

const getSectionTitle = (sectionKey) => {
    const titles = {
        whatWeDo: "何をやっているのか？",
        whyWeDoIt: "なぜやっているのか？",
        howWeDoIt: "どうやってやっているのか？",
    };
    return titles[sectionKey] || "未知のセクション";
};

const handleButtonClick = async ({ setText, companyInfo, companyId, postData, isLoading, sectionTitle, sectionKey, onTextChange }) => {

    let requestData = {};

    requestData = {
        text: `この企業は${sectionTitle}？ということを伝える文章を作成してください。`,
        company_id: companyId,
        company_name: companyInfo?.name,
        messages: [
            { "role": "system", "content": `あなたは${companyInfo?.name}の採用ページを作成するライターです` },
            { "role": "system", "content": "求職者が入社したいと思えるような会社であることを宣伝する簡潔な文言を作成します。" },
            { "role": "system", "content": "会社名は含めないようにしてください。" },
            { "role": "system", "content": 'ダブルクオテーションでキャッチフレーズを挟まない' },
            { "role": "assistant", "content": "combined_prompt_placeholder" },
            { "role": "user", "content": "user_input_placeholder" }
        ]
    };

    const responses = await postData(requestData);
    if (!isLoading) {
        setText(responses?.response?.choices[0]?.message?.content);
        onTextChange(sectionKey, responses?.response?.choices[0]?.message?.content);
    }
};

const SectionEditForm = ({ sectionKey, formData, onTextChange, setFormData, companyId, hasEditPermission, companyInfo, postData, isLoading, planName }) => {
    // Firestoreから画像URLを取得して、images配列を更新する
    const [images, setImages] = useState([]);
    const sectionData = formData[sectionKey];
    const { showSnackbar } = useSnackbar();
    const [text, setText] = useState(formData[sectionKey].text || '');

    const sectionTitle = getSectionTitle(sectionKey);

    useEffect(() => {
        const fetchImages = async () => {
            const promises = sectionData.images.map(async (image, index) => {
                if (image.filePath) {
                    const url = await getImageUrl(image.filePath);
                    return { ...image, previewUrl: url };
                }
                return image;
            });
            const updatedImages = await Promise.all(promises);
            setImages(updatedImages);
        };

        if (sectionData.images) {
            fetchImages();
        }
    }, [formData, sectionData.images]); // 依存配列をformDataに変更

    useEffect(() => {
        setText(sectionData.text);
    }, [sectionData]);

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    // 画像情報を更新する関数
    const updateImagesState = (newImages) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [sectionKey]: {
                ...prevFormData[sectionKey],
                images: newImages,
            }
        }));
    };

    // 画像変更時の処理
    const handleImageChange = async (event, index) => {
        if (event.target.files[0]) {
            const newFile = event.target.files[0];
            const newPreviewUrl = URL.createObjectURL(newFile);

            const newImages = [...sectionData.images];
            newImages[index] = { file: newFile, previewUrl: newPreviewUrl, filePath: null };
            updateImagesState(newImages);
        }
    };

    // 画像削除時の処理
    const handleImageRemove = async (index) => {
        const imageToRemove = sectionData.images[index];

        // Storageから画像削除
        if (imageToRemove.filePath) {
            const imageRef = ref(storage, imageToRemove.filePath);
            try {
                await deleteObject(imageRef);
                // Firestoreのドキュメントを更新
                const newImages = [...sectionData.images];
                newImages.splice(index, 1, { file: null, previewUrl: null, filePath: null }); // 削除された画像の情報をリセット

                // 更新データの準備
                const updateData = {
                    [`${sectionKey}.images`]: newImages.map(img => ({
                        previewUrl: img.previewUrl || null, // アップロードされていない場合はnull
                        filePath: img.filePath || null // アップロードされていない場合はnull
                    }))
                };
                // Firestoreのドキュメントを更新
                await updateDoc(doc(firestore, "companies", companyId), updateData);
                // ローカルの状態も更新
                updateImagesState(newImages);
                showSnackbar({ message: "画像が削除されました", type: 'success' });
            } catch (error) {
                showSnackbar({ message: "画像削除に失敗しました", type: 'error' });
                return; // Storageからの削除に失敗した場合はここで処理を終了
            }
        } else {
            showSnackbar({ message: "画像の削除またはドキュメントの更新に失敗しました", type: 'error' });
        }
    };


    return (
        <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>{getSectionTitle(sectionKey)}</Typography>

            <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
                {images.map((image, index) => (
                    <React.Fragment key={index}>
                        <Box sx={{ width: '50%', display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                            {image.previewUrl ? (
                                <Card sx={{ width: '100%', maxHeight: 'auto', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        image={image.previewUrl}
                                        alt={`プレビュー ${index + 1}`}
                                        sx={{
                                            height: 'auto',
                                            objectFit: 'cover',
                                            width: '100%',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Card>
                            ) : (
                                <Card sx={{ width: '100%', maxHeight: 'auto', overflow: 'hidden' }}>
                                    <CardMedia
                                        component="img"
                                        // image='https://sus-shokunin.com/wordpress/wp-content/uploads/2016/08/noimage.jpg'
                                        // alt={`プレビュー ${index + 1}`}
                                        sx={{
                                            height: 'auto',
                                            objectFit: 'cover',
                                            width: '100%',
                                            objectPosition: 'center',
                                        }}
                                    />
                                </Card>
                            )}
                            {hasEditPermission && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleImageChange(e, index)}
                                        style={{ display: 'none' }}
                                        id={`image-upload-${sectionKey}-${index}`}
                                    />
                                    {image.previewUrl ? (
                                        <>
                                            <label htmlFor={`image-upload-${sectionKey}-${index}`}>
                                                <Button component="span" variant='contained'>
                                                    {`画像変更`}
                                                </Button>
                                            </label>
                                            <Button
                                                variant='outlined'
                                                color="error"
                                                onClick={() => handleImageRemove(index)}
                                            >
                                                削除
                                            </Button>
                                        </>
                                    ) : (
                                        <label htmlFor={`image-upload-${sectionKey}-${index}`}>
                                            <Button component="span" variant='contained'>
                                                {`画像アップロード`}
                                            </Button>
                                        </label>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </React.Fragment>
                ))}
            </Box>

            {hasEditPermission ? (
                <Box sx={{ position: 'relative', width: '100%' }}>
                    <TextField
                        fullWidth
                        label={getSectionTitle(sectionKey)}
                        variant="outlined"
                        value={text}
                        onChange={handleTextChange}
                        onBlur={() => { onTextChange(sectionKey, text) }}
                        sx={{ mb: 6 }}
                        multiline
                    />
                    <Tooltip
                        // title="AIで生成" 
                        title={planName === 'スタンダードプラン' ? 'AIで生成' : 'このプランでは使えません'}
                        placement="top" arrow
                        slotProps={{
                            popper: {
                                sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                        marginTop: '0px',
                                    },
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                    {
                                        marginBottom: '6px',
                                    },
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                    {
                                        marginLeft: '0px',
                                    },
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                    {
                                        marginRight: '0px',
                                    },
                                },
                            },
                        }}>
                        <LoadingButton
                            onClick={planName === 'スタンダードプラン' ? () => { handleButtonClick({ setText, companyInfo, companyId, postData, isLoading, sectionTitle, sectionKey, onTextChange }) } : null}
                            variant="contained"
                            sx={{
                                position: 'absolute',
                                right: 0,  // テキストフィールドの右端にボタンを配置
                                bottom: 0,  // テキストフィールドの中央に来るように調整
                                borderRadius: 5,
                                padding: 1,
                                minWidth: 0,
                                bgcolor: planName === 'スタンダードプラン' ? 'bgcolor.primary' : 'text.disabled',
                                '&:hover': {
                                    bgcolor: planName === 'スタンダードプラン' ? 'primary.dark' : 'text.secondary',  // ホバー時の背景色
                                }
                            }}
                            loading={isLoading}
                        >
                            <NotesIcon fontSize='small' />
                        </LoadingButton>
                    </Tooltip>
                </Box>
            ) : (
                <Typography variant="body1" sx={{ mb: 12 }}>{sectionData.text || '未設定'}</Typography>
            )}

        </Box>
    );
};

const CompanyOurs = () => {
    const initialFormData = {
        whatWeDo: {
            text: "",
            images: [{ filePath: null, previewUrl: null }, { filePath: null, previewUrl: null }]
        },
        whyWeDoIt: {
            text: "",
            images: [{ filePath: null, previewUrl: null }, { filePath: null, previewUrl: null }]
        },
        howWeDoIt: {
            text: "",
            images: [{ filePath: null, previewUrl: null }, { filePath: null, previewUrl: null }]
        },
    };
    const [formData, setFormData] = useState(initialFormData);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [planName, setPlanName] = useState('');

    const { id: companyId } = useParams();
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();
    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId; // 編集権限があるかどうか
    const { isLoading, postData } = useApi("https://create-ai-message-normal-frnrslgk3q-an.a.run.app");

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const docRef = doc(firestore, "companies", companyId);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const id = docSnap.id; // ドキュメントのIDを取得

                    // Firestoreから取得したデータを基にformDataを更新
                    const updatedFormData = Object.keys(formData).reduce((acc, sectionKey) => {
                        const section = data[sectionKey] || {};
                        acc[sectionKey] = {
                            text: section.text || formData[sectionKey].text,
                            images: section.images || formData[sectionKey].images
                        };
                        return acc;
                    }, {});

                    setFormData(updatedFormData);
                    setCompanyInfo({ id, ...data });
                }
            } catch (error) {
                console.error("Error getting document:", error);
            }
        };

        if (companyId) {
            fetchCompanyInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    useEffect(() => {
        if (companyInfo?.planPrice === 'price_1PAUU6GBe8AZCWiIPqYf1X2N') {
            // トライアルプラン
            setPlanName('トライアルプラン');
        } else if (companyInfo?.planPrice === 'price_1PAUWzGBe8AZCWiI1Q6J9Q2v') {
            // スタンダードプラン
            setPlanName('スタンダードプラン');
        } else {
            // 未定義
            setPlanName('未定義');
        }
    }, [companyInfo]);


    const handleTextChange = (sectionKey, newText) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [sectionKey]: {
                ...prevFormData[sectionKey],
                text: newText
            }
        }));
    };

    const handleSubmit = async () => {
        try {
            // 各セクションごとに処理
            await Promise.all(Object.keys(formData).map(async (sectionKey) => {
                const sectionData = formData[sectionKey];
                const textData = sectionData.text;

                // 画像アップロードのPromise配列を作成
                const imageUploadPromises = sectionData.images.map(async (image, index) => {
                    const currentImageInfo = companyInfo[sectionKey]?.images[index];

                    // 画像が更新されているか判定
                    if (image.file && currentImageInfo?.filePath) {
                        // 元の画像をStorageから削除
                        const oldImageRef = ref(storage, currentImageInfo.filePath);
                        await deleteObject(oldImageRef);
                    }

                    if (image.file) {
                        // ストレージのパスを指定
                        const storagePath = `company_ours/${companyId}/ours/${sectionKey}_${index + 1}_${uuidv4()}`;
                        const imageRef = ref(storage, storagePath);
                        // ファイルをアップロード
                        await uploadBytes(imageRef, image.file);
                        // アップロードした画像のURLを取得
                        const imageUrl = await getDownloadURL(imageRef);
                        return { previewUrl: imageUrl, filePath: storagePath, file: null }; // アップロード後はファイルをnullに
                    }

                    return { previewUrl: image.previewUrl, filePath: currentImageInfo?.filePath || null, file: null }; // アップロードされていない場合
                });

                // すべての画像アップロードが完了するのを待つ
                const images = await Promise.all(imageUploadPromises);

                // Firestoreにテキストデータと画像URLを含むimages配列を保存
                const updateData = {
                    [`${sectionKey}.text`]: textData,
                    [`${sectionKey}.images`]: images
                };

                // Firestoreのドキュメントを更新
                await updateDoc(doc(firestore, "companies", companyId), updateData);
            }));
            showSnackbar({ message: "更新に成功しました", type: 'success' });
        } catch (error) {
            console.error("更新エラー: ", error);
            showSnackbar({ message: "更新に失敗しました", type: 'error' });
        }
    };


    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <CompanyTopNavigationContent hasEditPermission={hasEditPermission} company={companyInfo} />
            </ResponsiveContainer>
            <Container maxWidth="md" sx={{ mt: { xs: 6, sm: 12 }, mb: { xs: 19, sm: 4 } }}>
                {['whatWeDo', 'whyWeDoIt', 'howWeDoIt'].map((sectionKey) =>
                    formData[sectionKey] && (
                        <SectionEditForm
                            key={sectionKey}
                            sectionKey={sectionKey}
                            formData={formData}
                            onTextChange={handleTextChange}
                            setFormData={setFormData}
                            companyId={companyId}
                            hasEditPermission={hasEditPermission}
                            companyInfo={companyInfo}
                            postData={postData}
                            isLoading={isLoading}
                            planName={planName}
                        />
                    )
                )}

                {hasEditPermission && (
                    <LeftBottomFloatBtn handleSubmit={handleSubmit} />
                )}

            </Container>
            <Footer />
        </>
    )
};

export default CompanyOurs;