import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardMedia, Grid } from '@mui/material';

import { firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

import devConfig from '../config';

import { getImageUrl } from '../utils/getImageUrl';
import NavigationBar from '../components/Layout/NavigationBar';
import ResponsiveContainer from '../components/Layout/ResponsiveContainer';
import CompanyInfoList from '../components/Company/CompanyInfoList';
import CompanyTopNavigationContent from '../components/Company/CompanyTopNavigationContent';
import ScrollFooterBar from '../components/Notification/ScrollFooterBar';
import Contents from '../components/Contents';
import PostDetailsTopNavigationContent from '../components/PostDetailsTopNavigationContent';
import Footer from '../components/Layout/Footer';

const CompanyDetail = () => {

    const { type: postType } = useParams();
    const { id: companyId } = useParams();
    const [company, setCompany] = useState(null); // 企業情報を保持するための状態
    const [imageUrl, setImageUrl] = useState('');

    const hasEditPermission = devConfig.hasEditPermission;

    useEffect(() => {
        const fetchCompany = async () => {
            const docRef = doc(firestore, "companies", companyId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const companyData = docSnap.data();
                const companyWithId = { ...companyData, id: docSnap.id };
                setCompany(companyWithId);
            }
        };

        fetchCompany();
    }, [companyId]);


    useEffect(() => {
        const imagePath = `company_images/${companyId}/thumbnail_400x400.webp`;
        const fetchImageUrl = async () => {
            const url = await getImageUrl(imagePath);
            setImageUrl(url);
        };

        fetchImageUrl();
    }, [company, companyId]);

    const renderContent = () => {
        switch (postType) {
            case 'company':
                return <CompanyTopNavigationContent hasEditPermission={hasEditPermission} company={company} />;
            default:
                return <PostDetailsTopNavigationContent />;
        }
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                {renderContent()}
                <Card sx={{ maxWidth: '100%', maxHeight: 400, overflow: 'hidden' }}>
                    <CardMedia
                        component="img"
                        image={imageUrl}
                        alt="サムネイル"
                        sx={{
                            width: '100%', // 幅を100%に設定
                            height: 400, // 高さを固定値に設定
                            objectFit: 'cover', // コンテナを覆うように画像を調整
                            objectPosition: 'center' // 画像の中心をコンテナの中心に合わせる
                        }}
                    />
                </Card>

                <Grid container spacing={1.5}>

                    <Grid item xs={12} md={9} sx={{ p: 2, pr: 7 }}>
                        <Contents company={company} />
                    </Grid>

                    <Grid item xs={0} md={3}>
                        <CompanyInfoList isUnique={true} hasEditPermission={hasEditPermission} company={company} />
                    </Grid>
                </Grid>
                <ScrollFooterBar
                    avatarSrc="/static/images/avatar/1.jpg"
                    companyName={company?.name}
                    companyId={company?.id}
                    message="この会社に共感したら、担当者と話してみませんか？"
                />

            </ResponsiveContainer>
            <Footer />
        </>
    )
};

export default CompanyDetail;