import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImageUploadModal = ({ open, handleClose, handleSave }) => {
    const [imagePreview, setImagePreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        // const file = event.target.files[0];

        const file = event.target.files[0];
        if (!file) return;

        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            // 画像の寸法制限 (例: 幅と高さが1000px以下)
            if (width > 1000 || height > 1000) {
                alert('画像の幅と高さは1000px以下にしてください。');
                return;
            }

            // ファイルサイズ制限 (例: 5MB)
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (file.size > maxSize) {
                alert('ファイルサイズは5MB以下にしてください。');
                return;
            }

            // ファイルタイプ制限
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (!allowedTypes.includes(file.type)) {
                alert('JPEG、PNG、GIF形式のファイルのみアップロードできます。');
                return;
            }

            setSelectedFile(file);
            setImagePreview(URL.createObjectURL(file));
        };
    };

    const saveImage = () => {
        // ここで画像を保存するロジックを実装します。
        // 例: フォームデータを使用してサーバーにPOSTリクエストを送信
        handleSave(selectedFile);
        handleClose(); // モーダルを閉じる
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                画像をアップロード
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <Typography variant='bosy1'>
                画像の幅と高さは1000px以下にしてください。<br/>
                ファイルサイズは5MB以下にしてください。<br/>
                JPEG、PNG、GIF形式のファイルのみアップロードできます。
            </Typography>
                <Box textAlign="center" sx={{mt:3}}>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="upload-image"
                    />
                    <label htmlFor="upload-image">
                        <Button variant="contained" color="primary" component="span">
                            画像を選択
                        </Button>
                    </label>
                    {imagePreview && (
                        <Box mt={2}>
                            <img src={imagePreview} alt="Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button onClick={saveImage} color="primary">
                    保存
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImageUploadModal;
