// theme.js ファイルでテーマを定義する
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
    },
    typography: {
        fontFamily: [
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    components: {
        // MUI コンポーネントのグローバル設定
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '8px',  // ここでダイアログの角の丸みを設定
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // 例: 全ての FormControl に適用されるスタイル
                    marginBottom: '8px',  // マージン調整
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: '8px', // テキストフィールドの角の丸みを設定
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderRadius: '8px', // アウトラインされたボーダーの角の丸みを設定
                        }
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    // Select コンポーネントのスタイル
                    borderRadius: '8px',  // 角の丸み
                    '&:focus': {
                        borderRadius: '8px',  // フォーカス時の角の丸みを保持
                    }
                },
                icon: {
                    color: 'rgba(0, 0, 0, 0.54)',  // アイコンカラー
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px', // アウトライン入力の角の丸みを設定
                },
                notchedOutline: {
                    borderRadius: '8px', // ノッチのあるアウトラインの角の丸みを設定
                }
            }
        },
    }
});

export default theme;
