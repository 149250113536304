// React imports
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Material-UI imports
import { Chip, Typography, Box, Avatar, IconButton, Button, Stack, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

// Firebase imports
import { firestore } from '../../firebase';
import {
    collection, getDocs, query, where,
    serverTimestamp, addDoc, doc,
    arrayRemove, arrayUnion, updateDoc, increment
} from 'firebase/firestore';

// Local utilities and context/hooks
import { getImageUrl } from '../../utils/getImageUrl';
import { useSnackbar } from '../../Context/SnackbarContext';
import { useUser } from '../../Context/UserContext';
import { TourContext } from '../../Context/TourContext';

// Custom Components
import RegistrationDialog from '../Input/RegistrationDialog';

// 企業カードコンポーネント
const DataCard = ({ item, isGuide = false }) => {
    // ホバー状態を管理するための状態
    const [isHovered, setIsHovered] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [isBookmarked, setIsBookmarked] = useState(false);
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const itemId = item?.id?.raw
    const itemTags = item?.tags?.raw
    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);

    useEffect(() => {
        // Define the image path based on the itemId and isMobile flag
        const imagePath = `company_images/${itemId}/${isMobile ? 'thumbnail_200x200' : 'thumbnail_400x400'}.webp`;

        const fetchImageUrl = async () => {
            const url = await getImageUrl(imagePath);
            setImageUrl(url);
        };

        fetchImageUrl();
    }, [itemId, isMobile]);

    const { steps, setSteps } = useContext(TourContext);

    // joyrideステップを追加
    useEffect(() => {
        if (!isGuide) return;
        // 追加するステップ
        const newSteps = [
            {
                target: '.talk-to-ai-btn',
                content: <p>企業ごとのAIと話すことができます。<br />AI担当者とのチャットを開始しましょう！</p>,
                floaterProps: {
                    offset: 20, // オフセットを適切に設定
                    disableAnimation: true,
                },
                placement: 'top',
                spotlightPadding: 20,
                data: {
                    chatTag: 'AI担当者',
                    companyId: itemId,
                    companyInfo: item,
                    user: user,
                    navigate: navigate,
                },
            },
            {
                target: '.first-question-btn',
                content: '初めての質問をしてみよう',
                floaterProps: {
                    offset: 20, // オフセットを適切に設定
                    disableAnimation: true,
                },
                placement: 'top',
                spotlightPadding: 20,
                data: {},
            }
        ];
        // 新しいステップが既に存在しない場合のみ追加する
        const isStepExist = steps.some(step => newSteps.some(newStep => newStep.target === step.target));

        if (!isStepExist) {
            setSteps([...steps, ...newSteps]);
        }
    }, [isGuide, itemId, item, user, navigate, steps, setSteps]);

    // 初期状態でブックマークされているかどうかを確認
    useEffect(() => {
        setIsBookmarked(user?.companyBookMarks?.includes(itemId));
    }, [user, itemId]);

    const toggleBookmark = async () => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }

        const userDocRef = doc(firestore, "users", user.documentId);
        try {
            await updateDoc(userDocRef, {
                companyBookMarks: isBookmarked ? arrayRemove(itemId) : arrayUnion(itemId),
            });
            // ブックマークの状態を反転
            setIsBookmarked(!isBookmarked);
            showSnackbar({
                message: `企業が${isBookmarked ? 'ブックマークから削除されました' : 'ブックマークに追加されました'}.`,
                type: 'success',
            });
        } catch (error) {
            console.error("ブックマークの更新に失敗しました: ", error);
            showSnackbar({
                message: "ブックマークの更新に失敗しました。",
                type: 'error',
            });
        }
    };

    // チャットドキュメントを追加する関数
    const startOrJoinChat = async ({ chatTag }) => {
        if (!user) {
            setShowRegistrationDialog(true);
            return;
        }

        // ユーザーと企業ID、そしてchatTagにマッチするチャットを検索
        const q = query(collection(firestore, 'chats'),
            where('participantIds', 'array-contains', user.uid),
            where('companyId', '==', itemId),
            where('chatTag', '==', chatTag),
            where('archived', '==', false) // 既に終了していないチャットのみを対象にする
        );

        const querySnapshot = await getDocs(q);

        // 既存のチャットが見つかった場合、そのチャットに移動
        if (!querySnapshot.empty) {
            // 最初のドキュメントにアクセス
            const firstDoc = querySnapshot.docs[0];
            // ドキュメントのIDを取得
            const chatId = firstDoc.id;
            // ドキュメントIDを使用して特定のチャットページにナビゲート
            navigate(`/chat/${chatId}`);
            showSnackbar({ message: "既存のチャットに参加しました", type: 'success' });
            return;
        } else {
            // 既存のチャットがない場合、新しいチャットを作成
            const chatData = {
                participantIds: [user.uid], // ユーザー検索用のフィールド
                participants: [{ "userId": user.uid, "userType": user.userType }],
                companyId: itemId, // 企業のIDを保存
                companyRef: doc(firestore, `companies/${itemId}`), // 企業ドキュメントへの参照
                chatTag: chatTag,
                avatarSrc: item?.iconurl?.raw ?? null,
                companyDisplaySrc: user?.userIconUrl ?? null,
                lastMessage: '',
                lastMessageTimestamp: serverTimestamp(),
                archived: false,
                startedBy: { "userId": user.uid, "userType": user.userType },
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            };

            // 新しいチャットドキュメントを追加し、追加されたドキュメントの参照を取得
            const docRef = await addDoc(collection(firestore, 'chats'), chatData);
            // 追加されたドキュメントのIDを取得
            const chatId = docRef.id;
            // ドキュメントIDを使用して特定のチャットページにナビゲート
            navigate(`/chat/${chatId}`);
            showSnackbar({ message: "新しいチャットを開始しました", type: 'success' });
        }
    };

    const incrementViewCount = async (itemId) => {
        const docRef = doc(firestore, "companies", itemId);
        await updateDoc(docRef, {
            viewCount: increment(1)
        });
    };

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer' }} // カーソルをポインターに設定してユーザーにインタラクティブであることを示す
        >
            <RegistrationDialog
                open={showRegistrationDialog}
                onClose={() => setShowRegistrationDialog(false)}
            />
            <Box component={Link}
                onClick={(e) => {
                    e.preventDefault(); // デフォルトのリンクの挙動を防ぐ
                    incrementViewCount(itemId).then(() => {
                        // データ更新後にページ遷移
                        navigate(`/company/${itemId}/home`);
                    });
                }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                sx={{ textDecoration: 'none' }}
            >

                <Box
                    component="img"
                    src={imageUrl} // デフォルトの画像パスを設定してください
                    alt={
                        item?.name?.raw
                    }
                    height={isMobile ? 180 : 275}
                    sx={{
                        borderRadius: '8px',
                        objectFit: 'cover', // はみ出した部分は切り取る
                        objectPosition: 'center center', // 画像の中心をコンテナの中心に合わせる
                        height: isMobile ? '180px' : '275px', // モバイルかどうかによって高さを設定
                        width: '100%' // 幅はコンテナに合わせて100%
                    }}
                />
                <Box>
                    <Box sx={{ display: 'flex', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {/* タグが存在し、空でない場合のみレンダリング */}
                        {itemTags && itemTags.length > 0 && itemTags.map((tag, index) => (
                            <Chip key={index} label={tag} variant="outlined" sx={{ mr: 1 }} />
                        ))}
                    </Box>
                    <Typography
                        gutterBottom variant="h5" component="h2" sx={{ fontWeight: 'bold', mt: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
                        color={isHovered ? 'primary' : 'black'} // ホバー状態に応じて色を変更
                    >
                        {
                            item?.name?.raw
                        }
                    </Typography>
                    <Box sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        height: '3.6em',
                    }}>
                        <Typography variant="body2" color='black'>
                            {item?.companydetail?.raw}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Typography variant="subtitle2" color='text.secondary' sx={{ pt: 1, fontWeight: 'bold' }}>
                {`${item?.viewcount?.raw || 0} views`}
            </Typography>
            <Box display="flex" justifyContent="space-between" paddingBottom={1}>
                <Box display="flex" alignItems="center">
                    <Avatar src={item?.iconurl?.raw} aria-label="recipe">
                        {item?.iconurl?.raw ? <img src={item?.iconurl?.raw} alt="company logo" /> : item?.name?.raw.charAt(0)}
                    </Avatar>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{
                            ml: 1,
                            fontWeight: 'bold',
                            textDecoration: 'none', // 通常時は下線を非表示にする
                            color: 'gray', // テキストの色を継承する
                            '&:hover': {
                                textDecoration: 'underline', // ホバー時に下線を表示する
                                color: 'black', // ホバー時に色を変更する
                            },
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: { xs: 'calc(60vw - 6rem - 16px)', sm: '150px' }, // テキストの最大幅を指定
                            fontSize: { xs: '0.85rem', sm: '1rem' }
                        }}
                        component={Link}
                        to={`/post/company/${itemId}`}
                    >
                        {item?.name?.raw}
                    </Typography>
                </Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <IconButton
                        size="large"
                        sx={{ ml: 1, color: isBookmarked ? 'primary.main' : 'inherit' }} // ブックマーク済みの場合は色を変更
                        onClick={toggleBookmark}
                    >
                        {isBookmarked ? <TurnedInIcon fontSize="inherit" /> : <TurnedInNotIcon fontSize="inherit" />}
                    </IconButton>
                    {/* ボタンのテキストが長い場合の対応は不要 */}
                    <Button variant="contained" onClick={() => startOrJoinChat({ chatTag: 'AI担当者' })}
                        className='talk-to-ai-btn'
                    >
                        AIと話す
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
};

export default DataCard;
