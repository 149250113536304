import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

const CheckboxControl = ({ label, checked, onChange, name }) => {
    return (
        <Grid item xs={6} sm={4}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={onChange}
                        name={name}
                    />
                }
                label={label}
            />
        </Grid>
    );
};

export default CheckboxControl;