import React, { useState } from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const roleDisplayNames = {
    admin: '管理者',
    editor: '編集ユーザー',
    chat_user: 'チャットユーザー',
    viewer: '閲覧ユーザー',
};

const UserRow = ({ row }) => {
    const [open, setOpen] = useState(false);
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date.seconds * 1000);
        return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align='left'>{row.lastName}</TableCell>
                <TableCell component="th" scope="row" align='left'>{row.firstName}</TableCell>
                <TableCell align="center">{roleDisplayNames[row.role]}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} sx={{ mb: 2 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                ユーザー詳細
                            </Typography>
                            <TableContainer>
                                <Table aria-label="user details table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell component="th" scope="row">氏名</TableCell>
                                            <TableCell>{`${row.lastName} ${row.firstName}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">メールアドレス</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">性別</TableCell>
                                            <TableCell>{row.gender === 'male' ? '男性' : '女性'}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">役割</TableCell>
                                            <TableCell>{roleDisplayNames[row.role]}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">誕生日</TableCell>
                                            <TableCell>{formatDate(row.birthDate)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">アカウント作成日</TableCell>
                                            <TableCell>{formatDate(row.createAt)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">最終更新日</TableCell>
                                            <TableCell>{formatDate(row.updatedAt)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default UserRow;