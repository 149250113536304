import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

export function SmChatAppBar({ activeChatRoom }) {
    const navigate = useNavigate();

    // バックボタンのクリックハンドラー
    const handleBackClick = () => {
        // ここで /chat/null へのナビゲーションを実行
        navigate('/chat/null');
    };

    // アバターのクリックハンドラー
    const handleAvatarClick = () => {
        // ここで /company/:id/home へのナビゲーションを実行
        // activeChatRoom.companyId を使用して適切なIDをパスに含める
        navigate(`/company/${activeChatRoom.companyId}/home`);
    };

    return (
        <AppBar component="nav" color="inherit">
            <Toolbar variant='dense'>
                <IconButton edge="start" color="inherit" aria-label="back" onClick={handleBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="body1" component="div" sx={{
                    flexGrow: 1,
                    textAlign: 'center',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    {activeChatRoom?.title}
                </Typography>
                <Avatar
                    src={activeChatRoom?.avatarSrc}
                    alt=""
                    onClick={handleAvatarClick} // アバタークリック時の処理を追加
                >
                    {activeChatRoom?.avatarSrc ? '' : activeChatRoom?.title.charAt(0)}
                </Avatar>
            </Toolbar>
        </AppBar>
    );
}
