// src/components/PasswordResetDialog.js
import React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from "firebase/auth";

import { useForm } from 'react-hook-form';
import { useSnackbar } from '../../Context/SnackbarContext';

const PasswordResetDialog = ({ open, onClose }) => {
    const { showSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        reset, // リセット関数を追加
        formState: { errors, isValid }
    } = useForm({
        mode: "onChange" // フォーム入力のたびにバリデーションを実行
    });

    const handleResetPassword = async ({ email }) => {
        try {
            sendPasswordResetEmail(auth, email);
            showSnackbar({ message: "", type: 'success', title: 'パスワードリセットメールを送信しました。' });
            reset(); // フォームのリセット、ここでemailフィールドもリセットされる
            onClose(); // ダイアログを閉じる
        } catch (error) {
            showSnackbar({ message: "再度お試しください", type: 'error', title: '送信に失敗' });
        }
    };

    const handleClose = () => {
        reset(); // フォームのリセット、ここでemailフィールドもリセットされる
        onClose(); // ダイアログを閉じる
    }


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>パスワードリセット</DialogTitle>
            <form onSubmit={handleSubmit(handleResetPassword)}>
                <DialogContent>
                    <DialogContentText>
                        パスワードリセットのためのリンクを送るメールアドレスを入力してください。
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="メールアドレス"
                        type="email"
                        fullWidth
                        variant="outlined"
                        {...register("email", {
                            required: "メールアドレスは必須です",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "有効なメールアドレスを入力してください"
                            }
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <Button type="submit" disabled={!isValid} variant="contained">送信</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default PasswordResetDialog;
