import { useState } from 'react';

const useApi = (url) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const postData = async (body) => {
        setIsLoading(true);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await response.json();
            setData(responseData);
            setIsLoading(false);
            return responseData;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            console.error("Failed to fetch: ", e);
            return null;
        }
    };

    return { data, isLoading, error, postData };
};

export default useApi;
