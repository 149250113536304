import React from 'react';
import {
    Box,
    Typography,
    Stack,
    TextField,
    Divider,
    IconButton,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    useMediaQuery
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, AddCircleOutline as AddCircleOutlineIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useUser } from '../Context/UserContext';

const SearchBarContent = ({ searchParams, handleInputChange, handleSearch, toggleFeature, features }) => {
    const { user } = useUser();
    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={2}>
                <TextField
                    fullWidth
                    label="キーワード検索"
                    variant="outlined"
                    size="small"
                    name="searchText"
                    value={searchParams.searchText}
                    onChange={handleInputChange}
                />
            </Stack>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold' }}>特徴</Typography>
                <Divider />
                <Stack spacing={0}>
                    {features.map((feature) => (
                        // ユーザーがログインしていない場合、"ブックマーク済み" 特徴を表示しない
                        (user || feature.name !== "ブックマーク済み") && (
                            <Box key={feature.name} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography variant="body2">{feature.name}</Typography>
                                <IconButton
                                    color="primary"
                                    aria-label="toggle feature"
                                    size="small"
                                    onClick={() => toggleFeature(feature.name)} // ここで feature.name を引数として渡す
                                >
                                    {feature.enabled ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                                </IconButton>
                            </Box>
                        )
                    ))}
                </Stack>
            </Box>
            <Box sx={{ mt: 1 }}>
                <Button variant="contained" fullWidth onClick={handleSearch}>検索</Button>
            </Box>
        </Box>
    );
};

const SearchBar = ({ searchParams, handleInputChange, handleSearch, toggleFeature, features }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Box>
            {isMobile ? (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="search-bar-content"
                        id="search-bar-header"
                    >
                        <Typography variant="subtitle1" color='text.secondary' sx={{ fontWeight: 'bold' }}>検索条件</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SearchBarContent
                            searchParams={searchParams}
                            handleInputChange={handleInputChange}
                            handleSearch={handleSearch}
                            toggleFeature={toggleFeature}
                            features={features}
                        />
                    </AccordionDetails>
                </Accordion>
            ) : (
                <SearchBarContent
                    searchParams={searchParams}
                    handleInputChange={handleInputChange}
                    handleSearch={handleSearch}
                    toggleFeature={toggleFeature}
                    features={features}
                />
            )}
        </Box>
    );
};

export default SearchBar;