import React from 'react';
import { Box } from '@mui/material';
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';

import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';

import devConfig from '../../config';
import NavigationBar from '../../components/Layout/NavigationBar';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const EditPage = () => {
    const hasEditPermission = devConfig.hasEditPermission;;

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <CompanyTopNavigationContent hasEditPermission={hasEditPermission} />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                </Box>
            </ResponsiveContainer>
        </>
    );
}

export default EditPage;

