// メッセージをFirestoreに登録する関数
// const addMessageToFirestore = async (chatId, messageText, userId) => {
//     try {
//         await addDoc(collection(firestore, `chats/${chatId}/messages`), {
//             senderId: userId,
//             text: messageText,
//             timestamps: serverTimestamp(), // Firestoreのサーバー時間を使用
//             createdAt: serverTimestamp(),
//             updatedAt: serverTimestamp(),
//         });
//     } catch (error) {
//         console.error('Error adding message to Firestore:', error);
//     }
// };
// MessageInputField コンポーネント内でメッセージ送信機能を実装
// const sendMessage = async ({ chatId, messageText, user }) => {
//     // ユーザーからのメッセージをFirestoreに保存
//     await addMessageToFirestore(chatId, messageText, user.uid);
// };
export async function fetchUserSearchAPI(companyId, searchParams, itemsPerPage, page, setUsers, setTotalUsers) {
    const endpoint = "https://elasticsearch-frnrslgk3q-an.a.run.app";
    const engineName = "user-search-engine";

    // フィルタ条件の初期設定
    let filters = [{ "isdeleted": "false" }];

    // 性別が指定されている場合
    if (Array.isArray(searchParams.gender) && searchParams.gender.length > 0) {
        filters.push({ "gender": searchParams.gender });
    }

    // 学年が指定されている場合
    if (Array.isArray(searchParams.grade) && searchParams.grade.length > 0) {
        filters.push({ "grade": searchParams.grade });
    }

    // ユーザータイプが指定されている場合
    if (Array.isArray(searchParams.userType) && searchParams.userType.length > 0) {
        filters.push({ "usertype": searchParams.userType });
    }

    // 志望が指定されている場合
    if (Array.isArray(searchParams.aspirations) && searchParams.aspirations.length > 0) {
        filters.push({ "aspirations": searchParams.aspirations });
    }

    // 教育関連タグが指定されている場合
    if (Array.isArray(searchParams.interestsEducationTags) && searchParams.interestsEducationTags.length > 0) {
        filters.push({ "interestseducationtags": searchParams.interestsEducationTags });
    }

    // 雇用関連タグが指定されている場合
    if (Array.isArray(searchParams.interestsEmploymentTags) && searchParams.interestsEmploymentTags.length > 0) {
        filters.push({ "interestsemploymenttags": searchParams.interestsEmploymentTags });
    }

    // 受賞歴がある場合（true/false）
    if (searchParams.awards) {
        filters.push({ "hasawards": "id" });
    }

    // キャッチフレーズがある場合
    if (searchParams.catchphrase) {
        filters.push({ "hascatchphrase": "true" });
    }

    // 資格がある場合
    if (searchParams.certifications) {
        filters.push({ "hascertifications": "true" });
    }

    // ブックマークがある場合
    if (searchParams.companyBookMarks) {
        filters.push({ "companybookmarks": companyId }); // 仮の値または実際の値に置き換える必要がある
    }

    // 保護者の同意がある場合
    if (searchParams.parentalConsent) {
        filters.push({ "parentalconsent": "true" });
    }

    // プロフィールがある場合
    if (searchParams.profile) {
        filters.push({ "hasprofile": "true" });
    }

    // ユーザーアイコンURLがある場合
    if (searchParams.userIconUrl) {
        filters.push({ "hasusericonurl": "true" }); // 仮の値または条件に応じて "false" にするかもしれません
    }

    // 年齢範囲が指定されている場合
    if (Array.isArray(searchParams.ageRange) && searchParams.ageRange.length === 2) {
        filters.push({
            "age": { "from": searchParams.ageRange[0], "to": searchParams.ageRange[1] }
        });
    }

    // 結果的に構築されたフィルタリストを含むリクエストデータ
    const requestData = {
        "engine": engineName,
        "query": searchParams.searchText || "", // 検索テキストが提供されていない場合は空文字列を使用
        "filters": { "all": filters },
        "page": { "size": itemsPerPage, "current": page }
    };

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setUsers(data.results);
        setTotalUsers(data.meta.page.total_results);

    } catch (error) {
        console.error("There was an error fetching the users:", error);
    }
}
