import React from 'react';

import { Box, Typography } from '@mui/material';

import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineItem from '@mui/lab/TimelineItem';

import SchoolIcon from '@mui/icons-material/School';
import BusinessIcon from '@mui/icons-material/Business';

import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import HistoryEditModal from './HistoryEditModal';
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';
import { firestore } from '../../firebase';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const formatDate = (dateString) => {
    return dateString.replace(/-/g, '/');
};

const getIconForType = (type) => {

    switch (type) {
        case 'education':
            return <SchoolIcon />;
        case 'career':
            return <BusinessIcon />;
        default:
            return null; // デフォルトのアイコンがある場合はここに設定
    }
};

// startedAtとendedAt（または'現在'）から期間を計算する関数
const calculateTerm = (startedAt, endedAt) => {
    const start = dayjs(startedAt);
    // endedAtが'現在'の場合はdayjs()で現在時刻を取得、それ以外はそのまま日付として解釈
    const end = endedAt === '現在' ? dayjs() : dayjs(endedAt);
    const diff = end.diff(start);

    // dayjsのdurationを使用して、差分から期間を計算
    const termDuration = dayjs.duration(diff);

    // 期間を「X年Yヶ月」の形式で表示
    const years = termDuration.years() > 0 ? `${termDuration.years()}年` : '';
    const months = termDuration.months() > 0 ? `${termDuration.months()}ヶ月` : '';
    // 日数も表示する場合は以下の行を追加（必要に応じて）

    // 期間が全くない場合、「期間不明」と表示
    return `${years}${months}` || '期間不明';
};

const CustomTimelineItem = ({ content, startedAt, endedAt, organization, type, position, major, description, learned, isDisabled }) => {
    const [modalState, setModalState] = React.useState({
        open: false, // モーダルが開いているかどうか
        data: null, // モーダルに渡すデータ
        type: null, // モーダルのタイプ（'edit', 'add', 'view' など）
    });

    const { user } = useUser();
    const { showSnackbar } = useSnackbar();

    const term = calculateTerm(startedAt, endedAt);
    const icon = getIconForType(type);

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    // モーダルを開く関数
    const handleOpenModal = ({ modalType }) => {
        const modalData = {
            // id: content.id,
            type,
            organization,
            position,
            major,
            description,
            learned,
            startedAt,
            endedAt,
        };
        setModalState({ open: true, data: modalData, type: modalType });
    };

    // モーダルを閉じる関数
    const handleCloseModal = () => {
        setModalState({ open: false, data: null, type: null });
    };

    // 職歴と学歴のデータをFirestoreに更新する関数
    const handleUpdateHistoryData = async (data, userDocId, historyDocId) => {
        try {
            // 特定のユーザーの特定のヒストリードキュメントを更新
            const historyDocRef = doc(firestore, "users", userDocId, "history", historyDocId);
            await updateDoc(historyDocRef, {
                ...data,
                updatedAt: serverTimestamp(), // 現在の日時を更新日として設定
            });
            showSnackbar({ type: 'success', message: '更新しました' });
            handleCloseModal(); // モーダルを閉じる
        } catch (error) {
            showSnackbar({ type: 'error', message: '更新に失敗しました' });
        }
    };

    const handleDelete = async (userDocId, historyDocId) => {
        // Firestoreに対する削除（非アクティブ化）処理
        try {
            const docRef = doc(firestore, "users", userDocId, "history", historyDocId);
            await updateDoc(docRef, {
                isDisabled: true,
                updatedAt: serverTimestamp(),
            });
            showSnackbar({ type: 'success', message: '削除しました' });
            handleCloseModal(); // モーダルを閉じる
        } catch (error) {
            showSnackbar({ type: 'error', message: '削除に失敗しました' });
        }
    };

    return (
        <>
            <TimelineItem
                onClick={() => {
                    if (!isDisabled) { // isDisabled が true の場合はクリックイベントを無効化
                        handleOpenModal({ modalType: 'history' });
                    }
                }}
                sx={{
                    cursor: isDisabled ? 'default' : 'pointer', // isDisabled が true の場合はカーソルをデフォルトに設定
                    '&:hover': {
                        bgcolor: isDisabled ? 'transparent' : 'action.hover', // isDisabled が true の場合はホバー効果を無効化
                    },
                    transition: 'background-color 0.3s',
                }}
            >
                {isSm && (
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="text.secondary">
                        {formatDate(endedAt)}<br />
                        ~{formatDate(startedAt)}
                    </TimelineOppositeContent>
                )}
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>{icon}</TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', pt: 2, borderBottom: '1px solid #ccc' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                            {organization}
                        </Typography>
                        {term && (
                            <Typography variant="body1" component="span" color='text.secondary' sx={{ fontWeight: 'bold', ml: 1 }}>
                                {term}
                            </Typography>
                        )}
                    </Box>
                    {type === 'career' && (
                        <>
                            {!isSm && (
                                <Typography variant="caption" color="text.secondary">{formatDate(endedAt)} ~ {formatDate(startedAt)}</Typography>
                            )}
                            {isSm && (
                                <Typography variant="body1" color='text.secondary' sx={{ fontWeight: 'bold' }}>
                                    {position}
                                </Typography>
                            )}
                        </>
                    )}
                    {type === 'education' && (
                        <>
                            {!isSm && (
                                <Typography variant="caption" color="initial">{formatDate(endedAt)} ~ {formatDate(startedAt)}</Typography>
                            )}
                            {isSm && (
                                <Typography variant="body1" color='text.secondary' sx={{ fontWeight: 'bold' }}>
                                    {major}
                                </Typography>
                            )}
                        </>
                    )}

                    {type === 'career' && (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {description}
                        </Typography>
                    )}
                    {type === 'education' && (
                        <>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                {learned}
                            </Typography>
                        </>
                    )}
                </TimelineContent>
            </TimelineItem>

            <HistoryEditModal
                open={modalState.type === 'history' && modalState.open}
                onClose={handleCloseModal}
                onSave={(data) => handleUpdateHistoryData(data, user.documentId, content.id)} // 更新関数をモーダルに渡す
                onDelete={() => handleDelete(user.documentId, content.id)} // 削除関数をモーダルに渡す
                initialData={modalState.data} // 編集するデータをモーダルに渡す
                isEdit={true} // 編集モードかどうか
            />
        </>
    );
};


const CustomTimeline = ({ items, isDisabled }) => {
    return (
        <>
            {items.map((item, index) => (
                <CustomTimelineItem
                    key={index}
                    content={item}
                    {...item}
                    isDisabled={isDisabled}
                />
            ))}
        </>
    );
};

export default CustomTimeline;
