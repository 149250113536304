import React from 'react';
import { Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Chip, Checkbox } from '@mui/material';


import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const LabelledInput = ({ title, label, value, onChange, type = 'text', options, isTitle = true, ...props }) => {
    // Autocomplete の選択肢があるかどうかで条件分岐
    const isAutocomplete = options && options.autocomplete;
    // セレクトボックスの選択肢があるかどうかで条件分岐
    const isSelect = !isAutocomplete && options && Array.isArray(options);

    return (
        <Box sx={{
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { md: 'center' },
            display: 'flex',
            gap: 0.5,
            marginBottom: 2,
        }}>

            {isTitle && (
                <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                    fontWeight: 'bold',
                    width: { md: 'auto' },
                    minWidth: { md: '160px' },
                    marginBottom: { xs: 0, md: 0 },
                }}>
                    {isTitle && title}
                </Typography>
            )}
            {isAutocomplete ? (
                <Autocomplete
                    multiple
                    fullWidth
                    options={options.autocomplete}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.label}
                    value={value} // 親コンポーネントから適切にマッピングされたオブジェクトの配列を受け取る
                    onChange={(event, newValue) => {
                        onChange(newValue); // 親から渡された onChange を直接呼び出し
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                color="primary"
                            />
                        ))
                    }
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.label}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} label={options.formLabel} placeholder={options.formLabel} />
                    )}
                />
            ) :
                isSelect ? (
                    <FormControl fullWidth>
                        <InputLabel id={`${label}-label`}>{label}</InputLabel>
                        <Select
                            labelId={`${label}-label`}
                            id={label}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            label={label}
                            {...props}
                        >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        type={type}
                        value={value}
                        label={label}
                        onChange={(e) => onChange(e.target.value)}
                        variant="outlined"
                        fullWidth
                        {...props}
                    />
                )}
        </Box>
    );
};

export default LabelledInput;
