import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { firestore as db } from '../firebase';
import { useUser } from './UserContext';

const CompanyContext = createContext();

export const useCompany = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const { user } = useUser(); // UserContextからuserを取得

    useEffect(() => {
        if (user && user.userType === 'company') {
            const companyDoc = doc(db, 'companies', user.companyId);
            const unsubscribe = onSnapshot(companyDoc, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    setCompany({ id: docSnapshot.id, ...docSnapshot.data() });
                } else {
                    console.error("No company found with given ID:", user.companyId);
                }
                setLoading(false);
            });
            return () => unsubscribe();
        } else {
            setLoading(false);
        }
    }, [user]);

    return (
        <CompanyContext.Provider value={{ company, loading }}>
            {!loading && children}
        </CompanyContext.Provider>
    );
};
