import React, { createContext, useState } from 'react';

// firestore import
import { query, collection, where, getDocs, addDoc, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

// APIエンドポイントのURLをインポート
import { GENERATE_AI_MESSAGE_FROM_INDEX } from '../utils/apiEndpoint';

export const TourContext = createContext();

export const TourProvider = ({ children }) => {
    const [run, setRun] = useState(false);
    const [steps, setSteps] = useState([]);

    return (
        <TourContext.Provider value={{ run, setRun, steps, setSteps }}>
            {children}
        </TourContext.Provider>
    );
};

export const handleCallback = async(data) => {
    const { index, type, step } = data;

    // ステップ2の後に特定の関数を実行
    if (type === 'step:after' && index === 1) { // Joyrideのインデックスは0から始まるため、ステップ2はインデックス1です。
        executeCustomFunction(step.data);
    }

    if (type === 'step:after' && index === 2) { // Joyrideのインデックスは0から始まるため、ステップ2はインデックス1です。
        sendMessage({
            messageText: step.data.messageText,
            user: step.data.user,
            activeChat: step.data.activeChat,
            activeChatRoom: step.data.activeChatRoom,
            setIsLoadingAiResponse: step.data.setIsLoadingAiResponse
        });
        if (step?.data?.setRun) {
            step.data.setRun(false); // ツアーを終了
            step.data.setOpenFinishTourDialog(true); // ツアー終了ダイアログを表示
            // ここにツアー終了後の処理を追加【Firestoreのusersコレクションのドキュメントにチュートリアルが終わったフラグを立てる】
            const userDocRef = doc(firestore, "users", step.data.user.uid);
            await setDoc(userDocRef, { tutorialStatus: 'completed' }, { merge: true });
        }
    }
};

// ステップからデータを受け取って何かを実行する関数
function executeCustomFunction(data) {
    // ここでデータに基づいて何か処理を行う
    return startOrJoinChat({
        itemId: data.companyId,
        item: data.companyInfo,
        user: data.user,
        chatTag: data.chatTag,
        navigate: data.navigate
    });
}

// チャットを開始または参加する関数
const startOrJoinChat = async ({ itemId, item, user, chatTag, navigate }) => {
    // ユーザーと企業ID、そしてchatTagにマッチするチャットを検索
    const q = query(collection(firestore, 'chats'),
        where('participantIds', 'array-contains', user?.uid),
        where('companyId', '==', itemId),
        where('chatTag', '==', chatTag),
        where('archived', '==', false) // 既に終了していないチャットのみを対象にする
    );

    const querySnapshot = await getDocs(q);

    // 既存のチャットが見つかった場合、そのチャットに移動
    if (!querySnapshot.empty) {
        const firstDoc = querySnapshot.docs[0];
        const chatId = firstDoc.id;
        navigate(`/chat/${chatId}`);
        return;
    } else {
        // 既存のチャットがない場合、新しいチャットを作成
        const chatData = {
            participantIds: [user.uid], // ユーザー検索用のフィールド
            participants: [{ "userId": user.uid, "userType": user.userType }],
            companyId: itemId, // 企業のIDを保存
            companyRef: doc(firestore, `companies/${itemId}`), // 企業ドキュメントへの参照
            chatTag: chatTag,
            avatarSrc: item?.iconurl?.raw ?? null,
            companyDisplaySrc: user?.userIconUrl ?? null,
            lastMessage: '',
            lastMessageTimestamp: serverTimestamp(),
            archived: false,
            startedBy: { "userId": user.uid, "userType": user.userType },
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
        };

        // 新しいチャットドキュメントを追加し、追加されたドキュメントの参照を取得
        const docRef = await addDoc(collection(firestore, 'chats'), chatData);
        // 追加されたドキュメントのIDを取得
        const chatId = docRef.id;
        navigate(`/chat/${chatId}`); // ドキュメントIDを使用して特定のチャットページにナビゲート
    }
};

// MessageInputField コンポーネント内でメッセージ送信機能を実装
const sendMessage = async ({ messageText, user, activeChat, activeChatRoom, setIsLoadingAiResponse }) => {
    // ステップ1: ユーザーからのメッセージをFirestoreに保存
    await addMessageToFirestore(activeChat, messageText, user?.uid);

    if (activeChatRoom?.chatTag === 'AI担当者') {
        // ステップ2: APIを叩いてAIからのレスポンスを取得
        setIsLoadingAiResponse(true); // リクエスト開始時にローディングを表示
        try {
            const aiResponse = await callAiMessageEndpoint(messageText, activeChatRoom.companyId, activeChatRoom.title);
            // ステップ3: APIからのレスポンスをFirestoreに保存
            if (aiResponse && aiResponse.response && aiResponse.response.choices && aiResponse.response.choices.length > 0) {
                const aiMessageContent = aiResponse.response.choices[0].message.content;
                await addDoc(collection(firestore, `chats/${activeChat}/messages`), {
                    senderId: 'AI',
                    text: aiMessageContent,
                    similar_chunks_info: formatSimilarityChunksInfoData(aiResponse?.similar_chunks_info) || null,
                    usage_completion_tokens: aiResponse?.response.usage.completion_tokens || null,
                    usage_prompt_tokens: aiResponse?.response.usage.prompt_tokens || null,
                    usage_total_tokens: aiResponse?.response.usage.total_tokens || null,
                    timestamps: serverTimestamp(), // Firestoreのサーバー時間を使用
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                });
            }
        } catch (error) {
            console.error('AIメッセージ取得エラー:', error);
        } finally {
            setIsLoadingAiResponse(false); // レスポンス受信後
        }
    }
};

// メッセージをFirestoreに登録する関数
const addMessageToFirestore = async (chatId, messageText, userId) => {
    try {
        await addDoc(collection(firestore, `chats/${chatId}/messages`), {
            senderId: userId,
            text: messageText,
            timestamps: serverTimestamp(), // Firestoreのサーバー時間を使用
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
        });
    } catch (error) {
        console.error('Error adding message to Firestore:', error);
    }
};

// APIにリクエストを送る関数
const callAiMessageEndpoint = async (text, companyId, companyName) => {
    const response = await fetch(GENERATE_AI_MESSAGE_FROM_INDEX, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text,
            company_id: companyId,
            company_name: companyName,
        }),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json(); // レスポンスのJSONを返す
};

// 類似性情報のデータをフォーマットする関数
const formatSimilarityChunksInfoData = (data) => {
    return data.map(item => ({
        score: item[1][0],
        filename: item[0]
    }));
};