// React
import React, { useEffect } from 'react';

// React Hook Form
import { useForm, Controller } from 'react-hook-form';

// MUI コンポーネント & アイコン
import { TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, FormControl } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

// 日付ピッカー & 日付処理
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

const HighlightCardEditModal = ({ openEdit, handleClose, onSave, data, modalType, category, onDelete }) => {
    const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            title: '',
            url: '',
            description: '',
            date: dayjs(),
        }
    });

    useEffect(() => {
        if (!openEdit) {
            reset({
                title: '',
                url: '',
                description: '',
                date: null,
            });
        }
    }, [openEdit, reset]);

    useEffect(() => {
        if (openEdit && data) {
            reset(data); // dataが存在する場合、フォームの値を更新
        }
    }, [data, openEdit, reset]);

    const handleSave = (formData) => {
        const validatedDate = dayjs(formData.date).isValid() ? dayjs(formData.date).format('YYYY-MM-DD') : null;
        onSave({
            ...formData,
            date: validatedDate,
        });
        handleClose();
    };

    const handleDelete = (itemId) => {
        onDelete(itemId);
        handleClose();
    };

    // カテゴリに応じたフォームのラベルを取得する関数
    const getFormLabels = (category) => {
        switch (category) {
            case 'certifications':
                return {
                    titleLabel: "資格名",
                    descriptionLabel: "資格の紹介や取得までのエピソード",
                    dateLabel: "取得日"
                };
            case 'awards':
                return {
                    titleLabel: "受賞名",
                    descriptionLabel: "受賞の詳細やエピソード",
                    dateLabel: "受賞日"
                };
            default:
                return {
                    titleLabel: "タイトル",
                    descriptionLabel: "説明",
                    dateLabel: "日付"
                };
        }
    };

    const { titleLabel, descriptionLabel, dateLabel } = getFormLabels(category);

    return (
        <Dialog open={openEdit} onClose={handleClose} aria-labelledby="edit-dialog-title" fullWidth maxWidth="sm">
            <DialogTitle id="edit-dialog-title">{modalType}</DialogTitle>
            <form onSubmit={handleSubmit(handleSave)}>
                <DialogContent>
                    <TextField
                        margin="normal"
                        fullWidth
                        label={titleLabel}
                        {...register("title", { required: true })}
                        error={!!errors.title}
                        helperText={errors.title ? "This field is required" : ""}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="URL"
                        {...register("url")}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label={descriptionLabel}
                        {...register("description")}
                        multiline
                        rows={4}
                    />

                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={ja}
                        dateFormats={{
                            monthAndYear: 'YYYY年MM月',
                            normalDate: 'YYYY年MM月DD日',
                            keyboardDate: 'YYYY年MM月DD日',
                        }}
                        localeText={{
                            previousMonth: '前へ',
                            nextMonth: '次へ',
                            cancelText: "キャンセル",
                            okText: "選択",
                        }}
                    >
                        <FormControl component="fieldset" margin="normal" fullWidth>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        views={['year', 'month', 'day']}
                                        openTo="year" // 年から選べるようにする
                                        format="YYYY年MM月DD日" // input後のform内の表示を変える
                                        label={dateLabel}
                                        value={field.value ? dayjs(field.value) : null}
                                        onChange={(newValue) => {
                                            field.onChange(newValue && dayjs(newValue).isValid() ? newValue : null);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        DialogProps={{
                                            sx: {
                                                '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                    fontSize: '1.5rem',
                                                },
                                            },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    {data?.id && ( // dataにidが存在する場合のみ削除ボタンを表示
                        <Button
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDelete(data.id)}
                            color="error"
                        >
                            削除
                        </Button>
                    )}
                    <Button onClick={handleClose} color="primary" variant='outlined'>
                        キャンセル
                    </Button>
                    <Button type="submit" color="primary" variant="contained" disabled={!isValid}>
                        保存
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default HighlightCardEditModal;
