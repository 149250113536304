import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { EmailAuthProvider, linkWithCredential } from 'firebase/auth';
import { auth } from '../../firebase';
import { useSnackbar } from '../../Context/SnackbarContext';

const AddEmailPasswordDialog = ({ open, handleClose }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        mode: 'onChange'
    });
    const { showSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleAddEmailPassword = async (data) => {
        const currentUser = auth.currentUser;
        setLoading(true);

        try {
            const credential = EmailAuthProvider.credential(data.email, data.password);
            await linkWithCredential(currentUser, credential);
            showSnackbar({ message: 'Email/Password認証が追加されました', type: 'success' });
            handleClose(); // ダイアログを閉じる
        } catch (error) {
            console.error('Email/Passwordの追加エラー:', error);
            showSnackbar({ message: `エラー: ${error.message}`, type: 'error' });
        } finally {
            setLoading(false);
            reset(); // フォームをリセット
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit(handleAddEmailPassword)}>
                <DialogTitle>Email/Password認証を追加</DialogTitle>
                <DialogContent>
                    <Box sx={{ '& .MuiTextField-root': { m: 1 } }}>
                        <TextField
                            label="メールアドレス"
                            type="email"
                            fullWidth
                            {...register('email', {
                                required: 'メールアドレスは必須です。',
                                pattern: {
                                    value: /^\S+@\S+\.\S+$/,
                                    message: '有効なメールアドレスを入力してください。'
                                }
                            })}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                        <TextField
                            label="パスワード"
                            type="password"
                            fullWidth
                            {...register('password', {
                                required: 'パスワードは必須です。',
                                minLength: { value: 6, message: 'パスワードは6文字以上である必要があります。' }
                            })}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? '処理中...' : '追加'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AddEmailPasswordDialog;
