import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Avatar, Paper } from '@mui/material';

const ScrollFooterBarAIAndPerson = ({ avatarSrc, company, message, startOrJoinChat }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(window.pageYOffset > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <Box>
            <Paper elevation={8} sx={{
                position: 'fixed',
                bottom: 20,
                left: { xs: '0%', sm: '50%' },
                transform: { xs: 'translateX(2.5%)', sm: 'translateX(-50%)' },
                maxWidth: { xs: '95%', md: '960px' },
                bgcolor: 'white',
                color: 'black',
                width: { xs: '95%', sm: '90%' },
                p: 2,
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: 'center',
                visibility: isVisible ? 'visible' : 'hidden',
                opacity: 1,
                transition: 'visibility 0.3s, opacity 0.3s linear',
                borderRadius: '5px',
                zIndex: 1000, // ここに追加
            }}>
                <Avatar sx={{ marginRight: 1, marginBottom: { xs: 2, sm: 0 }, display: { xs: 'none', sm: 'block' } }} src={avatarSrc} />
                <Box sx={{ flex: 1, mx: 2, textAlign: { xs: 'center', sm: 'left' } }}>
                    <Typography variant="subtitle2">{company?.name}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {message}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, gap: 1 }}>
                    <Button variant='contained' onClick={() => startOrJoinChat({ chatTag: 'AI担当者' })}>AIと話す</Button>
                    {company?.allowsDirectChat &&
                        <Button variant='contained' onClick={() => startOrJoinChat({ chatTag: '企業担当者' })}>担当者と話す</Button>
                    }
                </Box>
            </Paper>
        </Box>
    );
};

export default ScrollFooterBarAIAndPerson;