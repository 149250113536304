import React, { useState } from 'react';
import { Box, Stack, Divider, Typography, Chip, Avatar, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import { red } from '@mui/material/colors';
import EditCompanyModal from './EditCompanyModal';
import ApplicantContactModal from '../Chat/ApplicantContactModal';
import { firestore } from '../../firebase';
import { useSnackbar } from '../../Context/SnackbarContext';
import { doc, setDoc } from 'firebase/firestore';
import { useCompany } from '../../Context/CompanyContext';

const CompanyInfoList = ({ isUnique, hasEditPermission }) => {
    // 企業の紹介ページでこのパーツを使う際に，タグのチップがいらないので，その場合はisUnique=falseとして対応できる．
    // isUniqueがtrueの場合はtrue、それ以外の場合はfalseを返す
    isUnique = isUnique ? true : false;
    const { company } = useCompany();

    const [open, setOpen] = useState(false);
    const [applicantModalOpen, setApplicantModalOpen] = useState(false); // [1] モーダルの表示状態を管理するstate

    const { showSnackbar } = useSnackbar();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSave = async(info) => {
        try {
            // Firestoreに保存
            const docRef = doc(firestore, "companies", company.id); // `company.id` はこのコンテキストで正しいと仮定
            await setDoc(docRef, info); // `info` に更新された企業情報が含まれているため、これを使用
    
            // 成功メッセージを表示
            showSnackbar({ message: "企業情報を更新しました", type: 'success', title: '更新成功' });
    
            // モーダルを閉じる
            handleClose();
        } catch (e) {
            console.error("Error adding document: ", e);
            showSnackbar({ message: "企業情報の更新に失敗しました", type: 'error', title: '更新失敗' });
        }
    };

    const onApplicantContactButton = () => {
        setApplicantModalOpen(true);
    };

    return (
        <Box sx={{ mt: 4 }}>
            <ApplicantContactModal isOpen={applicantModalOpen} setIsOpen={setApplicantModalOpen} companyId={company?.id} />
            {hasEditPermission ? (
                <>
                    <Button variant="outlined" onClick={handleOpen}>会社情報を編集</Button>
                    <EditCompanyModal
                        open={open}
                        handleClose={handleClose}
                        handleSave={handleSave}
                    />
                </>
            ) : (
                <>
                    {isUnique &&
                        <>
                            <Box sx={{ marginY: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="contained" onClick={onApplicantContactButton} >担当者と話してみる</Button>
                            </Box>
                            <Divider />
                            <Stack spacing={0} sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="subtitle1" color='black' sx={{ fontWeight: 'bold', mb: 2 }}>募集の特徴</Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {/* チップのリスト。ここでデータを動的にレンダリングする */}
                                    {company?.tags && company.tags.length > 0 && company.tags.map((tag, index) => (
                                        <Chip key={index} label={tag} size="small" variant="outlined" sx={{ mr: 1 }} />
                                    ))}
                                </Box>
                            </Stack>
                            <Divider />
                        </>
                    }
                    <Stack spacing={0} sx={{ mt: 2, mb: 2 }}>
                        <Typography variant="subtitle1" color='black' sx={{ fontWeight: 'bold', mb: 2 }}>会社情報</Typography>
                        <Box display="flex" alignItems="center">
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                YA
                            </Avatar>
                            <Typography variant="subtitle1"
                                sx={{
                                    ml: 1,
                                    fontWeight: 'bold',
                                    color: 'black', // テキストの色を継承する
                                    textDecoration: 'none', // 通常時は下線を非表示にする
                                }}
                                component={Link} to={`/post/company/1`} >
                                {company?.name}
                            </Typography>
                        </Box>
                        <Box sx={{ bgcolor: 'background.paper' }}>
                            <List dense={true}>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <BusinessIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={`設立年月: ${company?.establishedDate}`} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <PeopleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={`メンバー: ${company?.employeeCount}`} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                {/* <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <StarBorderIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="海外進出している" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem> */}
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <LocationOnIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={`${company?.address}`} primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                </ListItem>
                                <ListItem disableGutters sx={{ alignItems: 'flex-start' }}>
                                    <ListItemIcon sx={{ mt: 0.5, minWidth: '1.75rem' }}>
                                        <LanguageIcon fontSize="small" />
                                    </ListItemIcon>
                                    {/* テキストをLinkコンポーネントでラップし、クリック可能にする */}
                                    <ListItemText primary={<Link href={company?.websiteUrl} target="_blank" rel="noopener" primaryTypographyProps={{ fontSize: '0.875rem' }} style={{ textDecoration: 'none' }}>{company?.websiteUrl}</Link>} />
                                </ListItem>
                            </List>
                        </Box>
                    </Stack>
                </>
            )}
        </Box>
    )
}

export default CompanyInfoList;