import React from 'react';
import { Paper, Typography } from '@mui/material';

const HighlightCard = ({ title, date, onClick }) => {
    // 日付が存在しない場合の表示を設定
    const displayDate = date ? date : "日付なし";
    return (
        <Paper
            sx={{
                p: 2,
                mt: 2,
                transition: 'background-color 0.3s ease', // 背景色の変更にトランジションを適用
                '&:hover': {
                    backgroundColor: 'action.hover', // ホバー時の背景色をMuiテーマのアクションホバーカラーに設定
                },
            }}
            onClick={onClick}
        >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{title}</Typography>
            <Typography variant="caption" color="text.secondary">{displayDate}</Typography>
        </Paper>

    );
}

export default HighlightCard;