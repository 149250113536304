// React & ルーター関連
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Firebase 関連
import { auth, firestore } from '../../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, serverTimestamp, setDoc, Timestamp, getDoc } from "firebase/firestore";
import { OAuthProvider, signInWithPopup } from "firebase/auth";

// MUI コンポーネント & アイコン
import {
	TextField, Typography, Button, FormControl, Container, RadioGroup, FormControlLabel, Radio, FormLabel, Box, Grid, InputAdornment,
	InputLabel, Select, MenuItem, Link
} from '@mui/material';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LockIconOutlined from '@mui/icons-material/LockOutlined';
import PhoneIconOutlined from '@mui/icons-material/PhoneOutlined';

// 日付ピッカー & dayjs
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

// カスタムコンポーネント & コンテキスト
import NavigationBar from '../../components/Layout/NavigationBar';
import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import Footer from '../../components/Layout/Footer';

// ユーティリティ & カスタムフック
import { useSnackbar } from '../../Context/SnackbarContext';
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';
import { EDUCATION_OPTIONS } from '../../utils/options';
import { calculateAge } from '../../utils/helpers/calculateAge';

const StudentSignupForm = () => {
	const { register, handleSubmit, control, formState: { errors } } = useForm({
		mode: "onChange",
		defaultValues: {
			birthDate: dayjs(), // ここでdayjsのデフォルト値を設定することもできます
		}
	});

	// 関数の取得
	const { showSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		try {
			// DatePicker から得られる日付が Date オブジェクトの場合、それを Timestamp に変換
			const birthDateTimestamp = Timestamp.fromDate(new Date(data.birthDate));
			const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
			const user = userCredential.user;

			// Firestoreにユーザー情報を保存
			await setDoc(doc(firestore, "users", user.uid), {
				email: data.email,
				phoneNumber: data.phoneNumber,
				lastName: data.lastName,
				firstName: data.firstName,
				birthDate: birthDateTimestamp,
				gender: data.gender,
				userType: data.userType,
				createdAt: serverTimestamp(),
				updatedAt: serverTimestamp(),
				isDeleted: false,
				emailNotification: 'notify',
				age: calculateAge(new Date(data.birthDate)),
				hasCatchphrase: false,
				hasProfile: false,
				hasGoals: false,
				hasAwards: false,
				hasCertifications: false,
				hasUsericonurl: false,
				// schoolName: data.schoolName,
				// grade: data.grade,
				// studentCardUrl: studentCardUrl,
			});

			showSnackbar({ message: "登録に成功しました", type: 'success', title: '登録成功' });
			navigate('/'); // ログイン後のページに遷移
		} catch (error) {
			const errorMessage = getFirebaseErrorMessage({
				defaultErrorMessage: 'アカウントの作成に失敗しました。もう一度試してください。',
				errorCode: error.code
			});
			showSnackbar({ message: errorMessage, type: 'error', title: '登録失敗' });
		}
	};

	const handleOIDCSignup = async () => {
		try {
			// Storage Access API を使用してストレージアクセスを確認
			if (document.hasStorageAccess && !await document.hasStorageAccess()) {
				await document.requestStorageAccess();
			}
			const userCredential = await signInWithPopup(auth, new OAuthProvider('oidc.line'));
			const user = userCredential.user;
			// Firestoreでユーザードキュメントを確認
			const userDocRef = doc(firestore, 'users', user.uid);
			const userDoc = await getDoc(userDocRef);

			if (!userDoc.exists()) {
				// Firestoreにユーザー情報を保存
				await setDoc(doc(firestore, "users", user.uid), {
					email: '',
					phoneNumber: '',
					lastName: user.displayName,
					firstName: '',
					birthDate: '',
					gender: 'other',
					userType: 'other',
					createdAt: serverTimestamp(),
					updatedAt: serverTimestamp(),
					isDeleted: false,
					emailNotification: 'notify',
					age: 16,
					hasCatchphrase: false,
					hasProfile: false,
					hasGoals: false,
					hasAwards: false,
					hasCertifications: false,
					hasUsericonurl: false,
					providerId: 'line',
				});
			}

			// step3 サインアップ成功後の処理（例：ホーム画面へのリダイレクト）をここに記述
            showSnackbar({ message: "LINEサインアップしました", type: 'success' });
			navigate('/');
		} catch (err) {
			showSnackbar({ message: 'LINEサインアップに失敗しました', type: 'error', title: 'ログインに失敗' });
		}
	};

	return (
		<>
			<NavigationBar />
			<ResponsiveContainer>
				<Container component="main" maxWidth="xs">
					<Typography variant="h5" component="h1" gutterBottom>アカウント登録</Typography>
					<form onSubmit={handleSubmit(onSubmit)}>
						<TextField
							label="メールアドレス"
							variant="outlined"
							type="email"
							fullWidth
							margin="normal"
							{...register("email", { required: "メールアドレスは必須です", pattern: /^\S+@\S+\.\S+$/ })}
							error={!!errors.email}
							helperText={errors.email?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIconOutlined />
									</InputAdornment>
								),
							}}
						/>

						<TextField
							label="パスワード"
							variant="outlined"
							type="password"
							fullWidth
							margin="normal"
							{...register("password", { required: "パスワードは必須です", minLength: { value: 6, message: "パスワードは6文字以上である必要があります" } })}
							error={!!errors.password}
							helperText={errors.password?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIconOutlined />
									</InputAdornment>
								),
							}}
						/>

						<TextField
							label="電話番号"
							variant="outlined"
							fullWidth
							type="tel"
							margin="normal"
							{...register("phoneNumber", {
								required: "電話番号は必須です",
								pattern: {
									value: /^(\+?\d[- .]*){7,13}$/,
									message: "無効な電話番号です"
								}
							})}
							error={!!errors.phoneNumber}
							helperText={errors.phoneNumber?.message}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PhoneIconOutlined />
									</InputAdornment>
								),
							}}
						/>
						<Grid container spacing={2}> {/* コンテナGrid */}
							<Grid item xs={6}> {/* 各TextFieldをラップするGridアイテム */}
								<TextField
									label="苗字"
									variant="outlined"
									fullWidth
									type="text"
									margin="normal"
									{...register("lastName", { required: "苗字は必須です" })}
									error={!!errors.lastName}
									helperText={errors.lastName?.message}
								/>
							</Grid>
							<Grid item xs={6}> {/* 各TextFieldをラップするGridアイテム */}
								<TextField
									label="名前"
									variant="outlined"
									type="text"
									fullWidth
									margin="normal"
									{...register("firstName", { required: "名前は必須です" })}
									error={!!errors.firstName}
									helperText={errors.firstName?.message}
								/>
							</Grid>
						</Grid>

						<FormControl fullWidth margin="normal">
							<InputLabel id="userType-label">種別</InputLabel>
							<Controller
								name="userType"
								control={control}
								rules={{ required: "種別を選択してください" }}
								render={({ field }) => (
									<Select
										labelId="userType-label"
										id="userType"
										label="種別"
										{...field}
									>
										<MenuItem value="">
											<em>未選択</em>
										</MenuItem>
										{EDUCATION_OPTIONS.map((option) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
							{errors.userType && <Typography color="error">{errors.userType.message}</Typography>}
						</FormControl>


						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							adapterLocale={ja}
							dateFormats={{
								monthAndYear: 'YYYY年MM月',
								normalDate: 'YYYY年MM月DD日',
								keyboardDate: 'YYYY年MM月DD日',
							}}
							localeText={{
								previousMonth: '前へ',
								nextMonth: '次へ',
								cancelText: "キャンセル",
								okText: "選択",
							}}
						>
							<FormControl component="fieldset" margin="normal" fullWidth>
								<Controller
									name="birthDate"
									control={control}
									rules={{ required: "生年月日は必須です" }}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<DatePicker
											views={['year', 'month', 'day']}
											openTo="year" // 年から選べるようにする
											format="YYYY年MM月DD日" // input後のform内の表示を変える
											label="生年月日"
											value={value}
											onChange={onChange}
											renderInput={(params) => (
												<TextField
													{...params}
													error={!!error}
													helperText={error ? error.message : null}
													fullWidth
													margin="normal"
												/>
											)}
											DialogProps={{
												sx: {
													'& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
														fontSize: '1.5rem',
													},
												},
											}}
										/>
									)}
								/>
							</FormControl>
						</LocalizationProvider>

						<FormControl component="fieldset" margin="normal" fullWidth>
							<FormLabel component="legend">性別</FormLabel>
							<Controller
								name="gender"
								control={control}
								rules={{ required: "性別を選択してください" }}
								render={({ field }) => (
									<RadioGroup {...field} row>
										<FormControlLabel value="male" control={<Radio />} label="男性" />
										<FormControlLabel value="female" control={<Radio />} label="女性" />
										<FormControlLabel value="other" control={<Radio />} label="その他" />
									</RadioGroup>
								)}
							/>
							{errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
						</FormControl>
						<Typography variant="body2">
							登録することで、<Link href="https://www.cielinc.co.jp/terms" target="_blank" style={{ textDecoration: 'none' }}>利用規約</Link>および<Link href="https://www.cielinc.co.jp/privacy-policy" target="_blank" style={{ textDecoration: 'none' }}>プライバシーポリシー</Link>に同意したものとします。
						</Typography>
						<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
							<Button type="submit" variant="contained" color="primary" fullWidth size="large" sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2, fontWeight: 'bold' }}>
								アカウント登録
							</Button>
						</Box>
					</form>
					<Button
						variant='contained'
						onClick={handleOIDCSignup}
						size="large"
						fullWidth
						sx={{
							borderRadius: '8px',
							paddingY: 1.5,
							marginTop: 2,
							backgroundColor: '#06C755',
							fontWeight: 'bold',
							'&:hover': {
								backgroundColor: 'rgba(6, 199, 85, 0.9)', // 透明度10%追加
								boxShadow: 'none', // デフォルトのMUIホバーシャドウを無効化
							},
							'&:active': {
								backgroundColor: 'rgba(6, 199, 85, 0.7)', // 透明度30%追加
								boxShadow: 'none', // アクティブ時もシャドウを無効化
							}
						}}
					>
						LINEでサインアップ
					</Button>
					<Typography variant="body2" align="" sx={{ mt: 2 }}>LINEログインは一般ユーザーのみ利用できます</Typography>
				</Container>
			</ResponsiveContainer >
			<Footer />
		</>
	);
}
	;

export default StudentSignupForm;