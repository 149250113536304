// React とそのフック
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// 日付処理
import dayjs from 'dayjs';

// Firebase 関連のインポート
import { auth, firestore } from '../../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc, collectionGroup, query, where, getDocs, serverTimestamp, Timestamp, increment, updateDoc } from "firebase/firestore";

// MUI コンポーネント
import { TextField, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';

// アプリケーション固有のコンテキストとコンポーネント
import { useSnackbar } from '../../Context/SnackbarContext';
import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';

// ユーティリティ関数
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';

import { calculateAge } from '../../utils/helpers/calculateAge';
import { Box } from '@mui/system';

const CompanySignupForm = () => {
    const [companyId, setCompanyId] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [role, setRole] = useState('');
    const [companyData, setCompanyData] = useState(null);
    const [companyRef, setCompanyRef] = useState(null); // 企業の参照を保存するためのステート
    const [authIdDocRef, setAuthIdDocRef] = useState(null);

    const { register, handleSubmit, control, formState: { errors }, setError } = useForm({
        mode: "onChange",
        defaultValues: {
            birthDate: dayjs(), // ここでdayjsのデフォルト値を設定することもできます
        }
    });
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    // 認証関数の実装
    const authenticateCompanyID = async (authId) => {
        const q = query(collectionGroup(firestore, 'authIds'), where('authId', '==', authId), where('isDeleted', '==', false));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            // No matching documents.
            showSnackbar({ message: "無効な認証IDです。", type: 'error', title: '認証失敗' });
            return false; // 認証失敗
        } else {
            // 最初に見つかったドキュメントのみを取得
            const firstDoc = querySnapshot.docs[0];
            setAuthIdDocRef(firstDoc.ref); // 認証IDドキュメントへの参照を保存
            const role = firstDoc.data()?.role; // `role`フィールドの取得
            setRole(role);
            const companyRef = firstDoc.ref.parent.parent; // 企業ドキュメントへの参照

            // 企業ドキュメントを取得
            const companyDoc = await getDoc(companyRef);
            if (!companyDoc.exists()) {
                // 企業ドキュメントが見つかりません。
                setError("companyId", { type: "manual", message: "企業ドキュメントが見つかりません。" });
                return false;
            } else {
                // 企業ドキュメントから必要な情報を取得
                const companyData = companyDoc.data();
                showSnackbar({ message: `${role}ユーザーとして登録を行います`, type: 'success', title: '認証成功' });
                setCompanyData(companyData);
                setCompanyRef(companyRef); // 企業の参照をステートに保存
                setIsAuthenticated(true);
            }
        }
    };


    const onSubmit = async (data) => {
        try {
            // DatePicker から得られる日付が Date オブジェクトの場合、それを Timestamp に変換
            const birthDateTimestamp = Timestamp.fromDate(new Date(data.birthDate));
            // ユーザー認証情報の登録
            const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
            const user = userCredential.user;

            // Firestoreに企業ユーザー情報を保存
            await setDoc(doc(firestore, "users", user.uid), {
                email: data.email,
                lastName: data.lastName,
                firstName: data.firstName,
                birthDate: birthDateTimestamp,
                gender: data.gender,
                userType: "company",
                role: role,
                companyId: companyRef.id, // 企業のIDをユーザードキュメントに追加
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                isDeleted: false,
                emailNotification: 'notify',
                age: calculateAge(new Date(data.birthDate)),
                hasCatchphrase: false,
                hasProfile: false,
                hasGoals: false,
                hasAwards: false,
                hasCertifications: false,
                hasUsericonurl: false,
            });

            // 認証IDのusedCountをインクリメント
            if (authIdDocRef) {
                await updateDoc(authIdDocRef, {
                    usedCount: increment(1),
                    updatedAt: serverTimestamp()
                });
            }

            showSnackbar({ message: "企業アカウントの登録に成功しました", type: 'success', title: '登録成功' });
            navigate(`/company/${companyRef.id}/dashboard`); // 登録後、ホームへリダイレクト
        } catch (error) {
            const errorMessage = getFirebaseErrorMessage({
                defaultErrorMessage: 'アカウントの作成に失敗しました。もう一度試してください。',
                errorCode: error.code
            });
            showSnackbar({ message: errorMessage, type: 'error', title: '登録失敗' });
        }
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                {!isAuthenticated ? (
                    <>
                        <Box sx={{ textAlign: 'center', marginBottom: 2, minHeight: '60vh' }}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                企業アカウント登録
                            </Typography>
                            <TextField
                                label="企業ID"
                                variant="outlined"
                                {...register("companyId")}
                                value={companyId}
                                onChange={(e) => setCompanyId(e.target.value)}
                                fullWidth
                                margin="normal"
                                error={!!errors.companyId}
                                helperText={errors.companyId?.message}
                            />
                            <Button onClick={() => authenticateCompanyID(companyId)} variant="contained" color="primary" fullWidth sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}>
                                認証
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography variant="h4" component="h1" gutterBottom>アカウント登録</Typography>
                        <Typography variant="h6">{companyData?.name}</Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/* フォームフィールド */}
                            <TextField label="メールアドレス" variant="outlined" fullWidth margin="normal" {...register("email", { required: "メールアドレスは必須です", pattern: /^\S+@\S+\.\S+$/ })} error={!!errors.email} helperText={errors.email?.message} />
                            <TextField label="パスワード" variant="outlined" type="password" fullWidth margin="normal" {...register("password", { required: "パスワードは必須です", minLength: { value: 6, message: "パスワードは6文字以上である必要があります" } })} error={!!errors.password} helperText={errors.password?.message} />
                            <TextField
                                label="苗字"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("lastName", { required: "苗字は必須です" })}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                label="名前"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                {...register("firstName", { required: "名前は必須です" })}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={ja}
                                dateFormats={{
                                    monthAndYear: 'YYYY年MM月',
                                    normalDate: 'YYYY年MM月DD日',
                                    keyboardDate: 'YYYY年MM月DD日',
                                }}
                                localeText={{
                                    previousMonth: '前へ',
                                    nextMonth: '次へ',
                                    cancelText: "キャンセル",
                                    okText: "選択",
                                }}
                            >
                                <FormControl component="fieldset" margin="normal" fullWidth>
                                    <Controller
                                        name="birthDate"
                                        control={control}
                                        rules={{ required: "生年月日は必須です" }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <DatePicker
                                                views={['year', 'month', 'day']}
                                                openTo="year" // 年から選べるようにする
                                                format="YYYY年MM月DD日" // input後のform内の表示を変える
                                                label="生年月日"
                                                value={value}
                                                onChange={onChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                )}
                                                DialogProps={{
                                                    sx: {
                                                        '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                            fontSize: '1.5rem',
                                                        },
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>

                            <FormControl component="fieldset" margin="normal">
                                <FormLabel component="legend">性別</FormLabel>
                                <Controller
                                    name="gender"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: "性別を選択してください" }}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            value={field.value}
                                            onChange={field.onChange}
                                        >
                                            <FormControlLabel value="male" control={<Radio />} label="男性" />
                                            <FormControlLabel value="female" control={<Radio />} label="女性" />
                                            <FormControlLabel value="other" control={<Radio />} label="その他" />
                                        </RadioGroup>
                                    )}
                                />
                                {errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
                            </FormControl>
                            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}>登録</Button>
                        </form>
                    </>
                )}
            </ResponsiveContainer>
            <Footer />
        </>
    );
}

export default CompanySignupForm;