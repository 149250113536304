import React from 'react';
import { Box, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import SaveOnBlurSelect from '../Input/SaveOnBlurSelect';
import SaveOnBlurTextField from '../Input/SaveOnBlurTextField';
import NotesIcon from '@mui/icons-material/Notes';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';
import { tooltipClasses } from '@mui/material/Tooltip';

const corporateValues = [
    { value: 'none', valueId: 'none', label: '選択してください' },
    { value: 'innovation', valueId: 'innovation', label: '革新' },
    { value: 'integrity', valueId: 'integrity', label: '誠実' },
    { value: 'quality', valueId: 'quality', label: '品質' },
    { value: 'teamwork', valueId: 'teamwork', label: 'チームワーク' },
    { value: 'customerFocus', valueId: 'customerFocus', label: '顧客重視' },
    { value: 'respect', valueId: 'respect', label: '尊敬' },
    { value: 'efficiency', valueId: 'efficiency', label: '効率' },
    { value: 'reliability', valueId: 'reliability', label: '信頼性' },
    { value: 'creativity', valueId: 'creativity', label: '創造性' },
    { value: 'sustainability', valueId: 'sustainability', label: '持続可能性' },
];

const ValueTable = ({
    hasEditPermission,
    onSave,
    companyValues,
    handleButtonClick,
    focusStatus,
    isLoading,
    planName,
}) => {
    // companyValues の長さが 3 未満の場合、不足分のダミーデータを追加
    const safeCompanyValues = companyValues || [];

    // safeCompanyValues の長さが 3 未満の場合、不足分のダミーデータを追加
    const displayValues = safeCompanyValues.length >= 3 ? safeCompanyValues : [
        ...safeCompanyValues,
        ...Array(3 - safeCompanyValues.length).fill({}).map((_, i) => ({
            valueId: `none`,
            description: '',
        })),
    ];

    return (
        <Table>
            <TableBody>

                {displayValues.map((value, index) => (
                    <TableRow key={index}>
                        <TableCell sx={{ verticalAlign: 'top', width: 200 }}>
                            {hasEditPermission ? (
                                <SaveOnBlurSelect
                                    // initialValue={value.valueId || 'none'}
                                    initialValue={value.valueId}
                                    // initialValue={"quality"}
                                    onSave={(newValueId) => onSave({ type: 'valueId', index, value: newValueId })}
                                    options={corporateValues}
                                    TypographyProps={{
                                        variant: "body1",
                                        sx: { fontWeight: 'bold' }
                                    }}
                                />
                            ) : (
                                <Typography variant="h5" gutterBottom>
                                    {corporateValues.find(v => v.valueId === value.valueId)?.label || '未定義'}
                                </Typography>
                            )}
                        </TableCell>
                        <TableCell>
                            {hasEditPermission ? (
                                <Box sx={{ position: 'relative', width: '100%' }}>
                                    <SaveOnBlurTextField
                                        initialValue={value.description || ''}
                                        onSave={(newDescription) => onSave({ type: 'description', index, value: newDescription })}
                                        TypographyProps={{ variant: "body2" }}
                                        multiline={true}
                                        rows={6}
                                        maxRows={6}
                                        greyBackground={true}
                                        label={!value.description && '入力してください'}
                                    />
                                    {!focusStatus?.[`value${index + 1}`] && (
                                        <Tooltip
                                            // title={!corporateValues.find(v => v.valueId === value.valueId)?.label || value.valueId === 'none' ? "価値観を選択してください" : "AIで生成"} 
                                            title={
                                                (!corporateValues.find(v => v.valueId === value.valueId)?.label || value.valueId === 'none') ?
                                                    "価値観を選択してください" :
                                                    (planName === 'スタンダードプラン' ? 'AIで生成' : 'このプランでは使えません')
                                            }
                                            placement="top" arrow
                                            slotProps={{
                                                popper: {
                                                    sx: {
                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                        {
                                                            marginTop: '0px',
                                                        },
                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                                        {
                                                            marginBottom: '6px',
                                                        },
                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                                        {
                                                            marginLeft: '0px',
                                                        },
                                                        [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                                        {
                                                            marginRight: '0px',
                                                        },
                                                    },
                                                },
                                            }}>
                                            <LoadingButton
                                                onClick={planName === 'スタンダードプラン' ? () => { handleButtonClick(`value${index + 1}`, corporateValues.find(v => v.valueId === value.valueId)?.label) } : null}
                                                variant="contained"
                                                sx={{
                                                    position: 'absolute',
                                                    right: 0,  // テキストフィールドの右端にボタンを配置
                                                    bottom: 0,  // テキストフィールドの中央に来るように調整
                                                    borderRadius: 5,
                                                    padding: 1,
                                                    minWidth: 0,
                                                    bgcolor: planName === 'スタンダードプラン' ? 'bgcolor.primary' : 'text.disabled',
                                                    '&:hover': {
                                                        bgcolor: planName === 'スタンダードプラン' ? 'primary.dark' : 'text.secondary',  // ホバー時の背景色
                                                    }
                                                }}
                                                loading={isLoading}
                                                disabled={!corporateValues.find(v => v.valueId === value.valueId)?.label || value.valueId === 'none'}
                                            >
                                                <NotesIcon fontSize='small' />
                                            </LoadingButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            ) : (
                                <Typography variant="body1">
                                    {value?.description || '---'}
                                </Typography>
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table >
    );
};
export default ValueTable;
