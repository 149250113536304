import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { useSnackbar } from '../../Context/SnackbarContext';
import { auth } from '../../firebase';

const UpdatePasswordDialog = ({ open, handleClose }) => {
    const { register, handleSubmit, formState: { errors, isValid }, reset } = useForm({
        mode: 'onChange' // バリデーションモードを 'onChange' に設定
    });
    const { showSnackbar } = useSnackbar();

    const handlePasswordUpdate = async (data) => {
        const { currentPassword, newPassword } = data;
        const currentUser = auth.currentUser;

        try {
            const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
            await reauthenticateWithCredential(currentUser, credential);
            await updatePassword(currentUser, newPassword);
            showSnackbar({ message: 'パスワードが更新されました。', type: 'success' });
            handleCloseDialog();
        } catch (error) {
            showSnackbar({ message: 'パスワードが違います。もう一度確認してください。', type: 'error' });
        }
    };

    // ダイアログを閉じるときにフォームをリセット
    const handleCloseDialog = () => {
        reset(); // フォームのリセット
        handleClose(); // ダイアログを閉じる
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <form onSubmit={handleSubmit(handlePasswordUpdate)}>
                <DialogTitle>パスワードの更新</DialogTitle>
                <DialogContent>
                    <TextField
                        label="現在のパスワード"
                        type="password"
                        fullWidth
                        margin="normal"
                        {...register("currentPassword", { required: "現在のパスワードは必須です。" })}
                        error={!!errors.currentPassword}
                        helperText={errors.currentPassword?.message}
                    />
                    <TextField
                        label="新しいパスワード"
                        type="password"
                        fullWidth
                        margin="normal"
                        {...register("newPassword", {
                            required: "新しいパスワードは必須です。",
                            minLength: {
                                value: 6,
                                message: "パスワードは6文字以上である必要があります。"
                            }
                        })}
                        error={!!errors.newPassword}
                        helperText={errors.newPassword?.message}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>キャンセル</Button>
                    <Button type="submit" variant='contained' disabled={!isValid}>更新</Button> {/* フォームが有効でないときはボタンを無効化 */}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UpdatePasswordDialog;
