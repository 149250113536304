import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { doc, onSnapshot } from "firebase/firestore";
import { auth, firestore as db } from '../firebase';
import { useSnackbar } from './SnackbarContext';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            if (currentUser) {
                const userDoc = doc(db, 'users', currentUser.uid);
                const unsubscribeSnapshot = onSnapshot(userDoc, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setUser({
                            uid: currentUser.uid, // Firebase Auth からのユーザーID
                            email: currentUser?.email, // Firebase Auth からのメールアドレス
                            documentId: docSnapshot.id, // Firestore のドキュメントID
                            userType: docSnapshot.data().userType,
                            primaryProviderId: !docSnapshot.data().providerData ? 'password' : docSnapshot.data().providerData[0].providerId,
                            ...docSnapshot.data()
                        });
                    } else {
                        console.error("No user found with given UID:", currentUser.uid);
                        // Optionally set user to a default state or handle the error
                    }
                    setLoading(false);
                });
                return () => unsubscribeSnapshot();
            } else {
                setUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleSignOut = async () => {
        try {
            await firebaseSignOut(auth);
            setUser(null);
            navigate('/');
            showSnackbar({ type: 'success', message: 'ログアウトしました' });
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser, loading, signOut: handleSignOut }}>
            {!loading && children}
        </UserContext.Provider>
    );
};
