import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { Container, Typography, TextField, Button } from '@mui/material';
import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';
import { useSnackbar } from '../../Context/SnackbarContext';
import { verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from "firebase/auth";

const ResetPassword = () => {
    const [actionCode, setActionCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [mode, setMode] = useState(''); // モードを管理するステート
    const { showSnackbar } = useSnackbar();

    // 初回のレンダリング時に、URLからクエリパラメータを取得
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const oobCode = queryParams.get('oobCode') || '';
        const modeParam = queryParams.get('mode') || ''; // modeパラメータを取得
        setActionCode(oobCode);
        setMode(modeParam); // モードを設定
    }, []);

    // パスワードリセットの処理
    const handlePasswordReset = async (event) => {
        event.preventDefault();

        if (actionCode === '') return; // 取得できない場合処理終了
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            await verifyPasswordResetCode(auth, actionCode);
            await confirmPasswordReset(auth, actionCode, password);
            showSnackbar({ message: "パスワードを変更しました", type: 'success' });
            window.location.href = "/login";
        } catch (error) {
            showSnackbar({ message: error.message, type: 'error' });
        }
    };

    // メールアドレス変更の処理
    const handleEmailVerification = async () => {
        try {
            await applyActionCode(auth, actionCode); // メールアドレス変更の確認
            showSnackbar({ message: "メールアドレスが確認され、変更されました", type: 'success' });
            window.location.href = "/login"; // 完了後にログインページにリダイレクト
        } catch (error) {
            showSnackbar({ message: error.message, type: 'error' });
        }
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <Container component="main" maxWidth="xs" sx={{ minHeight: '55vh' }}>
                    {mode === 'resetPassword' && (
                        <>
                            <Typography component="h1" variant="h5">パスワードをリセット</Typography>
                            <form onSubmit={handlePasswordReset}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="新しいパスワード"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="新しいパスワード（確認）"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={Boolean(error)}
                                    helperText={error}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}
                                >
                                    パスワードをリセット
                                </Button>
                            </form>
                        </>
                    )}

                    {mode === 'verifyAndChangeEmail' && (
                        <>
                            <Typography component="h1" variant="h5">メールアドレスの確認</Typography>
                            <Typography>メールアドレスを確認しています...</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleEmailVerification}
                                sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2 }}
                            >
                                メールアドレスを確認
                            </Button>
                        </>
                    )}

                    {mode === '' && (
                        <Typography>無効なリクエストです。もう一度お試しください。</Typography>
                    )}
                </Container>
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default ResetPassword;
