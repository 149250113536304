import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';

import { Button, Paper, Typography, Box } from '@mui/material';

import { firestore } from '../../firebase';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';

import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';

import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';

const ParentalAuthPage = () => {
    const { docId } = useParams(); // URLからdocIdを取得
    const [authInfo, setAuthInfo] = useState(null);
    const [choices, setChoices] = useState([]);
    const { showSnackbar } = useSnackbar();
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        // Firestoreから認証情報を取得
        const fetchAuthInfo = async () => {
            const docRef = doc(firestore, "parentalAuths", docId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setAuthInfo(docSnap.data());
            } else {
                showSnackbar({ message: "認証情報が見つかりません。", type: "error" });
                navigate('/'); // ホーム画面に戻る
            }
        };

        fetchAuthInfo();
    }, [docId, showSnackbar, navigate]);

    useEffect(() => {
        // authInfoが更新されたら新しいシャッフルされた選択肢を生成
        const generateRandomCode = () => { return Math.floor(Math.random() * 90) + 10; };

        const shuffledChoices = [
            authInfo?.authCode,
            generateRandomCode(),
            generateRandomCode(),
        ];

        // 配列をシャッフルする関数
        const shuffleArray = (array) => {
            let shuffled = [...array]; // 元の配列を変更せずにシャッフルした新しい配列を作成
            for (let i = shuffled.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
            }
            return shuffled;
        };

        setChoices(shuffleArray(shuffledChoices)); // シャッフルされた配列をセット
    }, [authInfo]);

    // 認証番号の検証と更新処理
    const verifyCode = async (selectedCode, docId, userDocId) => {
        if (selectedCode === authInfo.authCode) {
            // 認証成功の処理
            try {
                // parentalAuthsコレクションのドキュメントを更新
                const docRef = doc(firestore, "parentalAuths", docId);
                await updateDoc(docRef, {
                    isVerified: true,
                    verifiedAt: serverTimestamp(),
                });

                // ユーザードキュメントのparentalConsentをtrueに更新
                const userDocRef = doc(firestore, "users", userDocId);
                await updateDoc(userDocRef, {
                    parentalConsent: true,
                });

                showSnackbar({ message: "認証が完了しました。", type: "success" });
                navigate('/'); // ホーム画面に戻る
            } catch (error) {
                showSnackbar({ message: "認証の更新に失敗しました。", type: "error" });
            }
        } else {
            // 認証失敗の処理
            showSnackbar({ message: "認証番号が一致しません。", type: "error" });
        }
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                {/* 画面の中心に配置 */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '70vh' // 画面の高さ全体を使用
                }}>
                    <Paper sx={{ p: 4, pt: 8, pb: 8, minWidth: 400 }}>
                        {authInfo && !authInfo.isVerified ? (
                            <>
                                <Typography variant="h4" align="center">保護者認証</Typography>
                                <Typography sx={{ mt: 2, mb: 4 }} align="center">認証番号を選択してください。</Typography>
                                <Box sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-around', // ボタン間の間隔を均等に調整
                                    gap: 2, // ボタン間の間隔
                                }}>
                                    {choices.map((code) => (
                                        <Button
                                            key={code}
                                            onClick={() => verifyCode(code, docId, user.documentId)}
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 80,
                                                height: 80,
                                                borderRadius: '50%',
                                                minWidth: 'auto', // ボタンの最小幅を自動調整
                                            }}
                                            variant='contained'
                                        >
                                            <Typography variant='h4' sx={{ fontWeight: 'bold' }}>{code}</Typography>
                                        </Button>
                                    ))}
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography variant="h6" align="center">認証済みです</Typography>
                                {/* トップページへのリンク */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/')}
                                    sx={{ display: 'block', margin: 'auto', mt: 4 }}
                                >
                                    トップページへ
                                </Button>
                            </>
                        )
                        }
                    </Paper>
                </Box>
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default ParentalAuthPage;

