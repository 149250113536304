import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightCard from './HighlightCard';

const sortByDate = (a, b) => {
    // 日付が存在しない項目は、常に後ろに配置
    if (!a.date) return 1;
    if (!b.date) return -1;

    // 日付を比較して並び替え
    return new Date(b.date) - new Date(a.date);
};


const CertificationOrAward = ({ title, items, onAdd, onEdit, isUser }) => {

    return (
        <>
            <Divider sx={{ mt: 4, mb: 2 }} />
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mr: 3 }}>
                    {title}
                </Typography>
                {isUser && (
                    <Button
                        onClick={onAdd}
                        color="primary"
                        size='small'
                        startIcon={<AddCircleOutlineIcon />}
                        variant="contained"
                    >
                        追加
                    </Button>
                )}
            </Box>
            {items.length > 0 ? (
                items.sort(sortByDate).map((item, index) => (
                    <HighlightCard
                        key={index}
                        title={item.title}
                        date={item.date}
                        onClick={() => onEdit(item)}
                    />
                ))
            ) : (
                <Typography variant="body1" color="text.secondary">
                    {title}が登録されていません。
                </Typography>
            )}
        </>
    )
};

export default CertificationOrAward;