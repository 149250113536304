// React core
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// MUI components
import {
    Box, Typography, FormControl, InputLabel, Select, MenuItem, Button,
    // Accordion, AccordionSummary, AccordionDetails,
    useTheme, useMediaQuery,
    Chip, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Fab
} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import VerifiedIcon from '@mui/icons-material/Verified';

// Firebase
import { firestore, auth } from '../firebase';
import { doc, setDoc, getDoc } from "firebase/firestore";

// Context hooks
import { useUser } from '../Context/UserContext';
import { useSnackbar } from '../Context/SnackbarContext';

// Local components
import ResponsiveContainer from '../components/Layout/ResponsiveContainer';
import ProfileTopNavigationContent from '../components/Profile/ProfileTopNavigationContent';
import LabelledInput from '../components/Layout/LabelledInput';
import UpdateEmailDialog from '../components/Profile/UpdateEmailDialog';
import UpdatePasswordDialog from '../components/Profile/UpdatePasswordDialog';
// import ParentalConsentStepper from '../components/Profile/ParentalConsentStepper';
import NavigationBar from '../components/Layout/NavigationBar';
import Footer from '../components/Layout/Footer';
import GifteeCampaignDialog from '../components/Input/GifteeCampaignDialog';
import AddEmailPasswordDialog from '../components/Auth/AddEmailPasswordDialog';

// Utils and data
import { ASPIRATIONS_OPTIONS, SCHOOL_OPTIONS, GRADE_OPTIONS, GENDER_OPTIONS, INTERESTING_WORK_CATEGORY_OPTIONS, INTERESTING_LEARN_CATEGORY_OPTIONS, EMAIL_NOTIFICATION_OPTIONS } from '../utils/options';
import { getLabelByValue } from '../utils/helpers/getLabelByValue';

// import ReferralModal from '../components/Profile/ReferralModal';


const LanguageSelect = ({ language, onLanguageChange }) => {
    return (
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <FormControl fullWidth>
                <InputLabel>言語</InputLabel>
                <Select label="言語" value={language.lang} onChange={(e) => onLanguageChange('lang', e.target.value)}>
                    <MenuItem value="日本語">日本語</MenuItem>
                    <MenuItem value="英語">英語</MenuItem>
                    {/* 他の言語 */}
                </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 150 }}>
                <InputLabel>レベル</InputLabel>
                <Select label="レベル" value={language.level} onChange={(e) => onLanguageChange('level', e.target.value)}>
                    <MenuItem value="勉強中">勉強中</MenuItem>
                    <MenuItem value="日常会話">日常会話</MenuItem>
                    <MenuItem value="ビジネス会話">ビジネス会話</MenuItem>
                    <MenuItem value="ネイティブ">ネイティブ</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

const ProfileEdit = () => {

    // ユーザー情報を取得
    const { id } = useParams();
    const [displayUser, setDisplayUser] = useState(null);
    const { user } = useUser();
    const isUser = user?.uid === id;
    const [eventDialogOpen, setEventDialogOpen] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            const userDocRef = doc(firestore, "users", id);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                setDisplayUser({ documentId: userDoc.id, ...userDoc.data() });
            }
        };
        if (id) {
            fetchUser();
        }
        if (id === user?.uid) {
            setDisplayUser(user);
        } else {
            fetchUser();
        }
    }, [id, user]);

    let initialFormData;
    // 初期フォームデータを保持するための状態
    if (user) {
        initialFormData = {
            lastName: user.lastName,
            firstName: user.firstName,
            password: '',
            languages: user.languages,
            aspirations: user.aspirations, // 他のフォーム要素の初期値
            gender: user.gender, // 他のフォーム要素の初期値
            email: user.email, // 他のフォーム要素の初期値
            emailAddresses: [
                { email: auth.currentUser.email, description: "ログイン用のメールアドレス" }, // ログイン用メールアドレス
                { email: user.email2 || "", description: "サブメールアドレス1" }, // サブメールアドレス1
                { email: user.email3 || "", description: "サブメールアドレス2" }  // サブメールアドレス2
            ],
            phoneNumber: user.phoneNumber, // 他のフォーム要素の初期値
            grade: user.grade, // 他のフォーム要素の初期値
            schoolName: user.schoolName, // 他のフォーム要素の初期値
            postalCode: user.postalCode, // 他のフォーム要素の初期値
            prefecture: user.prefecture, // 他のフォーム要素の初期値
            city: user.city, // 他のフォーム要素の初期値
            streetAddress: user.streetAddress, // 他のフォーム要素の初期値
            emailNotification: user?.emailNotification || '', // 他のフォーム要素の初期値
            interestsEmploymentTags: user?.interestsEmploymentTags, // 他のフォーム要素の初期値
            interestsEducationTags: user?.interestsEducationTags, // 他のフォーム要素の初期値
        };
    } else {
        initialFormData = {
            lastName: user?.lastName,
            firstName: user?.firstName,
            password: '',
            languages: user?.languages,
            aspirations: user?.aspirations, // 他のフォーム要素の初期値
            gender: user?.gender, // 他のフォーム要素の初期値
            email: user?.email, // 他のフォーム要素の初期値
            emailAddresses: [
                { email: auth?.currentUser?.email, description: "ログイン用のメールアドレス" }, // ログイン用メールアドレス
                { email: user?.email2 || "", description: "サブメールアドレス1" }, // サブメールアドレス1
                { email: user?.email3 || "", description: "サブメールアドレス2" }  // サブメールアドレス2
            ],
            phoneNumber: user?.phoneNumber, // 他のフォーム要素の初期値
            grade: user?.grade, // 他のフォーム要素の初期値
            schoolName: user?.schoolName, // 他のフォーム要素の初期値
            postalCode: user?.postalCode, // 他のフォーム要素の初期値
            prefecture: user?.prefecture, // 他のフォーム要素の初期値
            city: user?.city, // 他のフォーム要素の初期値
            streetAddress: user?.streetAddress, // 他のフォーム要素の初期値
            emailNotification: user?.emailNotification || '', // 他のフォーム要素の初期値
            interestsEmploymentTags: user?.interestsEmploymentTags, // 他のフォーム要素の初期値
            interestsEducationTags: user?.interestsEducationTags, // 他のフォーム要素の初期値
        }
    }
    const [formData, setFormData] = useState(initialFormData);

    // モーダルの開閉状態を管理するオブジェクト
    const [dialogsOpen, setDialogsOpen] = useState({
        updatePassword: false,
        updateEmail: false,
    });
    // 認証セクションの開閉状態を管理するためのステート
    // const [expanded, setExpanded] = useState(!user?.parentalConsent);

    const { showSnackbar } = useSnackbar();

    // const handleChange = () => (isExpanded) => {
    //     setExpanded(!expanded);
    // };

    const theme = useTheme();
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));

    // Container maxWidth="md" の幅
    const maxWidthMD = theme.breakpoints.values.md; // 960px がデフォルト値
    const containerPadding = theme.spacing(3); // Container の左右のパディングを考慮

    // ビューポートが md サイズ以上の場合の右マージンを計算
    const rightMargin = matchesMD
        ? `calc((100vw - ${maxWidthMD}px) / 2 + ${containerPadding})`
        : `${theme.spacing(2)}`; // md サイズ未満の場合は 16px（theme.spacing(2)）


    // モーダルの開閉を制御する汎用関数
    const toggleModal = (modalName, isOpen) => {
        setDialogsOpen(prev => ({ ...prev, [modalName]: isOpen }));
    };

    // 入力の変更を処理する関数
    const handleInputChange = (fieldName, newValue, index = 0) => {
        if (fieldName === 'emailAddresses') {
            // emailAddresses 配列内の特定のメールアドレスを更新
            const updatedEmailAddresses = [...formData.emailAddresses];
            updatedEmailAddresses[index].email = newValue; // 新しいメールアドレスで更新
            setFormData({ ...formData, emailAddresses: updatedEmailAddresses });
        } else {
            // その他のフィールドの更新
            setFormData({ ...formData, [fieldName]: newValue });
        }
    };

    // 親コンポーネントでの onChange ハンドラの定義
    const handleAutocompleteChange = (fieldName, newValue) => {
        // newValue から value の配列を作成
        const values = newValue.map(item => item.value);
        handleInputChange(fieldName, values);
    };
    // 言語の変更を処理する関数
    const handleLanguageChange = (index, field, value) => {
        const newLanguages = [...formData.languages];
        newLanguages[index][field] = value;
        setFormData({ ...formData, languages: newLanguages });
    };

    // 言語を追加する関数
    const addLanguageField = () => {
        setFormData(prevFormData => ({
            ...prevFormData,
            languages: [...(prevFormData.languages || []), { lang: '', level: '' }]
        }));
    };

    const checkLanguagesChanged = (initialLanguages, currentLanguages) => {
        // initialLanguages または currentLanguages が null の場合に空の配列を使用
        initialLanguages = initialLanguages || [];
        currentLanguages = currentLanguages || [];

        if (initialLanguages.length !== currentLanguages.length) {
            return true; // 配列の長さが異なる場合、変更があったとみなす
        }
        for (let i = 0; i < initialLanguages.length; i++) {
            if (initialLanguages[i].lang !== currentLanguages[i].lang || initialLanguages[i].level !== currentLanguages[i].level) {
                return true; // 言語またはレベルのいずれかが異なる場合、変更があったとみなす
            }
        }
        return false; // どの要素も変更されていない場合
    };


    // 変更があるかどうかをチェックする関数
    const hasChanges = () => {
        // 言語以外の部分の変更をチェック
        const nonLanguageChanges = JSON.stringify({ ...formData, languages: [] }) !== JSON.stringify({ ...initialFormData, languages: [] });
        // 言語の部分の変更をチェック
        const languageChanges = checkLanguagesChanged(initialFormData.languages, formData.languages);
        return nonLanguageChanges || languageChanges; // どちらか一方がtrueなら変更があったとみなす
    };

    // 送信に関する関数
    const cleanFormData = (data) => {
        const cleanedData = {};
        Object.keys(data).forEach(key => {
            if (data[key] !== undefined) { // undefined でない値のみを新しいオブジェクトに追加
                cleanedData[key] = data[key];
            }
        });
        return cleanedData;
    };

    const saveProfileToFirestore = async (documentId, formData) => {
        const docRef = doc(firestore, 'users', documentId);
        try {
            await setDoc(docRef, formData, { merge: true }); // データをマージして保存
            showSnackbar({ message: 'プロファイル情報を更新しました', type: 'success' });
        } catch (error) {
            showSnackbar({ message: 'プロファイル情報の更新に失敗しました', type: 'error' });
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // デフォルトのフォーム送信を防ぐ

        if (!user) return; // ユーザー情報がない場合は何もしない
        const cleanedFormData = cleanFormData(formData); // formData から undefined を除外
        await saveProfileToFirestore(user.uid, cleanedFormData); // Firestoreに保存
    };

    return (
        <>
            {!user?.isCampaignParticipant && (
                <Fab variant="extended" aria-label="add" sx={{
                    position: 'fixed',
                    right: theme.spacing(0),
                    top: theme.spacing(10),
                    borderRadius: '8px 0px 0px 8px',
                    width: '200px',
                    height: '100px',
                    zIndex: 10
                }}
                    color="primary"
                    onClick={() => setEventDialogOpen(true)}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', textTransform: 'none' }}>条件を達成して<br />giftee Boxをもらおう</Typography>
                </Fab >
            )}
            <GifteeCampaignDialog open={eventDialogOpen} onClose={() => setEventDialogOpen(false)} />
            <NavigationBar />
            <ProfileTopNavigationContent user={displayUser} />
            <ResponsiveContainer>
                <Box sx={{}}>
                    {/* {isUser && user?.status === 'highSchool' && (
                        user?.parentalConsent ? (
                            <Box sx={{ mb: 10 }}>
                                <Typography variant="h5" sx={{ fontWeight: 'bold', flexShrink: 0, mb: 2, display: 'flex', alignItems: 'center', }}>
                                    <VerifiedIcon fontSize='large' color='primary' sx={{ mr: 1 }} />保護者の許諾認証
                                </Typography>
                                <Accordion expanded={expanded} onChange={handleChange()} sx={{ mt: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        認証は完了済みです
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ParentalConsentStepper />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        ) : (
                            <Box sx={{ mb: 10 }}>
                                <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 3 }}>保護者の許諾認証</Typography>
                                <ParentalConsentStepper />
                            </Box>
                        )
                    )} */}
                    {/* <ReferralModal /> */}

                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>今後の予定</Typography>
                    {/* 以下の部分で，興味のある分野を選択できるようにしてください．また，それぞれの選択肢もいくつか生成してください． 
                    進学と勉強2つの分野においてグループを作成してください*/}
                    {isUser ? (
                        <LabelledInput
                            label="今後の予定"
                            title={"今後の予定"}
                            value={formData.aspirations}
                            onChange={(newValue) => handleInputChange('aspirations', newValue)}
                            options={ASPIRATIONS_OPTIONS}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                今後の予定
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(ASPIRATIONS_OPTIONS, displayUser?.aspirations)}
                            </Typography>
                        </Box>
                    )}

                    {/* 働きたい分野 */}
                    {isUser ? (
                        <LabelledInput
                            label="働きたい分野"
                            title="働きたい分野"
                            value={formData?.interestsEmploymentTags?.map(tag =>
                                INTERESTING_WORK_CATEGORY_OPTIONS.find(option => option.value === tag) || tag
                            )}
                            onChange={(newValue) => handleAutocompleteChange('interestsEmploymentTags', newValue)}
                            options={{
                                autocomplete: INTERESTING_WORK_CATEGORY_OPTIONS,
                                formLabel: '働きたい分野'
                            }}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                働きたい分野
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {displayUser?.interestsEmploymentTags?.map((label, index) => (
                                        <Chip color="primary" key={index} label={getLabelByValue(INTERESTING_WORK_CATEGORY_OPTIONS, label)} />
                                    ))}
                                </Stack>
                            </Typography>
                        </Box>
                    )}

                    {/*　コメ学びたい分野 */}
                    {isUser ? (
                        <LabelledInput
                            label={isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            title={isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            value={formData?.interestsEducationTags?.map(tag =>
                                INTERESTING_LEARN_CATEGORY_OPTIONS.find(option => option.value === tag) || tag
                            )}
                            onChange={(newValue) => handleAutocompleteChange('interestsEducationTags', newValue)}
                            options={{
                                autocomplete: INTERESTING_LEARN_CATEGORY_OPTIONS,
                                formLabel: isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"
                            }}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {isUser && user.status !== 'highSchoolStudent' ? "仕事を通して学びたい分野" : "働きたい分野・学びたい分野"}
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                <Stack direction="row" spacing={1}>
                                    {displayUser?.interestsEducationTags?.map((label, index) => (
                                        <Chip color="primary" key={index} label={getLabelByValue(INTERESTING_LEARN_CATEGORY_OPTIONS, label)} />
                                    ))}
                                </Stack>
                            </Typography>
                        </Box>
                    )}

                    {/* メールの通知設定 */}
                    {isUser ? (
                        <LabelledInput
                            label="メール通知設定"
                            title={"メール通知設定"}
                            value={formData.emailNotification}
                            onChange={(newValue) => handleInputChange('emailNotification', newValue)}
                            options={EMAIL_NOTIFICATION_OPTIONS}
                        />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                メール通知設定
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(EMAIL_NOTIFICATION_OPTIONS, displayUser?.emailNotification)}
                            </Typography>
                        </Box>
                    )}
                </Box>

                <Box sx={{ mt: 10 }}>
                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>基本情報</Typography>

                    {/* 氏名 */}
                    {isUser && (
                        <>
                            <Box sx={{ gap: 4 }}>
                                <LabelledInput
                                    label="苗字"
                                    value={formData.lastName}
                                    onChange={(newValue) => handleInputChange('lastName', newValue)}
                                    name="lastName"
                                    title={"氏名"}
                                />
                                <LabelledInput
                                    label="名前"
                                    value={formData.firstName}
                                    onChange={(newValue) => handleInputChange('firstName', newValue)}
                                    name="fistName"
                                />
                            </Box>
                        </>
                    )}

                    {/* 性別 */}
                    {isUser ? (
                        <LabelledInput label="性別" value={formData.gender} title={"性別"} onChange={(newValue) => handleInputChange('gender', newValue)} options={GENDER_OPTIONS} />
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <Typography variant="body1" component="label" color="text.secondary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                性別
                            </Typography>
                            <Typography variant="body1" component="label" color="text.primary" sx={{
                                fontWeight: 'bold',
                                width: { md: 'auto' },
                                minWidth: { md: '160px' },
                                marginBottom: { xs: 0, md: 0 },
                            }}>
                                {getLabelByValue(GENDER_OPTIONS, displayUser?.gender)}
                            </Typography>
                        </Box>
                    )}

                    {/* 誕生日って変更しないよね */}
                    {/* <BirthdateSelects onChange={handleBirthdateChange} /> onChangeプロパティを渡す */}

                    {/* 高校名 */}
                    {isUser && user?.userType === 'highSchool' ? (
                        <LabelledInput
                            label="高校名"
                            title={"高校名"}
                            value={formData.schoolName}
                            onChange={(newValue) => handleInputChange('schoolName', newValue)}
                            options={SCHOOL_OPTIONS}
                            disabled={false}
                        />
                    ) : (
                        displayUser?.userType === 'highSchool' && (
                            <Box sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: { md: 'center' },
                                display: 'flex',
                                gap: 0.5,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    高校名
                                </Typography>
                                <Typography variant="body1" component="label" color="text.primary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    {getLabelByValue(SCHOOL_OPTIONS, displayUser?.schoolName)}
                                </Typography>
                            </Box>
                        )
                    )}

                    {/* 学年 */}
                    {isUser && user?.userType === 'highSchool' ? (
                        <LabelledInput
                            label="学年"
                            title={"学年"}
                            value={formData.grade}
                            onChange={(newValue) => handleInputChange('grade', newValue)}
                            options={GRADE_OPTIONS}
                            disabled={false}
                        />
                    ) : (
                        displayUser?.userType === 'highSchool' && (
                            <Box sx={{
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: { md: 'center' },
                                display: 'flex',
                                gap: 0.5,
                                marginBottom: 2,
                            }}>
                                <Typography variant="subtitle1" component="label" color="text.secondary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    学年
                                </Typography>
                                <Typography variant="body1" component="label" color="text.primary" sx={{
                                    fontWeight: 'bold',
                                    width: { md: 'auto' },
                                    minWidth: { md: '160px' },
                                    marginBottom: { xs: 0, md: 0 },
                                }}>
                                    {getLabelByValue(GRADE_OPTIONS, displayUser?.grade)}
                                </Typography>
                            </Box>
                        )
                    )}

                    {isUser && (
                        <>
                            <LabelledInput
                                label="電話番号"
                                value={formData.phoneNumber}
                                onChange={(newValue) => handleInputChange('phoneNumber', newValue)}
                                name="phoneNumber"
                                title={"電話番号"}
                            />
                            <LabelledInput
                                label={!formData.emailAddresses[0].email ? "メールアドレスが登録されていません" : "メールアドレス（ログイン用）"}
                                value={formData.emailAddresses[0].email || "メールアドレスが登録されていません"}
                                type="email"
                                name="email"
                                title="メールアドレス"
                                disabled={true}
                            />
                            <LabelledInput
                                label="メールアドレス2（サブ）"
                                value={formData.emailAddresses[1]?.email || ""}
                                onChange={(newValue) => handleInputChange('emailAddresses', newValue, 1)}
                                type="email"
                                name="email2"
                                isTitle={true}
                            />
                            <LabelledInput
                                label="メールアドレス3（サブ）"
                                value={formData.emailAddresses[2]?.email || ""}
                                onChange={(newValue) => handleInputChange('emailAddresses', newValue, 2)}
                                // onChange={(newValue) => handleInputChange('email3', newValue)}
                                type="email"
                                name="email3"
                                isTitle={true}
                            />


                            {/* userでプロバイダーがpasswordの時はこっち，LINEの時はemail/passwordを追加できるダイアログを追加する */}
                            {auth && auth?.currentUser?.providerData && auth?.currentUser?.providerData.length > 0 && auth?.currentUser?.providerData[0].providerId === 'oidc.line' && auth?.currentUser.email ? (
                                <Box sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'flex-end',
                                    alignItems: { md: 'center' },
                                    display: 'flex',
                                    gap: 0.5,
                                    marginBottom: 4,
                                }}>
                                    <Box variant="subtitle1" component="label" color="text.secondary" sx={{
                                        width: { md: 'auto' },
                                        minWidth: { md: '160px' },
                                        marginBottom: { xs: 0, md: 0 },
                                    }}>
                                    </Box>
                                    <Box sx={{
                                        flexDirection: { xs: 'row', md: 'row' },
                                        alignItems: { md: 'center' },
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}>
                                        <Button variant="outlined" onClick={() => toggleModal('updatePassword', true)}>
                                            パスワードを更新
                                        </Button>
                                        {/* メール更新モーダルを開くボタン */}
                                        <Button variant="outlined" onClick={() => toggleModal('updateEmail', true)}>
                                            メインアドレスを更新
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: 'flex-end',
                                    alignItems: { md: 'center' },
                                    display: 'flex',
                                    gap: 0.5,
                                    marginBottom: 4,
                                }}>
                                    <Box variant="subtitle1" component="label" color="text.secondary" sx={{
                                        width: { md: 'auto' },
                                        minWidth: { md: '160px' },
                                        marginBottom: { xs: 0, md: 0 },
                                    }}>
                                    </Box>
                                    <Box sx={{
                                        flexDirection: { xs: 'row', md: 'row' },
                                        alignItems: { md: 'center' },
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}>
                                        {/* メール追加モーダルを開くボタン */}
                                        <Button variant="outlined" onClick={() => toggleModal('addEmail', true)}>
                                            アドレス認証を追加
                                        </Button>
                                    </Box>
                                </Box>
                            )}


                            {/* 住所に関するフォーム */}
                            <LabelledInput label="郵便番号" value={formData.postalCode} onChange={(newValue) => handleInputChange('postalCode', newValue)} type="" name="郵便番号" title="住所" isTitle={true} />
                            <LabelledInput label="都道府県" value={formData.prefecture} onChange={(newValue) => handleInputChange('prefecture', newValue)} type="" name="都道府県" isTitle={true} />
                            <LabelledInput label="市町村" value={formData.city} onChange={(newValue) => handleInputChange('city', newValue)} type="" name="市町村" isTitle={true} />
                            <LabelledInput label="番地以降" value={formData.streetAddress} onChange={(newValue) => handleInputChange('streetAddress', newValue)} type="" name="番地以降" isTitle={true} />
                        </>
                    )}
                </Box>

                <Box sx={{ mt: 10 }}>
                    <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold', mb: 4 }}>言語</Typography>
                    {isUser ? (
                        formData?.languages?.map((language, index) => (
                            <LanguageSelect
                                key={index}
                                language={language}
                                onLanguageChange={(field, value) => handleLanguageChange(index, field, value)}
                            />
                        ))
                    ) : (
                        <Box sx={{
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { md: 'center' },
                            display: 'flex',
                            gap: 0.5,
                            marginBottom: 2,
                        }}>
                            <TableContainer component={Paper}>
                                <Table sx={{ maxWidth: 'md' }} aria-label="languages table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>言語</TableCell>
                                            <TableCell align="right">レベル</TableCell>
                                            {/* 必要に応じて他のヘッダーセルを追加 */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayUser?.languages?.map((language, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {language.lang}
                                                </TableCell>
                                                <TableCell align="right">{language.level}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                    {isUser && (
                        <Button onClick={addLanguageField} variant="contained">言語を追加</Button>
                    )}
                </Box>

                {isUser && (
                    <Box sx={{
                        position: 'fixed',
                        right: rightMargin,
                        bottom: theme.spacing(3), // 20px
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 4
                    }}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={!hasChanges()}
                        >
                            保存
                        </Button>
                    </Box>
                )}

                {/* パスワード更新モーダル */}
                <UpdatePasswordDialog
                    open={dialogsOpen.updatePassword}
                    handleClose={() => toggleModal('updatePassword', false)}
                />

                {/* メールアドレス更新モーダル */}
                <UpdateEmailDialog
                    open={dialogsOpen.updateEmail}
                    handleClose={() => toggleModal('updateEmail', false)}
                />

                {/* メールアドレス追加モーダル */}
                <AddEmailPasswordDialog
                    open={dialogsOpen.addEmail}
                    handleClose={() => toggleModal('addEmail', false)}
                />


            </ResponsiveContainer>
            <Footer />
        </>
    );
}

export default ProfileEdit;
