import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow, Collapse, IconButton, Box, Typography, Button, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { firestore } from '../../../../firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';

import { useSnackbar } from '../../../../Context/SnackbarContext';
import RestoreIcon from '@mui/icons-material/Restore';

const Row = ({ row }) => {
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    // 日付をフォーマットする関数
    const formatDate = (date) => {
        return date ? new Date(date.seconds * 1000).toISOString().split('T')[0] : 'N/A';
    };

    const handleRestore = (id) => {
        // Assuming you have access to the firestore instance
        const docRef = doc(firestore, 'companies', id);

        // Update the document with the isDeleted flag set to true
        updateDoc(docRef, {
            isDeleted: false,
            updatedAt: serverTimestamp()
        })
            .then(() => {
                showSnackbar({ message: "復元しました", type: 'success' });
            })
            .catch((error) => {
                showSnackbar({ message: "エラーが発生しました", type: 'error' });
            });
    };


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row?.name}</TableCell>
                <TableCell>{row?.websiteUrl}</TableCell>
                <TableCell align="right">{formatDate(row?.createdAt)}</TableCell>
                <TableCell align="right">{formatDate(row?.updatedAt)}</TableCell>
                <TableCell align="center">
                    <IconButton color="primary" onClick={() => handleRestore(row.id)}>
                        <RestoreIcon />
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">詳細情報</Typography>
                            <Table size="small" aria-label="details">
                                <TableBody>
                                    {/* ここに企業の詳細情報を追加 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">代表者名</TableCell>
                                        <TableCell align="right">{row?.leaderName || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">事業概要</TableCell>
                                        <TableCell align="right">{row?.bussinessOutline || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">追加状況</TableCell>
                                        <TableCell align="right">{row?.status || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 他のフィールドも同様に追加 */}
                                </TableBody>
                            </Table>
                            <Stack direction="row" spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                <Button variant="contained" color="primary" onClick={() => handleRestore(row.id)}>
                                        <RestoreIcon />
                                    データ復元
                                </Button>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;