import React from 'react';
import { ListItem, ListItemIcon, Avatar, ListItemText, Typography, Chip } from '@mui/material';

import { grey } from '@mui/material/colors';

const CustomListItem = ({ avatarSrc, title, tag, lastMessage, isActive, onClick }) => {
    const getChipColor = (tag) => {
        switch (tag) {
            case "AI担当者": return "primary";
            case "企業担当者": return "secondary";
            case "スカウト": return "warning";
            case "その他": return "default"; // このケースではsxを使用して色をカスタマイズ
            default: return "default";
        }
    };

    // "その他"のタグの背景色を設定
    const customTagStyle = tag === "その他" ? { bgcolor: grey[500], color: '#fff' } : {};


    return (
        <ListItem
            onClick={onClick}
            button
            sx={{
                bgcolor: isActive ? 'grey.300' : 'background.paper', // アクティブならsecondary.main、そうでなければ背景色をpaper色に
                '&:hover': {
                    bgcolor: isActive ? 'grey.200' : 'grey.100', // ホバー時の背景色も同様に設定
                },
                transition: 'background-color 0.3s ease', // 背景色の変更にトランジションを適用
                borderRadius: '4px', // 角丸の設定
                marginY: '2px', // 上下のマージンを少し追加
            }}
        >
            <ListItemIcon>
                <Avatar alt="Profile Picture" src={avatarSrc} sx={{ width: 56, height: 56 }} />
            </ListItemIcon>
            <ListItemText sx={{ ml: 2 }}>
                {/* タイトル */}
                <Typography variant="subtitle1" component="div" sx={{
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    {title}
                </Typography>
                {/* タグ */}
                <Chip
                    label={tag}
                    size="small"
                    color={getChipColor(tag)}
                    sx={customTagStyle}
                />
                {/* 最終メッセージ */}
                <Typography variant="subtitle2" color="text.secondary" sx={{
                    mt: 1,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    {lastMessage}
                </Typography>
            </ListItemText>
        </ListItem>
    );
};

export default CustomListItem;
