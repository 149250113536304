// React imports
import React, { useState } from 'react';

// MUI components
import {
    Box,
    Button,
    Chip,
    Collapse,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TablePagination,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Firebase imports
import {
    query,
    collection,
    where,
    getDocs,
    addDoc,
    doc,
    serverTimestamp,
    setDoc,
    arrayUnion,
    getDoc
} from 'firebase/firestore';
import { firestore } from '../../../firebase';

// Custom hooks and utilities
import { getLabelByValue } from '../../../utils/helpers/getLabelByValue';
import {
    GENDER_OPTIONS,
    ASPIRATIONS_OPTIONS,
    EDUCATION_OPTIONS,
    INTERESTING_WORK_CATEGORY_OPTIONS,
    INTERESTING_LEARN_CATEGORY_OPTIONS
} from '../../../utils/options';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { useUser } from '../../../Context/UserContext';

const renderEducationTags = (tags) => {
    return (
        <div>
            {(tags || []).map((tag, index) => (
                <Chip
                    key={index}
                    label={getLabelByValue(INTERESTING_LEARN_CATEGORY_OPTIONS, tag)}
                    color="primary"
                    size="small"
                    fontWeight="bold"
                    style={{ marginRight: 5 }}
                />
            ))}
        </div>
    );
};

const renderEmploymentTags = (tags) => {
    return (
        <div>
            {/* 配列の存在確認 */}
            {(tags || []).map((tag, index) => (
                <Chip
                    key={index}
                    label={getLabelByValue(INTERESTING_WORK_CATEGORY_OPTIONS, tag)}
                    color="secondary"
                    size="small"
                    style={{ marginRight: 5 }}
                />
            ))}
        </div>
    );
};

const CollapsibleRow = ({ user, renderEducationTags, renderEmploymentTags, startOrJoinChat, companyId, currentUser, setUsers, planName }) => {
    const [open, setOpen] = useState(false);

    // トグルボタンがクリックされたときの処理
    const handleToggleClick = (e) => {
        e.stopPropagation(); // Stops the click event from bubbling to the link
        setOpen(!open);
    };

    // 行がクリックされたときの処理
    const handleRowClick = () => {
        window.open(`/profile/${user.id?.raw || user.id}`, '_blank');
    };
    // 行がクリックされたときの処理
    const handleExistingScoutClick = () => {
        window.open(`/chat/${user.id?.raw || user.id}`, '_blank');
    };

    // スカウトボタンがクリックされたときの処理
    const handleScoutClick = async (e) => {
        e.stopPropagation(); // Stops the click event from bubbling to the row click
        await startOrJoinChat('スカウト', currentUser, user, companyId); // Assuming 'ScoutChat' is the tag for this type of chat
        // Update the user's scouted status
        setUsers(prevUsers => prevUsers.map(u => {
            if (u.id?.raw === user.id?.raw) {
                return { ...u, isScouted: true };
            }
            return u;
        }));
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover onClick={handleRowClick} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                <TableCell onClick={handleToggleClick}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{getLabelByValue(EDUCATION_OPTIONS, user?.usertype?.raw)}</TableCell>
                <TableCell align="left">{user.lastname?.raw || user.lastname} {user.firstname?.raw || user.firstname}</TableCell>
                <TableCell align="center">{getLabelByValue(ASPIRATIONS_OPTIONS, user?.aspirations?.raw)}</TableCell>
                <TableCell align="center">{user?.awards?.raw.length || 0}</TableCell>
                <TableCell align="center">{user?.certifications?.raw.length || 0}</TableCell>
                <TableCell align="center">{getLabelByValue(GENDER_OPTIONS, user?.gender?.raw)}</TableCell>
                <TableCell align="center">{user?.age?.raw || 'N/A'}</TableCell>
                <TableCell align="center">
                    {user.isScouted ? (
                        <Button variant="outlined" onClick={handleExistingScoutClick} sx={{ width: '6rem' }} disabled={planName !== 'スタンダードプラン'}>
                            Chatへ
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleScoutClick} sx={{ width: '6rem' }} disabled={planName !== 'スタンダードプラン'}>
                            スカウト
                        </Button>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                詳細情報
                            </Typography>
                            <Table size="small" aria-label="details">
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">キャッチフレーズ</TableCell>
                                        <TableCell align="left">{user.catchphrase?.raw || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">プロフィール</TableCell>
                                        <TableCell align="left">{user.profile?.raw || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">目標</TableCell>
                                        <TableCell align="left">{user.goals?.raw || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">保護者の利用同意</TableCell>
                                        <TableCell align="left">{user.parentalconsent?.raw ? '○' : '✖︎'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">学びたい分野</TableCell>
                                        <TableCell align="left">{renderEducationTags(user.interestseducationtags?.raw || [])}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">働きたい分野</TableCell>
                                        <TableCell align="left">{renderEmploymentTags(user.interestsemploymenttags?.raw || [])}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">学年</TableCell>
                                        <TableCell align="left">{getLabelByValue(GENDER_OPTIONS, user.grade?.raw)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">最終ログイン時間</TableCell>
                                        <TableCell align="left">{new Date(user.updatedat?.raw || '').toLocaleString()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const DataTable = ({ users, page, totalUsers, onChangePage, itemsPerPage, onChangeRowsPerPage, companyId, setUsers, planName }) => {
    const { user: currentUser } = useUser();
    const handleChangePage = (event, newPage) => {
        onChangePage(newPage + 1);  // plus one because page index starts at 1
    };

    const handleChangeRowsPerPage = (event) => {
        onChangeRowsPerPage(parseInt(event.target.value, 10));
        onChangePage(1);  // Reset to first page with new rows per page
    };

    const { showSnackbar } = useSnackbar();

    // チャットドキュメントを追加する関数
    const startOrJoinChat = async (chatTag, user, student, companyId) => {
        if (!user) return; // ユーザーがいない場合は何もしない
        // スカウト済みのユーザーかどうかを確認する
        const isScouted = student.isScouted;
        if (isScouted) {
            showSnackbar({ message: "このユーザーはすでにスカウト済みです", type: 'info' });
            return;
        }

        const q = query(collection(firestore, 'chats'),
            where('participantIds', 'array-contains', student.id?.raw || student.id),
            where('companyId', '==', companyId),
            where('chatTag', '==', chatTag),
            where('archived', '==', false) // 既に終了していないチャットのみを対象にする
        );

        const querySnapshot = await getDocs(q);

        // 既存のチャットが見つかった場合、そのチャットに移動
        if (!querySnapshot.empty) {
            // 最初のドキュメントにアクセス
            showSnackbar({ message: "既存のチャットが存在します", type: 'success' });
            return;
        } else {
            // 企業コレクションからドキュメントを取得するコード
            const company = await getDoc(doc(firestore, 'companies', companyId));
            // 既存のチャットがない場合、新しいチャットを作成
            const chatData = {
                participantIds: [user.uid, student.id?.raw || student.id], // ユーザー検索用のフィールド
                participants: [
                    { "userId": user.uid, "userType": user.userType },
                    { "userId": student.id?.raw || student.id, "userType": student.usertype?.raw }
                ],
                companyId: companyId, // 企業のIDを保存
                companyRef: doc(firestore, `companies/${companyId}`), // 企業ドキュメントへの参照
                avatarSrc: company?.iconUrl ?? null, // チャットのアバター画像
                companyDisplaySrc: student?.usericonurl?.raw ? false : true, // 企業のアバター画像を表示するかどうか
                chatTag: chatTag,
                lastMessage: '',
                lastMessageTimestamp: serverTimestamp(),
                archived: false,
                startedBy: { "userId": user.uid, "userType": user.userType },
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp()
            };

            // // 新しいチャットドキュメントを追加し、追加されたドキュメントの参照を取得
            // const docRef = 
            await addDoc(collection(firestore, 'chats'), chatData);
            // スカウト済みのユーザーとして記録
            const metaRef = doc(firestore, 'companies', companyId, 'meta', 'scoutedUsers');
            await setDoc(metaRef, { scoutedUsers: arrayUnion(student.id?.raw || student.id) }, { merge: true });
            showSnackbar({ message: "チャットへスカウトを追加しました", type: 'success' });
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" />
                        <TableCell align="left">ユーザータイプ</TableCell>
                        <TableCell align="left">氏名</TableCell>
                        <TableCell align="center">進路志望</TableCell>
                        <TableCell align="center">受賞数</TableCell>
                        <TableCell align="center">資格数</TableCell>
                        <TableCell align="center">性別</TableCell>
                        <TableCell align="center">年齢</TableCell>
                        <TableCell align="center"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => (
                        <CollapsibleRow
                            key={index}
                            user={user}
                            renderEducationTags={renderEducationTags}
                            renderEmploymentTags={renderEmploymentTags}
                            startOrJoinChat={startOrJoinChat}
                            companyId={companyId}
                            currentUser={currentUser}
                            setUsers={setUsers}
                            planName={planName}
                        />
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={totalUsers}
                page={page - 1}  // minus one because page index starts at 0
                onPageChange={handleChangePage}
                rowsPerPage={itemsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10]}  // Add more options as needed
            />
        </TableContainer>
    );
};

export default DataTable;
