import React, { useEffect, useState } from "react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { Alert, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography, Link } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { firestore } from "../../firebase";
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';
import GifteeImage from '../../assets/images/campaign.png';
import { Player } from '@lottiefiles/react-lottie-player';

const CampaignComponent = () => {
    const { user } = useUser();
    const { showSnackbar } = useSnackbar();
    const [hasReceivedIceCream, setHasReceivedIceCream] = useState(false);
    const [open, setOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [showCongrats, setShowCongrats] = useState(false);
    const [animation, setAnimation] = useState('/cracker.json');
    const [hasClosedIceCampaignAlert, setHasClosedIceCampaignAlert] = useState(false);

    useEffect(() => {
        const checkUserParticipation = async () => {
            if (user) {
                const userRef = doc(firestore, "users", user.uid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const data = userDoc.data();
                    if (data.isFirstIceCampaignParticipant) {
                        setHasReceivedIceCream(true);
                    }
                    if (data.hasClosedIceCampaignAlert) {
                        setHasClosedIceCampaignAlert(true);
                    }
                }
            }
        };

        checkUserParticipation();
    }, [user]);

    const onSubmit = async () => {
        try {
            const userRef = doc(firestore, "users", user?.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists() && userDoc.data().isFirstIceCampaignParticipant) {
                showSnackbar({ message: '既にアイスクリームを受け取っています', type: 'info', title: '情報' });
                return;
            }

            // Firestoreにユーザー情報を保存
            await updateDoc(userRef, {
                isFirstIceCampaignParticipant: true,
            });
            setOpen(false); // Close the dialog after updating
            setHasReceivedIceCream(true);
            setConfirmOpen(false); // Close the confirmation modal after updating
            setShowCongrats(true); // Show the congratulations animation
            setTimeout(() => {
                setShowCongrats(false);
            }, 5000); // Hide the animation after 5 seconds
        } catch (error) {
            const errorMessage = getFirebaseErrorMessage({
                defaultErrorMessage: 'ゲットに失敗しました',
                errorCode: error.code
            });
            showSnackbar({ message: errorMessage, type: 'error', title: '失敗' });
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleAlertClose = async () => {
        try {
            const userRef = doc(firestore, "users", user?.uid);
            await updateDoc(userRef, {
                hasClosedIceCampaignAlert: true,
            });
            setHasClosedIceCampaignAlert(true);
        } catch (error) {
            const errorMessage = getFirebaseErrorMessage({
                defaultErrorMessage: '更新に失敗しました',
                errorCode: error.code
            });
            showSnackbar({ message: errorMessage, type: 'error', title: '失敗' });
        }
    };

    return (
        <div>
            {user && !hasClosedIceCampaignAlert && (
                <Alert
                    severity={hasReceivedIceCream ? "success" : "info"}
                    sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    action={
                        hasReceivedIceCream ? (
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleAlertClose}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        ) : (
                            <Button color="inherit" size="small" onClick={handleClickOpen}>
                                ゲットする
                            </Button>
                        )
                    }
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', textTransform: 'none' }}>
                        {hasReceivedIceCream ? "楽しんで" : "アイスでひと休み"}
                    </Typography>
                </Alert>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography component="h3" variant="h5" align="center">
                            スタッフにこの画面を見せてください
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Box
                            component="img"
                            src={GifteeImage}
                            alt="giftee Box"
                            sx={{
                                width: { xs: '100%', sm: '90%' },
                                maxWidth: '600px',
                                borderRadius: '16px',
                            }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Link href="https://www.cielinc.co.jp/contents/zvryl8nx1q" target="_blank" rel="noopener" underline="hover">
                            配布場所の詳細はこちら
                        </Link>
                    </Box>
                    <Container component="main" sx={{ my: { xs: 1, sm: 2 } }}>
                        <Button
                            variant="contained"
                            onClick={handleConfirmOpen}
                            fullWidth
                            sx={{ mt: 2, borderRadius: '50px', py: 2, fontSize: { xs: '1rem', sm: '1.25rem' }, fontWeight: 'bold' }}
                            disabled={!user}
                        >
                            受け取る
                        </Button>
                    </Container>
                </DialogContent>
            </Dialog>

            <Dialog open={confirmOpen} onClose={handleConfirmClose}>
                <DialogTitle>確認</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ハーゲンダッツを受け取る!この画面は2度と表示されてしません。よろしいですか？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={onSubmit} color="primary" variant="contained">
                        受け取る
                    </Button>
                </DialogActions>
            </Dialog>

            {showCongrats && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1300
                }}>
                    <Player
                        autoplay
                        src={animation}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 0
                        }}
                        onEvent={(event) => {
                            if (event === 'complete') {
                                setAnimation('/confetti.json');
                            }
                        }}
                    />
                    <Typography variant="h3" sx={{ color: 'white', position: 'absolute' }}>
                        楽しんで！！
                    </Typography>
                </Box>
            )}
        </div>
    );
};

export default CampaignComponent;
