// React and hooks
import React, { useContext, useState, useEffect, useCallback } from 'react';
import Joyride from 'react-joyride';

// Material-UI components
import {
    Button, Divider, Typography, Grid, Container, Pagination, Box, CircularProgress,
    Fab
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// firestore
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

// Local components
import ResponsiveContainer from '../components/Layout/ResponsiveContainer';
import CompanyCard from '../components/Company/DataCard';
import SearchBar from '../components/SearchBar';
import NavigationBar from '../components/Layout/NavigationBar';
import Footer from '../components/Layout/Footer';
import GifteeCampaignDialog from '../components/Input/GifteeCampaignDialog';
import CampaignComponent from '../components/Input/CampaignComponent';

// Contexts
import { useUser } from '../Context/UserContext';
import { TourContext, handleCallback } from '../Context/TourContext';



// import { useSnackbar } from '../Context/SnackbarContext';
// import { firestore } from '../firebase';
// import { collection, getDocs, query, limit } from 'firebase/firestore';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function fetchElasticAPI(setLoading, features, user, searchParams, itemsPerPage, page, setCompanies, setTotalCompanies, setTotalPages) {
    return async () => {
        setLoading(true);
        const endpoint = "https://elasticsearch-frnrslgk3q-an.a.run.app";

        // フィルタ条件の設定
        let filters = [{ "isdeleted": "false" }];
        if (features.find(f => f.name === "AIの解答が正確" && f.enabled)) {
            filters.push({ "numchunks": { "from": 100 } });
        }
        if (features.find(f => f.name === "担当者と話せる" && f.enabled)) {
            filters.push({ "allowsdirectchat": "true" });
        }
        // ブックマーク済みの条件を追加
        if (user && features.find(f => f.name === "ブックマーク済み" && f.enabled)) {
            if (user.companyBookMarks && user.companyBookMarks.length > 0) {
                // user.companyBookMarks が存在し、かつ空でない場合のみフィルタを適用
                filters.push({ "id": user.companyBookMarks });
            } else {
                // ブックマークがない場合は検索結果が0になるようにする
                filters.push({ "id": "none" });
            }
        }

        const sort = [
            { "viewcount": "desc" }
        ];

        const requestData = {
            "query": searchParams.searchText,
            "filters": { "all": filters },
            "sort": sort,
            "page": { "size": itemsPerPage, "current": page }
        };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setCompanies(data.results);
            setTotalCompanies(data.meta.page.total_results);
            setTotalPages(data.meta.page.total_pages);

        } catch (error) {
            console.error("There was an error fetching the companies:", error);
        } finally {
            setLoading(false);
        }
    };
}

const handleSkipTutorial = async (userId) => {
    try {
        const userDocRef = doc(firestore, "users", userId);
        await setDoc(userDocRef, { tutorialStatus: 'skipped' }, { merge: true });
    } catch (error) {
        console.error('エラーが発生しました:', error);
    }
}

const CompanyList = () => {
    // ページネーションの状態
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchParams, setSearchParams] = useState({
        searchText: '',
        jobType: '',
        category: '',
        // 特徴に関するその他の状態があればここに追加
    });
    const [features, setFeatures] = useState([
        { name: "AIの解答が正確", enabled: false },
        { name: "担当者と話せる", enabled: false },
        { name: "ブックマーク済み", enabled: false },
    ]);
    const [totalCompanies, setTotalCompanies] = useState(0);
    const [loading, setLoading] = useState(false); // ローディング状態
    const [eventDialogOpen, setEventDialogOpen] = useState(false);
    // const { showSnackbar } = useSnackbar();
    const { user } = useUser();
    const theme = useTheme();
    const itemsPerPage = 10; // 1ページあたりのアイテム数

    const { steps, setSteps, run, setRun } = useContext(TourContext);

    // joyrideのステップを設定
    useEffect(() => {
        setSteps([
            {
                content:
                    (
                        <>
                            <h3>Cielへようこそ！<br />チュートリアルをはじめよう</h3>
                            <Button
                                variant=""
                                color="secondary"
                                onClick={() => {
                                    // チュートリアルをスキップする処理をここに書く
                                    handleSkipTutorial(user.uid)
                                }}
                                style={{ marginTop: '20px' }}
                            >
                                スキップ
                            </Button>
                        </>
                    ),
                locale: { skip: <strong aria-label="skip">Ciel Inc.</strong> },
                placement: 'center',
                target: 'body',
            }
        ]);
        if (user) {
            if (user?.tutorialStatus === 'completed' || user?.tutorialStatus === 'skipped') {
                setRun(false);
            } else {
                setRun(true);
            }
        } else {
            setRun(true);
        }
    }, [user, setSteps, setRun]);

    const fetchCompanies = fetchElasticAPI(setLoading, features, user, searchParams, itemsPerPage, page, setCompanies, setTotalCompanies, setTotalPages);

    useEffect(() => {
        fetchCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // 検索ボタンがクリックされた時に呼び出されるhandleSearchを更新
    const handleSearch = useCallback(() => {
        setPage(1);
        fetchCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, features]);

    const handlePageChange = (_, value) => {
        setPage(value);
        window.scrollTo(0, 0); // ページのトップにスクロールする
    };

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setSearchParams(prev => ({ ...prev, [name]: value }));
    }, []);

    const toggleFeature = useCallback((featureName) => {
        setFeatures(currentFeatures =>
            currentFeatures.map(feature =>
                feature.name === featureName ? { ...feature, enabled: !feature.enabled } : feature
            )
        );
    }, []);

    return (
        <>
            {user && (
                <Joyride
                    callback={handleCallback}
                    run={run}
                    continuous={true}
                    steps={steps}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={false}
                    locale={{
                        back: '戻る',
                        close: '閉じる',
                        last: '質問する',
                        next: '次へ',
                        skip: 'スキップ'
                    }}
                    styles={{
                        options: {
                            zIndex: 1000,
                        },
                        buttonNext: {
                            backgroundColor: '#1976d2', // Material-UI primary color
                            color: 'white',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // Material-UI shadow
                        },
                        buttonBack: {
                            backgroundColor: '#eeeeee', // Material-UI grey color
                            color: '#616161',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                        },
                    }}
                />
            )}
            {!user?.isCampaignParticipant & user?.userType !== 'company' & user?.userType !== 'admin' && (
                <Fab variant="extended" aria-label="add" sx={{
                    position: 'fixed',
                    right: theme.spacing(0),
                    bottom: theme.spacing(5),
                    borderRadius: '8px 0px 0px 8px',
                    width: '200px',
                    height: '100px',
                    zIndex: 10
                }}
                    color="primary"
                    onClick={() => setEventDialogOpen(true)}
                >
                    <Typography variant="body1" sx={{ fontWeight: 'bold', textTransform: 'none' }}>条件を達成して<br />giftee Boxをもらおう</Typography>
                </Fab >
            )}
            <GifteeCampaignDialog open={eventDialogOpen} onClose={() => setEventDialogOpen(false)} onOpen={() => setEventDialogOpen(true)} />

            <NavigationBar />
            {/* <Container maxWidth={'xl'} sx={{ mt: 0, display: { xs: 'none', sm: 'block' } }}>
                <SlideShow />
            </Container> */}
            <ResponsiveContainer>
                {user && (
                    <Container sx={{
                        px: { xs: 1, sm: 2, md: 3 }, // xsサイズではパディングを1に、smサイズでは2に、mdサイズでは3にする
                    }}>
                        <CampaignComponent />
                    </Container>
                )}
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={3.5}>
                        <Container sx={{
                            px: { xs: 1, sm: 2, md: 3 }, // xsサイズではパディングを1に、smサイズでは2に、mdサイズでは3にする
                        }}>
                            <SearchBar
                                searchParams={searchParams}
                                handleInputChange={handleInputChange}
                                handleSearch={handleSearch}
                                toggleFeature={toggleFeature}
                                features={features}
                            />
                        </Container>
                    </Grid>

                    {/* CompanyList */}
                    <Grid item xs={12} md={8.5}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: 640, width: '100%' }}>
                            <Container maxWidth="lg" sx={{
                                px: { xs: 1, sm: 2, md: 3 }, // xsサイズではパディングを1に、smサイズでは2に、mdサイズでは3にする
                            }}>

                                <Box sx={{ display: "flex", justifyContent: 'space-between' }} className='home'>
                                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography className='home-button' variant="subtitle2" component="h6" color="text.secondary" sx={{ display: 'flex', alignItems: 'flex-end', pb: 1 }}>{itemsPerPage}/</Typography>
                                        <Typography variant="h5" sx={{ display: 'flex', alignItems: 'flex-end', pb: 1, fontWeight: 'bold' }}>{totalCompanies}</Typography>
                                    </Box>
                                    {/* <Box sx={{ display: "flex" }}>
                                        <Typography component="h6" variant="subtitle2" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>並び替え</Typography>
                                        <Button>おすすめ</Button>
                                        <Button>人気</Button>
                                    </Box> */}
                                </Box>

                                <Divider sx={{ mb: 4 }} />

                                <Grid container spacing={4} justifyContent="center">
                                    {loading ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                                            <CircularProgress />
                                        </Box>
                                    ) : companies.length > 0 ? (
                                        companies.map((company, index) => (
                                            <Grid item key={company?.id?.raw} xs={12}>
                                                <CompanyCard item={company} isGuide={index === 0} />
                                                {index !== companies.length - 1 && <Divider />}
                                            </Grid>
                                        ))
                                    ) : (
                                        // 検索結果が0のときに表示するメッセージ
                                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                                            <Typography sx={{ textAlign: 'center' }}>検索結果が見つかりませんでした<br />
                                                条件を変更して再度お試しください。</Typography>
                                        </Box>
                                    )}
                                </Grid>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        color="primary"
                                    />
                                </Box>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </ResponsiveContainer>
            <Footer />
        </>
    );
}

export default CompanyList;