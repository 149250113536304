// React and related hooks
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Firebase imports
import { firestore } from '../../firebase';
import { doc, getDoc, collection, query, getDocs, where, orderBy } from 'firebase/firestore';

// MUI components
import {
    Box, Button, Typography, Divider, Tooltip, Alert, Collapse,
    Table, TableBody, TableCell, TableContainer, TableRow, Paper,
    TableHead, Container, LinearProgress, Dialog, DialogActions, DialogContent
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton';

// Contexts
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';

// Components
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';

// External libraries
import axios from 'axios';

// 状態と対応する文字列のマッピング
const statusMapping = {
    file_uploaded: "アップロード済み",
    ocr_completed: "OCR完了",
    chunks_created: "チャンク作成完了",
    index_created: "インデックス作成完了",
    success: "成功"
};

const CLOUD_FUNCTION_FILE_PAGE_COUNT_API_ENDPOINT = 'https://pdf-page-counter-frnrslgk3q-an.a.run.app/';
const CLOUD_FUNCTION_CREATE_INDEX_FROM_PDF_API_ENDPOINT = 'https://create-company-index-from-pdf-frnrslgk3q-an.a.run.app/';
const CLOUD_FUNCTION_DELETE_INDEX_API_ENDPOINT = 'https://delete-company-index-frnrslgk3q-an.a.run.app/';

// 会社情報を取得する
const fetchCompanyInfo = async (companyId, setCompanyInfo) => {
    const docRef = doc(firestore, "companies", companyId);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const id = docSnap.id;
            setCompanyInfo({ id, ...docSnap.data() });
        }
    } catch (error) {
        console.error("Error getting document:", error);
        // エラーハンドリングを行う
    }
};

// アップロードされたファイルの一覧を取得する
const fetchUploadsFiles = async (companyId, setUploadFiles) => {
    const uploadsCollectionRef = collection(firestore, 'companies', companyId, 'uploads');
    const q = query(uploadsCollectionRef, where('isDeleted', '==', false), orderBy('createdAt', 'desc'));

    const querySnapshot = await getDocs(q);
    const uploads = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));
    setUploadFiles(uploads);
};

// 使用量の進捗バー
function LinearProgressWithLabel(props) {
    const { value, maxValue } = props;
    let normalizedValue = 0;

    if (maxValue > 0) {
        normalizedValue = Math.min((value / maxValue) * 100, 100);
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={normalizedValue} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {`${Math.round(normalizedValue)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

const DataRow = ({ row, companyId, setUploadFiles, isIcon }) => {

    const [isLoading, setIsLoading] = useState(false);
    const { showSnackbar } = useSnackbar();

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date.seconds * 1000);
        return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
    };

    const statusText = statusMapping[row?.status] || "未知のステータス";
    const isSuccess = row?.status === 'success';

    const handleDelete = async () => {
        setIsLoading(true);
        showSnackbar({ message: '削除処理を開始しました', type: 'error' });

        try {
            const response = await axios.post(CLOUD_FUNCTION_DELETE_INDEX_API_ENDPOINT, {
                company_id: companyId,
                doc_id: row.id,
            });
            if (response.status === 200) {
                // setUploadFilesを使って，削除したデータを画面から削除する
                setUploadFiles(prevFiles => prevFiles.filter(file => file.id !== row.id));
                showSnackbar({ message: '削除処理が完了しました', type: 'success' });
            }
        } catch (error) {
            console.error('Error during API call:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align='left'>{formatDate(row?.createdAt)}</TableCell>
                <TableCell component="th" scope="row" align='left'>{row?.originalFileName}</TableCell>
                <TableCell align="center">{row?.pageNumber}ページ</TableCell>
                <TableCell align="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {isSuccess && <CheckCircleIcon fontSize="small" color={isIcon ? "success" : 'disabled'} sx={{ mr: 0.5 }} />}
                        {statusText}
                    </Box>
                </TableCell>
                <TableCell align="center">
                    {isIcon && (
                        <LoadingButton
                            aria-label="delete"
                            color={isSuccess ? 'error' : 'default'}
                            loading={isLoading}
                            loadingPosition="center"
                            startIcon={<DeleteIcon fontSize="small" sx={{ ml: 1.5 }} />}
                            onClick={handleDelete}
                            disabled={!isSuccess}
                        />
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};



const Learning = () => {
    const [open, setOpen] = useState(false);
    const [companyInfo, setCompanyInfo] = React.useState(null);
    // const [users, setUsers] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [openUploadFileAlertDialog, setOpenUploadFileAlertDialog] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [upperLimit, setUpperLimit] = useState(0);
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

    const { user } = useUser();
    const { id: companyId } = useParams();
    const { showSnackbar } = useSnackbar();

    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId;

    useEffect(() => {
        if (companyId) {
            fetchCompanyInfo(companyId, setCompanyInfo);
            fetchUploadsFiles(companyId, setUploadFiles);
        }
    }, [companyId]);

    useEffect(() => {
        if (companyInfo?.planPrice === 'price_1PAUU6GBe8AZCWiIPqYf1X2N') {
            // トライアルプラン
            setUpperLimit(0);
        } else if (companyInfo?.planPrice === 'price_1PAUWzGBe8AZCWiI1Q6J9Q2v') {
            // スタンダードプラン
            setUpperLimit(100);
        } else {
            // 未定義
            setUpperLimit(0);
        }
    }, [companyInfo]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFile(null);
        setNumPages(null);
        setPreviewUrl(null);
        setOpenUploadFileAlertDialog(false);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === "application/pdf") {
            if (file.size > MAX_FILE_SIZE) {
                setOpenUploadFileAlertDialog(true);
                setSelectedFile(null);
                setNumPages(null);
                setPreviewUrl(null);
                return;
            }

            setOpenUploadFileAlertDialog(false);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            setSelectedFile(file);
            formData.append('file', file);

            fetch(CLOUD_FUNCTION_FILE_PAGE_COUNT_API_ENDPOINT, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    setNumPages(data.page_count);
                })
                .catch(error => {
                    console.error('Error counting pages:', error);
                });
        } else {
            setSelectedFile(null);
            setNumPages(0);
        }
    };

    const handleUploadClick = async () => {
        if (selectedFile) {
            const formData = new FormData();
            const fileNameWithoutExtension = selectedFile.name.split('.').slice(0, -1).join('.');
            formData.append('file', selectedFile);
            formData.append('file_name', fileNameWithoutExtension);
            formData.append('company_id', companyId);
            formData.append('page_number', numPages);

            handleClose();
            showSnackbar({ message: '処理を開始しました', type: 'info' });

            try {
                const response = await fetch(CLOUD_FUNCTION_CREATE_INDEX_FROM_PDF_API_ENDPOINT, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    showSnackbar({ message: `${fileNameWithoutExtension}の処理が完了しました。`, type: 'success' });
                }
            } catch (error) {
                console.error('Upload failed:', error);
            }
        }

    };

    return (
        <>
            <Dialog open={open} onClose={handleClose}
                maxWidth="md" // ダイアログの最大幅をmediumに設定
                fullWidth={true} // ダイアログの幅をmaxWidthまで広げる
            >
                <DialogContent>
                    <Collapse in={!openUploadFileAlertDialog}>
                        <Alert
                            sx={{ mb: 2 }}
                            color="info"
                        >
                            アップロード可能なファイル形式はPDFのみです。ファイルサイズは10MBまでです。
                        </Alert>
                    </Collapse>

                    <Collapse in={openUploadFileAlertDialog}>
                        <Alert
                            sx={{ mb: 2 }}
                            color="error"
                        >
                            ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。
                        </Alert>
                    </Collapse>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <input
                            accept="application/pdf"
                            id="raised-button-file"
                            multiple
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="outlined" color="primary" component="span" sx={{ my: 2, p: 2, px: 6 }} startIcon={<CloudUploadIcon />}>
                                ファイルを選択
                            </Button>
                        </label>
                        {selectedFile && (
                            <>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                                    ページ数: {numPages}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ textAlign: 'center', mt: 2 }}>
                                    選択されたファイル名
                                </Typography>
                                <Typography variant="body1" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 2 }}>
                                    {selectedFile.name}
                                </Typography>
                                <embed src={previewUrl} type="application/pdf" width="100%" height="600px" />
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{}}>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <LoadingButton
                        onClick={handleUploadClick}
                        disabled={!selectedFile || !numPages}
                        loading={!!selectedFile && !numPages}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        アップロード
                    </LoadingButton>
                </DialogActions>
            </Dialog>


            <NavigationBar />
            <Container maxWidth="xl" sx={{ mt: 15, mb: 15, minHeight: '50vh' }}>
                <CompanyTopNavigationContent hasEditPermission={hasEditPermission} company={companyInfo} />
                {/* ダイレクトチャットを受け入れるかどうかのボタン */}
                {companyInfo?.totalUploadedPages >= upperLimit && (
                    <Alert color='error'>
                        すでにプランの上限に達しているため、アップロードできません。アップロード可能数を増やすには、プランをアップグレードするか、弊社へお問い合わせください。
                    </Alert>
                )}
                {/* companyInfoにtotalUploadedPageが未定義の時 */}
                {upperLimit === 0 && (
                    <Alert color='error'>
                        プラン情報が取得できなかったため、アップロード可能数を確認できません。しばらくしてから再度お試しください。または、プランをご確認ください。
                    </Alert>
                )}
                <Paper elevation={0} sx={{ mt: 2, width: '100%', p: 2, border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box display='flex' alignItems='center'>
                        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                            アップロード可能数　[{companyInfo?.totalUploadedPages || 0}/{upperLimit}枚]
                        </Typography>
                    </Box>
                    <Tooltip title={`${companyInfo?.totalUploadedPages || 0}/${upperLimit}ページ`}
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -14],
                                        },
                                    },
                                ],
                            },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ width: '98%' }}>
                                <LinearProgressWithLabel value={companyInfo?.totalUploadedPages || 0} maxValue={upperLimit} />
                            </Box>
                        </Box>
                    </Tooltip>
                </Paper>

                <Paper elevation={0} sx={{ mt: 2, width: '100%', border: 1, borderColor: '#ECEEF1', borderRadius: '8px' }}>
                    <Box sx={{ mb: 2, p: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mr: 3 }}>
                                    ファイル一覧
                                </Typography>
                            </Box>
                            <Typography variant="body2" component="p" sx={{ color: 'text.secondary' }}>
                                PDFファイルをアップロードすることで、CielのAIを賢くすることができます。<br />
                                ここでは、アップロードしたファイルの一覧を確認できます。
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={handleClickOpen}
                                disabled={upperLimit === 0 || companyInfo?.totalUploadedPages >= upperLimit}
                            >
                                ファイルをアップロード
                            </Button>
                        </Box>
                    </Box>
                    <Divider />
                    <Box mb={6}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">アップロード日</TableCell>
                                        <TableCell align='left'>ファイル名</TableCell>
                                        <TableCell align="center">ページ数</TableCell>
                                        <TableCell align="center">処理状況</TableCell>
                                        <TableCell align="center">削除</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {uploadFiles.map((row) => (
                                        <DataRow key={row.id} row={row} companyId={companyId} setUploadFiles={setUploadFiles} isIcon={true} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Container >
            <Footer />
        </>
    )
}

export default Learning;