import React from 'react';
import { Grid, Typography } from '@mui/material';

const IconTypography = ({ icon: Icon, children }) => {
    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                <Icon color='primary' sx={{ mr: 1 }} />
            </Grid>
            <Grid item>
                <Typography variant="body1" gutterBottom component="div" fontWeight="bold">
                    {children}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default IconTypography;