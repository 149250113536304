import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button, Avatar, Paper } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ApplicantContactModal from '../Chat/ApplicantContactModal';

const ScrollFooterBar = ({ avatarSrc, companyName, companyId, message }) => {
    const [applicantModaOopen, setApplicantModalOpen] = useState(false); // [1] モーダルの表示状態を管理するstate
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(window.pageYOffset > 0);
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);

        // user providerからユーザー情報を取得する処理
        // onBookmarkClick, onButtonClickの処理を実装する
    }, []);

    const onBookmarkClick = () => {
        // user providerからユーザー情報を取得する処理
    }

    const onButtonClick = () => {
        // applicantModalOpenをtrueにする処理
        setApplicantModalOpen(true);
    }

    return (
        <Box>
            <ApplicantContactModal isOpen={applicantModaOopen} setIsOpen={setApplicantModalOpen} companyId={companyId} />
            <Paper elevation={8} sx={{
                position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)',
                maxWidth: { xs: '90%', md: '960px' }, bgcolor: 'white', color: 'black',
                p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                visibility: isVisible ? 'visible' : 'hidden', opacity: isVisible ? 1 : 0,
                transition: 'visibility 0.3s, opacity 0.3s linear', borderRadius: '5px',
            }}>
                <Avatar sx={{ marginRight: 1 }} src={avatarSrc} />
                <Box sx={{ flex: 1, mx: 2 }}>
                    <Typography variant="subtitle2">{companyName}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {message}
                    </Typography>
                </Box>
                <IconButton color="inherit" onClick={onBookmarkClick}>
                    <BookmarkBorderIcon />
                </IconButton>
                <Button variant="contained" onClick={onButtonClick}>
                    担当者と話してみる
                </Button>
            </Paper>
        </Box>
    );
};

export default ScrollFooterBar;