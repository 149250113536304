import React from 'react';
import { Box, Chip, Divider, Typography, Avatar, IconButton, Stack, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import { Link } from 'react-router-dom';

const PostDetailsTopNavigationContent = () => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                <Typography variant="subtitle2" color='text.secondary' sx={{ paddingX: 1, fontWeight: 'bold' }}>
                    on 2024/01/17
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="subtitle2" color='text.secondary' sx={{ paddingX: 1, fontWeight: 'bold' }}>
                    90 views
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography variant="subtitle2" color='text.secondary' sx={{ paddingX: 1, fontWeight: 'bold' }}>
                    3人がエントリー中
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {/* チップのリスト。ここでデータを動的にレンダリングする */}
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
                <Chip label="Chip Filled" size="small" sx={{ mr: 1 }} />
                <Chip label="Chip Outlined" size="small" variant="outlined" sx={{ mr: 1 }} />
            </Box>

            <Typography variant='h4' component='h1' color='text.primary' sx={{ fontWeight: 'bold', mt: 2 }}>
                24卒｜急成長中のサービスをUI/UXの力で加速させるデザイナーを募集！
            </Typography>

            <Box display="flex" justifyContent="space-between" paddingBottom={1}>
                <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        YA
                    </Avatar>
                    <Typography variant="subtitle1"
                        color='text.secondary'
                        sx={{
                            ml: 1,
                            fontWeight: 'bold',
                            textDecoration: 'none', // 通常時は下線を非表示にする
                            color: 'gray', // テキストの色を継承する
                            '&:hover': {
                                textDecoration: 'underline', // ホバー時に下線を表示する
                                color: 'black', // ホバー時に色を変更する
                            }
                        }}
                        component={Link} to={`/post/company/1`} >
                        山形株式会社
                    </Typography>
                </Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                    <IconButton size="large" sx={{ ml: 1 }}>
                        <TurnedInNotIcon fontSize="inherit" />
                    </IconButton>
                    <Button variant='contained'>AIと話す</Button>
                </Stack>
            </Box>
        </>
    );
}

export default PostDetailsTopNavigationContent;