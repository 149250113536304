// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter } from '@mui/material';

// import { firestore } from '../../../../firebase';
// import { collection, getDocs, query, limit, orderBy, startAfter } from 'firebase/firestore';

// import TablePaginationActions from '../../../../components/Layout/TablePaginationActions';
// import Row from './Row';

// function getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies) {
//     return async () => {
//         const companiesRef = collection(firestore, 'preProcessCompanies');
//         let q;

//         if (rowsPerPage === -1) {
//             // rowsPerPage が -1 の場合は全てのドキュメントを取得
//             q = query(companiesRef, orderBy('name'));
//         } else if (page === 0) {
//             // 最初のページ
//             q = query(companiesRef, orderBy('name'), limit(rowsPerPage));
//         } else {
//             // それ以外のページ
//             const lastDoc = pageDocSnapshots[page - 1]; // 前のページの最後のドキュメントを取得
//             q = query(companiesRef, orderBy('name'), startAfter(lastDoc), limit(rowsPerPage));
//         }

//         const documentSnapshots = await getDocs(q);
//         const docs = documentSnapshots.docs;
//         const companiesData = docs.map(doc => ({ id: doc.id, ...doc.data() }));

//         // 最後のドキュメントスナップショットを更新
//         const newPageDocSnapshots = [...pageDocSnapshots];
//         newPageDocSnapshots[page] = docs[docs.length - 1]; // 現在のページの最後のドキュメントを保存
//         setPageDocSnapshots(newPageDocSnapshots);

//         setCompanies(companiesData);
//     };
// }

// const PreProcessCompaniesTable = () => {
//     const [companies, setCompanies] = useState([]);
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);
//     const [total, setTotal] = useState(0); // コレクション内のドキュメントの総数
//     const [pageDocSnapshots, setPageDocSnapshots] = useState([null]); // ページごとのドキュメントスナップショットを保存

//     useEffect(() => {
//         // 総数を取得
//         const fetchTotal = async () => {
//             const querySnapshot = await getDocs(collection(firestore, 'preProcessCompanies'));
//             setTotal(querySnapshot.docs.length);
//         };

//         fetchTotal();
//     }, []);

//     useEffect(() => {
//         const fetchCompanies = getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies);

//         fetchCompanies();
//     }, [page, rowsPerPage, pageDocSnapshots]);

//     // ページネーションのハンドラ
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         const newRowsPerPage = parseInt(event.target.value, 10);
//         setRowsPerPage(newRowsPerPage);
//         setPage(0); // 行数が変更されたら最初のページに戻る

//         // ページネーションの状態をリセット
//         if (newRowsPerPage === -1) {
//             setPageDocSnapshots([null]);
//         }
//     };

//     return (
//         <TableContainer component={Paper}>
//             <Table aria-label="collapsible table">
//                 <TableHead>
//                     <TableRow>
//                         <TableCell />
//                         <TableCell>名前</TableCell>
//                         <TableCell>ウェブサイトURL</TableCell>
//                         <TableCell align="center">ステータス</TableCell>
//                         <TableCell align="right">エラー</TableCell>
//                         <TableCell align="right">作成日</TableCell>
//                         <TableCell align="center">更新日</TableCell>
//                         <TableCell align="center">アクション</TableCell>
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {companies.map((company) => (
//                         <Row key={company.id} row={company} />
//                     ))}
//                 </TableBody>
//                 <TableFooter>
//                     <TableRow>
//                         <TablePagination
//                             rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//                             count={total || -1} // 総数がわからない場合は-1
//                             rowsPerPage={rowsPerPage}
//                             page={page}
//                             SelectProps={{
//                                 inputProps: { 'aria-label': 'rows per page' },
//                                 native: true,
//                             }}
//                             ActionsComponent={TablePaginationActions}
//                             onPageChange={handleChangePage}
//                             onRowsPerPageChange={handleChangeRowsPerPage}
//                         />
//                     </TableRow>
//                 </TableFooter>
//             </Table>
//         </TableContainer>
//     );
// }

// export default PreProcessCompaniesTable;


import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { firestore } from '../../../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import Row from './Row';

const PreProcessCompaniesTable = () => {
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchAllCompanies = async () => {
            const companiesRef = collection(firestore, 'preProcessCompanies');
            const q = query(companiesRef, orderBy('name'));
            const querySnapshot = await getDocs(q);
            const companiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCompanies(companiesData);
        };

        fetchAllCompanies();
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>名前</TableCell>
                        <TableCell>ウェブサイトURL</TableCell>
                        <TableCell align="center">ステータス</TableCell>
                        <TableCell align="right">エラー</TableCell>
                        <TableCell align="right">作成日</TableCell>
                        <TableCell align="center">更新日</TableCell>
                        <TableCell align="center">アクション</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company) => (
                        <Row key={company.id} row={company} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PreProcessCompaniesTable;
