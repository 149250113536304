// callApiをカスタムフックとして定義
export const useCallApi = () => {

    const callApi = async (apiUrl, data, method = 'POST') => {
        try {
            const response = await fetch(apiUrl, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // 成功メッセージの表示も省略
            return { success: true, data: response };
        } catch (error) {
            // エラー情報のみを返す
            return { success: false, error: error };
        }
    };

    return callApi;
};
