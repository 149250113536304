import React, { useState } from 'react';
// Router and hooks
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom/dist';
// MUI components
import { Button, TextField, Typography, Container, Link } from '@mui/material';

// Context
import { useSnackbar } from '../../Context/SnackbarContext';

// Components
import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import PasswordResetDialog from '../../components/Auth/PasswordResetDialog';
import NavigationBar from '../../components/Layout/NavigationBar';
import Footer from '../../components/Layout/Footer';

// Firebase 
import { auth, firestore } from '../../firebase';
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from 'firebase/firestore';
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { setDoc, serverTimestamp } from 'firebase/firestore';

const Login = () => {
    const [resetDialogOpen, setResetDialogOpen] = useState(false); // ダイアログの状態
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });

    // 関数の取得
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const handleLogin = async ({ email, password }) => { // Destructure email and password from data
        try {
            const userCred = await signInWithEmailAndPassword(auth, email, password);
            // ログイン成功後の処理（例：ホーム画面へのリダイレクト）をここに記述
            const userDocRef = doc(firestore, 'users', userCred.user.uid); // Assuming 'users' is the collection
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                switch (userData.userType) {
                    case 'company':
                        if (userData.companyId) {
                            navigate(`/company/${userData.companyId}/dashboard`);
                        } else {
                            navigate('/');
                        }
                        break;
                    case 'admin':
                        navigate('/admin/dashboard'); // Adjust the path as needed
                        break;
                    default:
                        showSnackbar({ message: "ログインしました", type: 'success' });
                        navigate('/');
                        break;
                }
            } else {
                console.error('User does not exist in database');
                // Optionally show an error message or redirect to a signup page
            }
        } catch (error) {
            const errorMessage = getFirebaseErrorMessage({
                defaultErrorMessage: 'ログインに失敗しました。もう一度試してください。',
                errorCode: error.code
            });
            // 表示するエラーメッセージの内容を日本語化する
            showSnackbar({ message: errorMessage, type: 'error', title: 'ログインに失敗' });
        }
    };

    const handleOIDCLogin = async () => {
        try {
            // Storage Access API を使用してストレージアクセスを確認
            if (document.hasStorageAccess && !await document.hasStorageAccess()) {
                await document.requestStorageAccess();
            }

            // step1: LINEログインプロバイダーでLINEログインを実行
            const lineProvider = new OAuthProvider('oidc.line');
            const userCredential = await signInWithPopup(auth, lineProvider);
            const user = userCredential.user;

            // step2: Firestoreにユーザー情報を確認
            const userDocRef = doc(firestore, 'users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // step3.1 Firestoreにユーザー情報を保存
                await setDoc(userDocRef, {
                    email: '',
                    phoneNumber: '',
                    lastName: user.displayName,
                    firstName: '',
                    birthDate: '',
                    gender: 'other',
                    userType: 'other',
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                    isDeleted: false,
                    emailNotification: 'notify',
                    age: 16,
                    hasCatchphrase: false,
                    hasProfile: false,
                    hasGoals: false,
                    hasAwards: false,
                    hasCertifications: false,
                    hasUsericonurl: false,
                    providerId: 'line',
                });
            }
            // step3 ログイン成功後の処理（例：ホーム画面へのリダイレクト）をここに記述
            showSnackbar({ message: "LINEログインしました", type: 'success' });
            navigate('/');
        } catch (err) {
            // エラーメッセージを表示
            showSnackbar({ message: 'LINEログインに失敗しました', type: 'error', title: 'ログインに失敗' });
        }
    };

    return (
        <>
            <NavigationBar />
            <ResponsiveContainer>
                <Container component="main" maxWidth="xs" sx={{ minHeight: '55vh' }}>
                    <Typography component="h1" variant="h5">ログイン</Typography>
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="メールアドレス"
                            {...register("email", {
                                required: "メールアドレスを入力してください",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "無効なメールアドレスです"
                                }
                            })}
                            error={Boolean(errors.email)}
                            helperText={errors.email?.message}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="パスワード"
                            type="password"
                            {...register("password", {
                                required: "パスワードを入力してください",
                                minLength: {
                                    value: 6,
                                    message: "パスワードは6文字以上で入力してください"
                                }
                            })}
                            error={Boolean(errors.password)}
                            helperText={errors.password?.message}
                        />
                        <Button type="submit" fullWidth variant="contained" size="large" color="primary" sx={{ borderRadius: '8px', paddingY: 1.5, marginTop: 2, fontWeight: 'bold' }}>ログイン</Button>
                    </form>
                    <Button
                        variant='contained'
                        onClick={handleOIDCLogin}
                        fullWidth
                        size="large"
                        sx={{
                            borderRadius: '8px',
                            paddingY: 1.5,
                            marginTop: 2,
                            backgroundColor: '#06C755',
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: 'rgba(6, 199, 85, 0.9)', // 透明度10%追加
                                boxShadow: 'none', // デフォルトのMUIホバーシャドウを無効化
                            },
                            '&:active': {
                                backgroundColor: 'rgba(6, 199, 85, 0.7)', // 透明度30%追加
                                boxShadow: 'none', // アクティブ時もシャドウを無効化
                            }
                        }}
                    >
                        LINEでログイン
                    </Button>
                    <Typography variant="body2" align="" sx={{ mt: 2 }}>LINEログインは一般ユーザーのみ利用できます</Typography>
                    <Link component="button" variant="body2" onClick={() => setResetDialogOpen(true)} sx={{ mt: 2 }}>
                        パスワードをお忘れですか？
                    </Link>
                    <PasswordResetDialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} />
                </Container>
            </ResponsiveContainer>
            <Footer />
        </>
    );
};

export default Login;
