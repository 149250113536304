import React, { useContext, useEffect, useState, useRef } from 'react';

import { useParams, useNavigate } from 'react-router-dom';
import Joyride from 'react-joyride';

import { Box, List, Typography, IconButton, Toolbar, CssBaseline, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArchiveIcon from '@mui/icons-material/Archive';
import RepartitionIcon from '@mui/icons-material/Repartition';

// Custom Components
import CustomListItem from '../components/Chat/CustomListItem';
import NavigationBar from '../components/Layout/NavigationBar';
import { smChatComponent } from '../components/Chat/smChatComponent';
import { pcChatComponent } from '../components/Chat/pcChatComponent';
import { SmChatAppBar } from '../components/Chat/SmChatAppBar';
import FinishDialog from '../components/Tour/FinishDialog';

// Contexts
import { useUser } from '../Context/UserContext';
import { useSnackbar } from '../Context/SnackbarContext';
import { TourContext, handleCallback } from '../Context/TourContext';

// Firebase Services
import { firestore } from '../firebase';
import { doc, getDoc, collection, query, where, onSnapshot, addDoc, serverTimestamp, orderBy, updateDoc } from 'firebase/firestore';

// Utilities
import { GENERATE_AI_MESSAGE_FROM_INDEX } from './../utils/apiEndpoint'; // Ensure this is an environment variable if applicable
import { mailTemplate } from '../utils/template';


const Chat = () => {
    // この部分に状態管理やイベントハンドリングのロジックを追加
    const [activeChat, setActiveChat] = useState(null);
    const [chatRooms, setChatRooms] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isLoadingAiResponse, setIsLoadingAiResponse] = useState(false);
    const [showArchivedChats, setShowArchivedChats] = useState(false);
    const [userIcons, setUserIcons] = useState([]);
    const [openFinishTourDialog, setOpenFinishTourDialog] = useState(false);

    const scrollRef = useRef(null);
    const { showSnackbar } = useSnackbar();

    const { steps, setSteps, run, setRun } = useContext(TourContext);
    const { user } = useUser();
    const navigate = useNavigate(); // navigate関数を取得
    const { id: chatId } = useParams();

    const theme = useTheme();
    // theme.breakpoints.up('sm')は、画面の幅がsmブレイクポイント以上であるかどうかを判定します。
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    // アクティブなチャットルームのデータを取得
    const activeChatRoom = chatRooms.find(room => room.id === activeChat);

    // participantIdsリストから特定のユーザーのプロフィールページにナビゲートする関数
    const handleProfileLinkClick = (participantId) => {
        // 例として、リストの最初のユーザーIDを使用
        const userId = participantId
        navigate(`/profile/${userId}`);
    };

    useEffect(() => {
        setActiveChat(chatId); // チャットルームが変更されたらアクティブなチャットをリセット
    }, [chatId]);

    // 参加しているchatRoomのリアルタイムデータを取得
    useEffect(() => {
        if (!user) return; // ユーザーがログインしていなければ何もしない

        let q;
        if (user.userType === 'company') {
            // 企業ユーザーの場合、自社に関連するチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('companyId', '==', user.companyId),
                where('archived', '==', false),
                where('chatTag', '!=', 'AI担当者'),
                orderBy('updatedAt', 'desc')
            );
        } else {
            // 企業以外のユーザー（学生や新たに追加されたユーザー種別）の場合、
            // 参加しているチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user.uid),
                where('archived', '==', false),
                orderBy('updatedAt', 'desc'));
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms = [];

            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();

                let title = ''; // タイトル用の変数を初期化
                let detail = ''; // 詳細用の変数を初期化

                if (user.userType !== 'company' && roomData.companyId) {
                    // 学生の場合、企業名を取得
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name; // 企業名
                        detail = companySnap.data().companyDetail; // 企業情報
                    }
                } else if (user.userType === 'company') {
                    // 企業の場合、チャットを開始したユーザーの名前を取得
                    const participants = roomData.participants;
                    const studentUserIds = participants
                        .filter(participant => participant.userType !== 'company')
                        .map(participant => participant.userId);

                    const studentNames = [];
                    for (const studentId of studentUserIds) {
                        const studentRef = doc(firestore, `users/${studentId}`);
                        const studentSnap = await getDoc(studentRef);
                        if (studentSnap.exists()) {
                            const studentData = studentSnap.data();
                            const studentName = `${studentData.lastName} ${studentData.firstName}`;
                            studentNames.push(studentName);
                        }
                    }
                    title = studentNames.join(', '); // 生徒の名前をカンマ区切りで結合
                    detail = ''; // 企業ユーザーの場合は詳細は設定しないか、別の情報を設定
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title: title, // タイトル（企業名またはユーザー名）
                    detail: detail, // 詳細（企業情報または空）
                });
            }

            setChatRooms(rooms);
        });

        // コンポーネントのアンマウント時にリスナーを解除
        return () => unsubscribe();
    }, [user]); // userを依存配列に追加


    // アクティブなチャットルームが変更されたら、そのチャットルームのメッセージを取得
    useEffect(() => {
        if (!activeChat) return; // アクティブなチャットルームがなければ何もしない
        // 選択されたチャットルームのメッセージを取得するクエリ
        const q = query(collection(firestore, `chats/${activeChat}/messages`), orderBy('timestamps'));

        // リアルタイムでメッセージを取得
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const msgs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setMessages(msgs);
        });

        // アクティブなチャットの参加者のアイコンを取得
        const fetchUserIcons = async () => {
            const chatDocRef = doc(firestore, "chats", activeChat);
            const chatDoc = await getDoc(chatDocRef);

            if (chatDoc.exists()) {
                const participantIds = chatDoc.data().participantIds;
                const userIconPromises = participantIds.map(async (uid) => {
                    const userDocRef = doc(firestore, "users", uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        return { uid, userIconUrl: userDoc.data().userIconUrl || 'null' };
                    }
                    return { uid, userIconUrl: 'default-icon-url.png' }; // デフォルトアイコンのURL
                });
                const icons = await Promise.all(userIconPromises);
                setUserIcons(icons);
            }
        };
        fetchUserIcons();

        // コンポーネントのアンマウント時にリスナーを解除
        return () => unsubscribe();
    }, [activeChat]);

    // メッセージが更新されたら、最新のメッセージまでスクロール
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    useEffect(() => {
        if (user) {
            if (user?.tutorialStatus === 'completed' || user?.tutorialStatus === 'skipped') {
                setRun(false);
            } else {
                setRun(true);
            }
        } else {
            setRun(true);
        }
    }, [setRun, user]);

    useEffect(() => {
        // ステップの配列が空かどうかを確認
        if (steps.length === 0) {
            setRun(false);
            return;
        }

        const newSteps = [...steps];
        const lastStepIndex = newSteps.length - 1;

        const newData = {
            messageText: "どんな会社ですか？",
            user: user,
            activeChat: activeChat,
            activeChatRoom: activeChatRoom,
            setIsLoadingAiResponse: setIsLoadingAiResponse,
            setRun: setRun,
            setOpenFinishTourDialog: setOpenFinishTourDialog
        };

        // 最後のステップが存在し、そのデータが現状の値と異なる場合に更新
        if (newSteps[lastStepIndex] && JSON.stringify(newSteps[lastStepIndex].data) !== JSON.stringify(newData)) {
            newSteps[lastStepIndex].data = newData;
            setSteps(newSteps);
        }
    }, [setSteps, activeChatRoom, user, activeChat, steps, setIsLoadingAiResponse, setOpenFinishTourDialog, setRun]);




    // メッセージをFirestoreに登録する関数
    const addMessageToFirestore = async (chatId, messageText, userId) => {
        try {
            await addDoc(collection(firestore, `chats/${chatId}/messages`), {
                senderId: userId,
                text: messageText,
                timestamps: serverTimestamp(), // Firestoreのサーバー時間を使用
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });
        } catch (error) {
            console.error('Error adding message to Firestore:', error);
        }
    };

    // APIにリクエストを送る関数
    const callAiMessageEndpoint = async (text, companyId, companyName) => {
        // const response = await fetch('http://127.0.0.1:5001/ciel-dac23/asia-northeast1/generate_ai_message_from_index', {
        const response = await fetch(GENERATE_AI_MESSAGE_FROM_INDEX, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                text,
                company_id: companyId,
                company_name: companyName,
            }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json(); // レスポンスのJSONを返す
    };

    const formatSimilarityChunksInfoData = (data) => {
        return data.map(item => ({
            score: item[1][0],
            filename: item[0]
        }));
    };

    // MessageInputField コンポーネント内でメッセージ送信機能を実装
    const sendMessage = async (messageText) => {
        // ステップ1: ユーザーからのメッセージをFirestoreに保存
        await addMessageToFirestore(activeChat, messageText, user.uid);

        if (activeChatRoom.chatTag === 'AI担当者') {
            // ステップ2: APIを叩いてAIからのレスポンスを取得
            setIsLoadingAiResponse(true); // リクエスト開始時にローディングを表示
            try {
                const aiResponse = await callAiMessageEndpoint(messageText, activeChatRoom.companyId, activeChatRoom.title);
                // ステップ3: APIからのレスポンスをFirestoreに保存
                if (aiResponse && aiResponse.response && aiResponse.response.choices && aiResponse.response.choices.length > 0) {
                    const aiMessageContent = aiResponse.response.choices[0].message.content;
                    // await addMessageToFirestore(activeChat, aiMessageContent, 'AI'); // 'AI'はAIからのメッセージを示すための仮のuserId
                    await addDoc(collection(firestore, `chats/${activeChat}/messages`), {
                        senderId: 'AI',
                        text: aiMessageContent,
                        similar_chunks_info: formatSimilarityChunksInfoData(aiResponse?.similar_chunks_info) || null,
                        usage_completion_tokens: aiResponse?.response.usage.completion_tokens || null,
                        usage_prompt_tokens: aiResponse?.response.usage.prompt_tokens || null,
                        usage_total_tokens: aiResponse?.response.usage.total_tokens || null,
                        timestamps: serverTimestamp(), // Firestoreのサーバー時間を使用
                        createdAt: serverTimestamp(),
                        updatedAt: serverTimestamp(),
                    });
                }
            } catch (error) {
                console.error('AIメッセージ取得エラー:', error);
            } finally {
                setIsLoadingAiResponse(false); // レスポンス受信後
            }
        }
        // ステップ4:AI担当者以外の場合かつ，ユーザーのemailNotificationが'notify'の場合，mailコレクションに所定のドキュメントを追加する
        if (activeChatRoom.chatTag !== 'AI担当者') {
            try {
                // 参加者のUIDから現在のユーザーを除外
                const otherParticipantIds = activeChatRoom.participantIds.filter(uid => uid !== user.uid);
                let notificationUids = [];

                // 各参加者のメール通知設定を確認
                for (const participantId of otherParticipantIds) {
                    const userRef = doc(firestore, 'users', participantId);
                    const userSnap = await getDoc(userRef);

                    if (userSnap.exists() && userSnap.data().emailNotification === 'notify') {
                        notificationUids.push(participantId);
                    }
                }

                if (notificationUids.length > 0) {
                    await addDoc(collection(firestore, 'mail'), {
                        toUids: notificationUids,
                        message: {
                            subject: '【Ciel】メッセージが届いています',
                            html: mailTemplate(activeChatRoom.id)
                        }
                    });
                }
            } catch (error) {
                console.error('Error adding mail to Firestore:', error);
            }
        }
    };

    // チャットの削除フラグを更新する関数
    const markChatAsDeleted = async () => {
        const chatRef = doc(firestore, "chats", activeChat);
        try {
            await updateDoc(chatRef, {
                archived: true
            });
            navigate('/chat/null');
            showSnackbar({ message: "チャットをアーカイブしました", type: 'success', title: '削除完了' });
            // 必要に応じて、成功時の処理をここに追加（例: スナックバーで通知）
        } catch (error) {
            showSnackbar({ message: "再度お試しください", type: 'error', title: '削除失敗' });
            // エラーハンドリング（例: エラーメッセージを表示）
        }
    };

    const toggleAndFetchArchivedChats = async () => {
        let q;
        if (user.userType !== 'company') {
            // 学生ユーザーの場合、参加しているチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user.uid),
                where('archived', '==', true));
        } else if (user.userType === 'company') {
            // 企業ユーザーの場合、自社に関連するチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('companyId', '==', user.companyId),
                where('archived', '==', true));
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms = [];

            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();

                let title = ''; // タイトル用の変数を初期化
                let detail = ''; // 詳細用の変数を初期化

                if (user.userType !== 'company' && roomData.companyId) {
                    // 学生の場合、企業名を取得
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name; // 企業名
                        detail = companySnap.data().companyDetail; // 企業情報
                    }
                } else if (user.userType === 'company') {
                    // 企業の場合、チャットを開始したユーザーの名前を取得
                    title = `${roomData.startedUserFirstName} ${roomData.startedUserLastName}`; // 開始ユーザーの名前
                    detail = ''; // 企業ユーザーの場合は詳細は設定しないか、別の情報を設定
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title: title, // タイトル（企業名またはユーザー名）
                    detail: detail, // 詳細（企業情報または空）
                });
            }

            setChatRooms(rooms);
            setShowArchivedChats(true);
        });
        return () => unsubscribe();
    };

    const toggleAndFetchUnArchivedChats = async () => {
        let q;
        if (user.userType !== 'company') {
            // 学生ユーザーの場合、参加しているチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('participantIds', 'array-contains', user.uid),
                where('archived', '==', false));
        } else if (user.userType === 'company') {
            // 企業ユーザーの場合、自社に関連するチャットルームでかつisDeletedがfalseのものを検索
            q = query(collection(firestore, 'chats'),
                where('companyId', '==', user.companyId),
                where('archived', '==', false));
        }

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
            const rooms = [];

            for (const roomDoc of querySnapshot.docs) {
                const roomData = roomDoc.data();

                let title = ''; // タイトル用の変数を初期化
                let detail = ''; // 詳細用の変数を初期化

                if (user.userType !== 'company' && roomData.companyId) {
                    // 学生の場合、企業名を取得
                    const companyRef = doc(firestore, `companies/${roomData.companyId}`);
                    const companySnap = await getDoc(companyRef);
                    if (companySnap.exists()) {
                        title = companySnap.data().name; // 企業名
                        detail = companySnap.data().companyDetail; // 企業情報
                    }
                } else if (user.userType === 'company') {
                    // 企業の場合、チャットを開始したユーザーの名前を取得
                    title = `${roomData.startedUserFirstName} ${roomData.startedUserLastName}`; // 開始ユーザーの名前
                    detail = ''; // 企業ユーザーの場合は詳細は設定しないか、別の情報を設定
                }

                rooms.push({
                    id: roomDoc.id,
                    ...roomData,
                    title: title, // タイトル（企業名またはユーザー名）
                    detail: detail, // 詳細（企業情報または空）
                });
            }

            setChatRooms(rooms);
            setShowArchivedChats(false);
        });

        // コンポーネントのアンマウント時にリスナーを解除
        return () => unsubscribe();
    };


    return (
        <>
            {user && (
                <Joyride
                    callback={handleCallback}
                    continuous={true}
                    run={run}
                    steps={steps}
                    stepIndex={2}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    locale={{
                        back: '戻る',
                        close: '閉じる',
                        last: '質問する',
                        next: '次へ',
                        skip: 'スキップ'
                    }}
                    styles={{
                        options: {
                            zIndex: 1000,
                        },
                        buttonNext: {
                            backgroundColor: '#1976d2', // Material-UI primary color
                            color: 'white',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                            border: 'none',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)', // Material-UI shadow
                        },
                        buttonBack: {
                            backgroundColor: '#eeeeee', // Material-UI grey color
                            color: '#616161',
                            borderRadius: '4px',
                            padding: '12px 16px',
                            // fontSize: '0.875rem',
                            textTransform: 'uppercase',
                            minWidth: '64px',
                        },
                    }}
                />
            )}

            <FinishDialog open={openFinishTourDialog} onClose={() => setOpenFinishTourDialog(false)} />

            {isSm ? (
                <>
                    {pcChatComponent(
                        showArchivedChats,
                        toggleAndFetchUnArchivedChats,
                        toggleAndFetchArchivedChats,
                        chatRooms,
                        activeChat,
                        setActiveChat,
                        navigate,
                        activeChatRoom,
                        user,
                        handleProfileLinkClick,
                        markChatAsDeleted,
                        messages,
                        isLoadingAiResponse,
                        scrollRef,
                        sendMessage,
                        userIcons
                    )}
                </>
            ) : (
                chatId === 'null' ? (
                    <>
                        {/* チャットルームの一覧画面 */}
                        <NavigationBar />
                        <Box sx={{ mt: 10, mb: 0 }}>
                            <Box sx={{ position: 'relative' }}>
                                <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>チャットルーム</Typography>
                                    {showArchivedChats ? (
                                        <IconButton aria-label="archive" onClick={toggleAndFetchUnArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                            <RepartitionIcon fontSize="large" />
                                        </IconButton>
                                    ) : (
                                        <IconButton aria-label="archive" onClick={toggleAndFetchArchivedChats} sx={{ position: 'absolute', right: 0 }}>
                                            <ArchiveIcon fontSize="large" />
                                        </IconButton>
                                    )}
                                </Box>
                                <List>
                                    {chatRooms.map(room => (
                                        <CustomListItem
                                            key={room.id}
                                            avatarSrc={room.avatarSrc}
                                            title={room.title}
                                            tag={room.chatTag}
                                            lastMessage={room.lastMessage || 'メッセージを開始してください'}
                                            isActive={activeChat === room.id}
                                            onClick={() => {
                                                setActiveChat(room.id);
                                                navigate(`/chat/${room.id}`);
                                            }} />
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        {/* チャットルームごとの画面 */}
                        <CssBaseline />
                        <SmChatAppBar activeChatRoom={activeChatRoom} />
                        {/* チャットメッセージのリスト */}
                        <Toolbar id="back-to-top-anchor" />
                        {smChatComponent(messages, user, isLoadingAiResponse, scrollRef, showArchivedChats, sendMessage, markChatAsDeleted, userIcons)}
                    </>
                )
            )}
        </>
    );
};

export default Chat;