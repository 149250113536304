import React, { createContext, useContext, useState } from 'react';

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        title: '',
        message: '',
        type: 'success' // 'success' または 'error'
    });

    const showSnackbar = ({ message, type = 'success', title = '' }) => {
        setSnackbar({ open: true, title, message, type });
    };

    const closeSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <SnackbarContext.Provider value={{ snackbar, showSnackbar, closeSnackbar }}>
            {children}
        </SnackbarContext.Provider>
    );
};