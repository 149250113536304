import React, { useState } from 'react';

import { Box, IconButton, TextField, InputAdornment, Stack, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

import { useUser } from '../../Context/UserContext';

export function MessageInputField({ onSend, markChatAsDeleted }) {
    const [message, setMessage] = useState('');
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const handleSend = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');
        }
    };

    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder="メッセージを入力..."
            label={isSm ? "Message" : null}
            multiline
            value={message}
            size={isSm ? '' : 'small'}
            onChange={(e) => setMessage(e.target.value)}
            helperText={isSm ? "AIメッセージは生成系AIが自動で返信するため、間違った回答を返す場合があります。あらかじめご了承ください。" : ''}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: !isSm ? '25px' : '4px',
                    padding: !isSm ? '8px 5px' : '16.5px 14px',
                    '& fieldset': {
                        borderColor: !isSm ? 'grey.400' : 'primary', // 初期状態とホバー時のボーダーカラー
                    },
                    '&:hover fieldset': {
                        borderColor: !isSm ? 'grey.500' : 'primary', // ホバー時のボーダーカラーを少し濃く
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: !isSm ? 'grey.400' : 'primary', // フォーカス時のボーダーカラー
                    },
                },
            }}
            InputProps={{
                startAdornment: !isSm ? (
                    <InputAdornment position="start">
                        <IconButton
                            onClick={markChatAsDeleted}
                            size='small'
                            sx={{
                                backgroundColor: 'error.main', // 背景色を淡いグレーに設定
                                color: 'white', // アイコンの色をダークグレーに設定
                                '&:hover': {
                                    backgroundColor: 'white', // ホバー時の背景色を少し濃いグレーに設定
                                    color: 'error.main', // ホバー時のアイコンの色を白に設定
                                    border: '1px solid', // ホバー時のアウトラインを設定
                                    borderColor: 'error.main', // ホバー時のアウトラインの色を赤に設定
                                },
                                height: '30px', width: '30px',
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </InputAdornment>
                ) : null,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            // style={{ color: message.trim() ? lightBlue[700] : grey[400] }}
                            onClick={handleSend}
                            size='small'
                            sx={{
                                color: message.trim() ? 'primary.main' : 'action.disabled', // メッセージが入力されているかによって色を変える
                            }}
                        >
                            <SendIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }} />
    );
}

export const QuestionSuggestions = ({ onSend }) => {
    const { user } = useUser();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const questions = [
        "どんな会社ですか？",
        "主な事業内容を教えてください。",
        "新卒採用はありますか？",
        "インターンシップの機会はありますか？",
    ];

    return (
        <Box sx={{ mr: 1 }}>
            {user.userType !== 'company' && (
                <Stack
                    spacing={1}
                    direction={isSm ? 'row' : { xs: 'row' }}
                    sx={{
                        mb: 2,
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap',
                        flexDirection: isSm ? 'row' : { xs: "row" }
                    }}
                >
                    {questions.map((question, index) => (
                        <Button
                            variant="outlined"
                            key={question}
                            onClick={() => onSend(question)}
                            sx={{ borderRadius: '25px' }}
                            className={index === 0 && 'first-question-btn'}
                        >
                            {question}
                        </Button>
                    ))}
                </Stack>
            )}
        </Box>
    );
};
