export function getFirebaseErrorMessage({defaultErrorMessage, errorCode}) {
    switch (errorCode) {
        // createUserWithEmailAndPassword method
        case 'auth/email-already-in-use':
            return '指定されたメールアドレスを持つアカウントが既に存在します。';
        case 'auth/operation-not-allowed':
            return 'メール/パスワード アカウントが有効でないです。Firebase コンソールで有効にしてください。';
        case 'auth/weak-password':
            return 'パスワードが十分に強くありません。';

        // signInWithEmailAndPassword method
        case 'auth/user-disabled':
            return 'このユーザーは無効になっています。';
        case 'auth/user-not-found':
            return 'ユーザーが見つかりません。アカウントが存在しない場合は、新規登録をしてください。';
        case 'auth/wrong-password':
            return 'パスワードが間違っています。';

        // All methods
        case 'auth/invalid-email':
            return '無効なメールアドレスです。';
        default:
            return defaultErrorMessage;
    }
}