import React from 'react';
import { useSnackbar } from '../../Context/SnackbarContext';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = () => {
    const { snackbar, closeSnackbar } = useSnackbar();

    return (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <Alert onClose={closeSnackbar} severity={snackbar.type}>
                {snackbar.title && <AlertTitle>{snackbar.title}</AlertTitle>}
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
};

export default CustomSnackbar;
