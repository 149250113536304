// React and Router imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Firebase imports
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../firebase";

// Material-UI imports
import { Typography, Box, Paper, Divider, FormGroup, Grid, Container, Button, Slider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SchoolIcon from '@mui/icons-material/School';
import ExploreIcon from '@mui/icons-material/Explore';
import WcIcon from '@mui/icons-material/Wc';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WorkIcon from '@mui/icons-material/Work';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';

// Local component imports
import NavigationBar from '../../components/Layout/NavigationBar';
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';
import SectionWithCheckboxes from '../../components/Company/UserSearch/SectionWithCheckboxes';
import CheckboxControl from '../../components/Company/UserSearch/CheckboxControl';
import IconTypography from '../../components/Company/UserSearch/IconTypography';
import DataTable from '../../components/Company/UserSearch/DataTable';
import Footer from '../../components/Layout/Footer';

// Utility imports
import {
    ASPIRATIONS_OPTIONS,
    GRADE_OPTIONS,
    GENDER_OPTIONS,
    INTERESTING_WORK_CATEGORY_OPTIONS,
    INTERESTING_LEARN_CATEGORY_OPTIONS,
    EDUCATION_OPTIONS
} from '../../utils/options';
import { fetchUserSearchAPI } from '../../components/Company/UserSearch/fetchUserSearchAPI';

const UserSearch = () => {
    const [companyInfo, setCompanyInfo] = useState({});
    const [searchParams, setSearchParams] = useState({
        companyBookMarks: false,
        userIconUrl: false,
        aspirations: [],
        profile: false,
        catchphrase: false,
        goals: false,
        awards: false,
        certifications: false,
        parentalConsent: false,
        interestsEducationTags: [],
        interestsEmploymentTags: [],
        gender: [],
        grade: [],
        userType: [],
        ageRange: [14, 30]
    })
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const { id: companyId } = useParams();
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [scoutedUsers, setScoutedUsers] = useState([]);
    const [planName, setPlanName] = useState('スタンダードプラン');

    const checkIfUserIsScouted = async (companyId) => {
        const metaRef = doc(firestore, 'companies', companyId, 'meta', 'scoutedUsers');
        const metaDoc = await getDoc(metaRef);
        if (metaDoc.exists()) {
            return metaDoc.data().scoutedUsers;
        } else {
            return [];
        }
    };

    const handleSubmit = async () => {
        await fetchUserSearchAPI(companyId, searchParams, itemsPerPage, page, setUsers, setTotalUsers);
        checkScoutedUsers(users, scoutedUsers, setUsers);
    };

    const fetchScoutedUsers = async () => {
        const fetchedScoutedUsers = await checkIfUserIsScouted(companyId);
        setScoutedUsers(fetchedScoutedUsers);
    };

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;

        setSearchParams((prev) => {
            // 配列に対する操作を行います。
            const existingValues = Array.isArray(prev[name]) ? prev[name] : [];
            // チェックが入れられたら値を追加、チェックが外れたら値を削除
            let updatedValues;
            if (checked) {
                updatedValues = [...existingValues, value];
            } else {
                updatedValues = existingValues.filter((item) => item !== value);
            }
            // 更新された値を返す
            return {
                ...prev,
                [name]: updatedValues,
            };
        });
    };

    const handleSingleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setSearchParams(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handlePageChange = async (newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setItemsPerPage(newRowsPerPage);
        setPage(1);
        // handleSubmit();
    };

    // fetch data from Firestore
    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const docRef = doc(firestore, "companies", companyId);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const id = docSnap.id;
                    setCompanyInfo({ id, ...docSnap.data() });
                }
            } catch (error) {
                console.error("Error getting document:", error);
                // エラーハンドリングを行う
            }
        };
        if (companyId) {
            fetchCompanyInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);
    
    useEffect(() => {
        fetchScoutedUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    useEffect(() => {
        checkScoutedUsers(users, scoutedUsers, setUsers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scoutedUsers, users.length]); // Reacts to changes in scoutedUsers

    useEffect(() => {
        // whenever the page changes.
        const fetchData = async () => {
            await fetchScoutedUsers();
            await handleSubmit();
            await checkScoutedUsers(users, scoutedUsers, setUsers);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        if (companyInfo?.planPrice === 'price_1PAUU6GBe8AZCWiIPqYf1X2N') {
            // トライアルプラン
            setPlanName('トライアルプラン');
        } else if (companyInfo?.planPrice === 'price_1PAUWzGBe8AZCWiI1Q6J9Q2v') {
            // スタンダードプラン
            setPlanName('スタンダードプラン');
        } else {
            // 未定義
            setPlanName('未定義');
        }
    }, [companyInfo]);

    return (
        <>
            <NavigationBar />
            <Container maxWidth="xl" sx={{ mt: 15 }}>
                <CompanyTopNavigationContent company={companyInfo} />

                <Paper component="form" sx={{ p: 2, mt: 4 }} elevation={3}>
                    <Typography variant="h5" component="div" fontWeight={'bold'} my={1} mb={2}>
                        ユーザー検索
                    </Typography>
                    <SectionWithCheckboxes title="ユーザータイプ" icon={PersonIcon} options={EDUCATION_OPTIONS} checkedValues={searchParams.userType} onChange={handleCheckboxChange} name="userType" />
                    <Divider />
                    <SectionWithCheckboxes title="学年" icon={SchoolIcon} options={GRADE_OPTIONS} checkedValues={searchParams.grade} onChange={handleCheckboxChange} name="grade" />
                    <Divider />
                    <SectionWithCheckboxes title="性別" icon={WcIcon} options={GENDER_OPTIONS} checkedValues={searchParams.gender} onChange={handleCheckboxChange} name="gender" />
                    <Divider />
                    <SectionWithCheckboxes title="進路" icon={ExploreIcon} options={ASPIRATIONS_OPTIONS} checkedValues={searchParams.aspirations} onChange={handleCheckboxChange} name="aspirations" />
                    <Divider />
                    <SectionWithCheckboxes title="学びたいこと" icon={LightbulbIcon} options={INTERESTING_LEARN_CATEGORY_OPTIONS} checkedValues={searchParams.interestsEducationTags} onChange={handleCheckboxChange} name="interestsEducationTags" />
                    <Divider />
                    <SectionWithCheckboxes title="働きたい分野" icon={WorkIcon} options={INTERESTING_WORK_CATEGORY_OPTIONS} checkedValues={searchParams.interestsEmploymentTags} onChange={handleCheckboxChange} name="interestsEmploymentTags" />
                    <Divider />

                    <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={3}>
                                <IconTypography icon={CakeIcon}>年齢</IconTypography>
                            </Grid>
                            <Grid item xs={12} sm={9} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Slider
                                    value={searchParams.ageRange}
                                    onChange={(event, newValue) => {
                                        setSearchParams(prevParams => ({
                                            ...prevParams,
                                            ageRange: newValue
                                        }));
                                    }}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="age-range-slider"
                                    min={14}  // 最小値
                                    max={80} // 最大値
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />

                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <IconTypography icon={StarBorderIcon}>こだわり条件</IconTypography>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <FormGroup>
                                        <Grid container spacing={0}>
                                            <CheckboxControl label="ブックマーク済み" checked={searchParams.companyBookMarks} onChange={handleSingleCheckboxChange} name="companyBookMarks" />
                                            <CheckboxControl label="アイコンあり" checked={searchParams.userIconUrl} onChange={handleSingleCheckboxChange} name="userIconUrl" />
                                            <CheckboxControl label="自己紹介あり" checked={searchParams.profile} onChange={handleSingleCheckboxChange} name="profile" />
                                            <CheckboxControl label="キャッチフレーズあり" checked={searchParams.catchphrase} onChange={handleSingleCheckboxChange} name="catchphrase" />
                                            <CheckboxControl label="将来の目標設定あり" checked={searchParams.goals} onChange={handleSingleCheckboxChange} name="goals" />
                                            <CheckboxControl label="受賞歴あり" checked={searchParams.awards} onChange={handleSingleCheckboxChange} name="awards" />
                                            <CheckboxControl label="資格あり" checked={searchParams.certifications} onChange={handleSingleCheckboxChange} name="certifications" />
                                            <CheckboxControl label="保護者同意あり" checked={searchParams.parentalConsent} onChange={handleSingleCheckboxChange} name="parentalConsent" />
                                        </Grid>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                            <Button fullWidth variant="contained" startIcon={<SearchIcon sx={{ fontSize: '2rem' }} />} sx={{ fontSize: '1.25rem', fontWeight: 'bold' }} disabled={planName !== 'スタンダードプラン'}
                                onClick={handleSubmit}>
                                検索
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Typography variant="h6" gutterBottom component="div" mt={8} fontWeight={'bold'}>
                    検索結果
                </Typography>

                <Box mb={6}>
                    {
                        users.length > 0 ? (
                            <>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography variant="body1" gutterBottom>
                                        検索結果：{totalUsers} 件
                                    </Typography>
                                    {/* スカウト件数を表示 */}
                                    <Typography variant="body1" gutterBottom sx={{ ml: 2 }}>
                                        スカウト件数：{scoutedUsers.length} 件
                                    </Typography>
                                </Box>
                                {/* <DataTable users={users} /> */}
                                <DataTable
                                    users={users}
                                    setUsers={setUsers}
                                    page={page}
                                    totalUsers={totalUsers}
                                    onChangePage={handlePageChange}
                                    itemsPerPage={itemsPerPage}
                                    onChangeRowsPerPage={handleRowsPerPageChange}
                                    companyId={companyId}
                                    planName={planName}
                                />
                            </>
                        ) : (
                            <Typography variant="body1" gutterBottom>
                                検索結果がありません
                            </Typography>
                        )
                    }
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default UserSearch;

const checkScoutedUsers = async(users, scoutedUsers, setUsers) => {
    if (users.length > 0 && scoutedUsers) {
        setUsers(prevUsers => prevUsers.map(user => ({
            ...user,
            isScouted: scoutedUsers.includes(user.id.raw)
        }))
        );
    }
}

