// React とその Hooks
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material-UI Components & Icons
import { Box, Typography, Button, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Timeline,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
    TimelineItem,
    timelineOppositeContentClasses,
    timelineItemClasses
} from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';

// Firebase
import { firestore } from '../firebase';
import {
    doc,
    collection,
    addDoc,
    updateDoc,
    serverTimestamp,
    getDocs,
    query,
    where,
    orderBy,
    getDoc
} from 'firebase/firestore';

// Custom Components, Contexts, and Utilities
import { useUser } from '../Context/UserContext';
import { useSnackbar } from '../Context/SnackbarContext';
import ResponsiveContainer from '../components/Layout/ResponsiveContainer';
import SaveOnBlurTextField from '../components/Input/SaveOnBlurTextField';
import ProfileTopNavigationContent from '../components/Profile/ProfileTopNavigationContent';
import CustomTimeline from '../components/Profile/CustomTimeline';
import HighlightCardModal from '../components/Profile/HighlightCardModal';
import HighlightCardEditModal from '../components/Profile/HighlightCardEditModal';
import HistoryEditModal from '../components/Profile/HistoryEditModal';
import CertificationOrAward from '../components/Profile/CertificationOrAward';
import NavigationBar from '../components/Layout/NavigationBar';
import Footer from '../components/Layout/Footer';

// External Libraries
import { v4 as uuidv4 } from 'uuid';


const fetchUserHistory = async (userId) => {
    const historyRef = collection(firestore, "users", userId, "history");
    const q = query(historyRef, where("isDisabled", "==", false), orderBy("endedAt", "desc")); // 例: アクティブな履歴のみ取得
    const querySnapshot = await getDocs(q);
    const historyItems = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
    }));
    return historyItems;
};

const saveToFirestore = async (documentId, fieldName, fieldValue) => {
    const docRef = doc(firestore, 'users', documentId);
    try {
        const updates = {
            [fieldName]: fieldValue
        };

        // catchphraseが更新される場合、hasCatchphraseも適切に更新する
        if (fieldName === 'catchphrase') {
            updates.hasCatchphrase = !!fieldValue && fieldValue.trim() !== ''; // 空文字または未定義でない確認
        }
        if (fieldName === 'profile') {
            updates.hasProfile = !!fieldValue && fieldValue.trim() !== ''; // 空文字または未定義でない確認
        }
        if (fieldName === 'goals') {
            updates.hasGoals = !!fieldValue && fieldValue.trim() !== ''; // 空文字または未定義でない確認
        }

        await updateDoc(docRef, updates);
    } catch (error) {
        console.error('Error updating document: ', error);
    }
};



const Profile = () => {
    const [timelineItems, setTimelineItems] = useState([]);
    const [modalState, setModalState] = useState({
        open: false, // モーダルが開いているかどうか
        data: null, // モーダルに渡すデータ
        type: null, // モーダルのタイプ（'edit', 'add', 'view' など）
    });
    const [user, setUser] = useState(null);

    const { user: loginUser } = useUser();
    const { showSnackbar } = useSnackbar();
    const { id: userId } = useParams();

    const isUser = loginUser?.uid === userId;

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const fetchUser = async () => {
            const userDocRef = doc(firestore, "users", userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                setUser({ documentId: userDoc.id, ...userDoc.data() });
            }
        };
        if (userId === loginUser?.uid) {
            setUser(loginUser);
        } else {
            fetchUser();
        }
    }, [userId, loginUser]);

    useEffect(() => {
        const getHistory = async () => {
            const historyItems = await fetchUserHistory(user.documentId);
            setTimelineItems(historyItems);
        };
        if (user?.documentId) {
            getHistory();
        }
    }, [user]);

    // モーダルを開く関数
    const handleOpenModal = (data = {}, type = 'view', category) => {
        const initialData = {
            type: 'education',
            organization: '',
            position: '',
            employeeType: '',
            description: '',
            major: '',
            learned: '',
            startedAt: '',
            endedAt: '',
            isCurrent: false,
            isDisabled: false,
        };

        if (Object.keys(data).length === 0 && type === 'history') {
            data = initialData;
        }
        setModalState({ open: true, data, type, category });
    };

    // モーダルを閉じる関数
    const handleCloseModal = () => {
        setModalState({ open: false, data: null, type: null });
    };

    // 職歴と学歴のデータをFirestoreに保存する関数
    const handleSaveHistoryData = async (data, userDocId) => {
        try {
            // 特定のユーザーのヒストリーサブコレクションに新しいドキュメントを追加
            await addDoc(collection(firestore, "users", userDocId, "history"), {
                ...data,
                createdAt: serverTimestamp(), // 現在の日時を作成日として設定
                updatedAt: serverTimestamp(), // 現在の日時を更新日として設定
            });
            showSnackbar({ type: 'success', message: '保存しました' });
            handleCloseModal(); // モーダルを閉じる
        } catch (error) {
            showSnackbar({ type: 'error', message: '保存に失敗しました' });
        }
    };

    // 資格や受賞歴のデータをFirestoreに保存する関数
    const handleSaveCertificationOrAward = async (data, userDocId, category) => {
        const docRef = doc(firestore, "users", userDocId);
        try {
            const userDoc = await getDoc(docRef);
            let itemsArray = [];

            if (userDoc.exists()) {
                const userData = userDoc.data();
                itemsArray = userData[category] ? [...userData[category]] : [];
            }

            // 更新または新規追加のロジック
            if (data.id) {
                // 更新処理: IDが存在する場合、対象のデータを更新
                const index = itemsArray.findIndex(item => item.id === data.id);
                if (index !== -1) {
                    itemsArray[index] = { ...data };
                }
            } else {
                // 新規追加処理: IDがない場合、新しいIDを生成してデータを追加
                const newData = { ...data, id: uuidv4(), addedAt: new Date().toISOString() };
                itemsArray.push(newData);
            }

            // 更新オブジェクトの準備
            let updateObject = { [category]: itemsArray, updatedAt: serverTimestamp() };
            updateObject[`has${category.charAt(0).toUpperCase() + category.slice(1)}`] = itemsArray.length > 0;

            // Firestoreのドキュメントを更新
            await updateDoc(docRef, updateObject);
            showSnackbar({ type: 'success', message: '保存しました' });
            handleCloseModal(); // モーダルを閉じる
        } catch (error) {
            showSnackbar({ type: 'error', message: '保存に失敗しました' });
        }
    }

    // 資格や受賞歴のデータをFirestoreから削除する関数
    const handleDeleteCertificationOrAward = async (itemId, userDocId, category) => {
        const docRef = doc(firestore, "users", userDocId);
        try {
            const userDoc = await getDoc(docRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const itemsArray = userData[category] ? [...userData[category]] : [];

                // 削除する項目を配列から探し、除外する
                const updatedItemsArray = itemsArray.filter(item => item.id !== itemId);

                // Firestoreのドキュメントを更新

                let updateObject = {
                    [category]: updatedItemsArray,
                    updatedAt: serverTimestamp(),
                    [`has${category.charAt(0).toUpperCase() + category.slice(1)}`]: updatedItemsArray.length > 0
                };
                await updateDoc(docRef, updateObject);
                showSnackbar({ type: 'success', message: '削除しました' });
                handleCloseModal(); // モーダルを閉じる
            }
        } catch (error) {
            showSnackbar({ type: 'error', message: '削除に失敗しました' });
        }
    };

    const getInitialValue = (user, field, defaultValue) => {
        if (user && user[field] && user[field].trim() !== '') {
            return user[field];
        } else {
            return defaultValue;
        }
    }

    return (
        <>
            <NavigationBar />
            <ProfileTopNavigationContent user={user} />
            <ResponsiveContainer>
                {isUser ? (
                    <SaveOnBlurTextField
                        initialValue={getInitialValue(user, 'catchphrase', 'キャッチコピーや好きな言葉')}
                        onSave={(value) => saveToFirestore(user?.documentId, 'catchphrase', value)}
                        TypographyProps={{
                            variant: "h3",
                            sx: { fontWeight: "bold" }
                        }}
                        isDisabled={!isUser}
                    />
                ) : (
                    <Typography variant="h3" sx={{ fontWeight: 'bold', padding: 2 }}>
                        {user?.catchphrase || '未入力'}
                    </Typography>
                )}
                {isUser ? (
                    <SaveOnBlurTextField
                        initialValue={getInitialValue(user, 'profile', '自己紹介')}
                        onSave={(value) => saveToFirestore(user?.documentId, 'profile', value)}
                        TypographyProps={{
                            variant: "body1",
                            sx: { mt: 2 }
                        }}
                        multiline={true}
                        initialRows={4}
                        greyBackground={true}
                        isDisabled={!isUser}
                    />
                ) : (
                    <Typography variant="body1" sx={{ mt: 2, p: 2, }}>
                        {user?.profile || '自己紹介が未入力'}
                    </Typography>
                )}

                <Timeline sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0,
                        padding: 0,
                        paddingX: 2,
                    },
                    mt: 5
                }}>
                    {/* これからやりたいことを記載するボックス */}
                    <TimelineItem sx={{ borderBottom: '1px dashed #ccc' }}>
                        {isSm && (
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} variant="body2" color="white">
                                2024/03<br />
                                ~2023/10
                            </TimelineOppositeContent>
                        )}
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot>
                                <UpdateIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', }}>
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Typography variant="h5" component="h5" sx={{ fontWeight: 'bold' }}>
                                    この先やってみたいこと
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{}}>
                                {isUser ? (
                                    <SaveOnBlurTextField
                                        initialValue={getInitialValue(user, 'goals', '(例)地域社会への貢献: 地域の建物や施設の設計や改修プロジェクトに参加し、地域社会に貢献する。')}
                                        onSave={(value) => saveToFirestore(user?.documentId, 'goals', value)}
                                        TypographyProps={{
                                            variant: "body1",
                                            sx: {}
                                        }}
                                        multiline={true}
                                        rows={4}
                                        maxRows={6}
                                        greyBackground={true}
                                        isDisabled={!isUser}
                                    />
                                ) : (
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {user?.goals || 'この先やってみたいことが未入力です'}
                                    </Typography>
                                )}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    {/* それ以外の経歴 */}
                    {isUser && (
                        <Box display="flex" alignItems="center" justifyContent='center' sx={{ mt: -2 }}>
                            <Button
                                onClick={() => { handleOpenModal({}, 'history') }}
                                color="primary"
                                size='small'
                                startIcon={<AddCircleOutlineIcon />}
                                variant="contained" // ボタンに背景色をつける
                            >
                                職歴・学歴の追加
                            </Button>
                        </Box>
                    )}

                    <CustomTimeline items={timelineItems} isDisabled={!isUser} />
                </Timeline>
            </ResponsiveContainer>

            <ResponsiveContainer>
                <CertificationOrAward
                    title="資格"
                    items={user?.certifications || []}
                    onAdd={() => handleOpenModal({}, 'add', 'certifications')}
                    onEdit={(item) => handleOpenModal(item, 'edit', 'certifications')}
                    isUser={isUser}
                />
                <CertificationOrAward
                    title="受賞"
                    items={user?.awards || []}
                    onAdd={() => handleOpenModal({}, 'add', 'awards')}
                    onEdit={(item) => handleOpenModal(item, 'edit', 'awards')}
                    isUser={isUser}
                />
            </ResponsiveContainer>

            <Footer />

            <HistoryEditModal
                open={modalState.type === 'history' && modalState.open}
                onClose={handleCloseModal}
                onSave={(data) => handleSaveHistoryData(data, user?.documentId)} // 保存関数をモーダルに渡す
                initialData={modalState.data} // 編集するデータをモーダル渡す
            />

            <HighlightCardModal
                open={modalState.type === 'edit' && modalState.open}
                handleClose={handleCloseModal}
                onSave={(data) => handleSaveCertificationOrAward(data, user?.documentId, modalState.category)}
                onDelete={(id) => handleDeleteCertificationOrAward(id, user?.documentId, modalState.category)}
                data={modalState.data}
                category={modalState.category}
                isDisabled={isUser}
            />

            <HighlightCardEditModal
                openEdit={modalState.type === 'add' && modalState.open}
                handleClose={handleCloseModal}
                onSave={(data) => handleSaveCertificationOrAward(data, user?.documentId, modalState.category)}
                onDelete={(id) => handleDeleteCertificationOrAward(id, user?.documentId, modalState.category)}
                data={modalState.data}
                modalType="新規追加"
                category={modalState.category}
            />


        </>
    );
}

export default Profile;

