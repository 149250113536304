export const getRoleDescription = (roleValue) => {
    switch (roleValue) {
        case 'admin':
            return '管理者';
        case 'editor':
            return '企業情報の編集ができるユーザー';
        case 'chat_user':
            return 'チャットまでできるユーザー';
        case 'viewer':
            return '閲覧までできるユーザー';
        default:
            return '未定義の役割';
    }
}
