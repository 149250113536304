import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter } from '@mui/material';

import { firestore } from '../../../../firebase';
import { collection, getDocs, query, limit, orderBy, startAfter, where } from 'firebase/firestore';

import TablePaginationActions from '../../../../components/Layout/TablePaginationActions';
import Row from './Row';

function getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies) {
    return async () => {
        const companiesRef = collection(firestore, 'companies');
        let q;

        if (page === 0) { // 最初のページ
            q = query(companiesRef, where("isDeleted", "==", true), orderBy('name'), limit(rowsPerPage));
        } else { // それ以外のページ
            const lastDoc = pageDocSnapshots[page - 1];
            q = query(companiesRef, where("isDeleted", "==", true), orderBy('name'), startAfter(lastDoc), limit(rowsPerPage));
        }

        const documentSnapshots = await getDocs(q);
        const docs = documentSnapshots.docs;
        const companiesData = docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const newPageDocSnapshots = [...pageDocSnapshots];
        newPageDocSnapshots[page] = docs[docs.length - 1];
        setPageDocSnapshots(newPageDocSnapshots);

        setCompanies(companiesData);
    };
}

const DeletedCompaniesTable = () => {
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [total, setTotal] = useState(0); // コレクション内のドキュメントの総数
    const [pageDocSnapshots, setPageDocSnapshots] = useState([null]); // ページごとのドキュメントスナップショットを保存


    useEffect(() => {
        // 総数を取得
        const fetchTotal = async () => {
            const q = query(collection(firestore, 'companies'), where("isDeleted", "==", true));
            const querySnapshot = await getDocs(q);
            setTotal(querySnapshot.docs.length);
        };

        fetchTotal();
    }, []);

    useEffect(() => {
        const fetchCompanies = getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies);

        fetchCompanies();
    }, [page, rowsPerPage, pageDocSnapshots]);

    // ページネーションのハンドラ
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // 行数が変更されたら最初のページに戻る
    };

    return (
        <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>名前</TableCell>
                            <TableCell>ウェブサイトURL</TableCell>
                            <TableCell align="right">作成日</TableCell>
                            <TableCell align="right">削除日</TableCell>
                            <TableCell align="center">復元</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies.map((company) => (
                            <Row key={company.id} row={company} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                count={total || -1} // 総数がわからない場合は-1
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                ActionsComponent={TablePaginationActions}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
    );
}

export default DeletedCompaniesTable;