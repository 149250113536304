import React from 'react';
import { Grid, Box, Stack, Alert, AlertTitle } from '@mui/material';
import Message from './Message';
import CircularProgress from '@mui/material/CircularProgress';
import { QuestionSuggestions, MessageInputField } from './MessageInputField';
import { ScrollTop } from './ScrollTop';

export function smChatComponent(messages, user, isLoadingAiResponse, scrollRef, showArchivedChats, sendMessage, markChatAsDeleted, userIcons) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 147px)' }}>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, WebkitOverflowScrolling: 'touch', }}>
                <Stack spacing={2}>
                    {messages?.map((message) => (
                        <Message key={message.id} message={message} user={user} userIcons={userIcons}/>
                    ))}
                    {isLoadingAiResponse && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {/* スクロールのためのダミー要素 */}
                    <div ref={scrollRef} />
                </Stack>
            </Box>

            {/* 送信バー */}
            {!showArchivedChats && messages.length === 0 && (
                <QuestionSuggestions onSend={sendMessage} />
            )}
            {!showArchivedChats ? (
                <Box sx={{ px: 1, pt: 0.5, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%' }}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={true}>
                            <MessageInputField onSend={sendMessage} markChatAsDeleted={markChatAsDeleted} />
                        </Grid>
                    </Grid>
                </Box>
            ) : (
                <Box sx={{ p: 1, display: 'flex', alignItems: 'center', mt: 'auto', width: '100%', justifyContent: 'center' }}>
                    <Alert variant="filled" severity="error">
                        <AlertTitle>アーカイブされたチャットルームです</AlertTitle>
                    </Alert>
                </Box>
            )}
            <ScrollTop />
        </Box>
    );
}
