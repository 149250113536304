// getImageUrl.js
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

export const getImageUrl = async (imagePath) => {
    try {
        const imageRef = ref(storage, imagePath);
        const url = await getDownloadURL(imageRef);
        return url;
    } catch (error) {
        // console.error("Error getting image URL: ", error);
        return ''; // 画像が見つからない場合やエラーが発生した場合は空の文字列を返します
    }
};
