// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableFooter } from '@mui/material';

// import { firestore } from '../../../../firebase';
// import { collection, getDocs, query, limit, orderBy, startAfter, where } from 'firebase/firestore';

// import TablePaginationActions from '../../../../components/Layout/TablePaginationActions';
// import Row from './Row';

// function getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies) {
//     return async () => {
//         const companiesRef = collection(firestore, 'companies');
//         let q;

//         if (page === 0) { // 最初のページ
//             q = query(companiesRef, where("isDeleted", "==", false), orderBy('name'), limit(rowsPerPage));
//         } else { // それ以外のページ
//             const lastDoc = pageDocSnapshots[page - 1];
//             q = query(companiesRef, where("isDeleted", "==", false), orderBy('name'), startAfter(lastDoc), limit(rowsPerPage));
//         }

//         const documentSnapshots = await getDocs(q);
//         const docs = documentSnapshots.docs;
//         const companiesData = docs.map(doc => ({ id: doc.id, ...doc.data() }));

//         const newPageDocSnapshots = [...pageDocSnapshots];
//         newPageDocSnapshots[page] = docs[docs.length - 1];
//         setPageDocSnapshots(newPageDocSnapshots);

//         setCompanies(companiesData);
//     };
// }


// const CompaniesTable = () => {
//     const [companies, setCompanies] = useState([]);
//     const [page, setPage] = useState(0);
//     const [rowsPerPage, setRowsPerPage] = useState(5);
//     const [total, setTotal] = useState(0); // コレクション内のドキュメントの総数
//     const [pageDocSnapshots, setPageDocSnapshots] = useState([null]); // ページごとのドキュメントスナップショットを保存


//     useEffect(() => {
//         // 総数を取得（削除フラグが立っていないもののみ）
//         const fetchTotal = async () => {
//             const q = query(collection(firestore, 'companies'), where("isDeleted", "==", false));
//             const querySnapshot = await getDocs(q);
//             setTotal(querySnapshot.docs.length);
//         };
    
//         fetchTotal();
//     }, []);
    

//     useEffect(() => {
//         const fetchCompanies = getCompaniesFromFirestore(page, rowsPerPage, pageDocSnapshots, setPageDocSnapshots, setCompanies);

//         fetchCompanies();
//     }, [page, rowsPerPage, pageDocSnapshots]);

//     // ページネーションのハンドラ
//     const handleChangePage = (event, newPage) => {
//         setPage(newPage);
//     };

//     const handleChangeRowsPerPage = (event) => {
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0); // 行数が変更されたら最初のページに戻る
//     };

//     return (
//         <TableContainer component={Paper}>
//                 <Table aria-label="collapsible table">
//                     <TableHead>
//                         <TableRow>
//                             <TableCell />
//                             <TableCell align='center'>サムネイル</TableCell>
//                             <TableCell>名前</TableCell>
//                             <TableCell>ウェブサイトURL</TableCell>
//                             <TableCell align="right">チャンクサイズ</TableCell>
//                             <TableCell align="right">作成日</TableCell>
//                             <TableCell align="center">編集</TableCell>
//                             <TableCell align="center">ユーザー追加</TableCell>
//                             <TableCell align="center">削除</TableCell>
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {companies.map((company) => (
//                             <Row key={company.id} row={company} />
//                         ))}
//                     </TableBody>
//                     <TableFooter>
//                         <TableRow>
//                             <TablePagination
//                                 rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//                                 count={total || -1} // 総数がわからない場合は-1
//                                 rowsPerPage={rowsPerPage}
//                                 page={page}
//                                 SelectProps={{
//                                     inputProps: { 'aria-label': 'rows per page' },
//                                     native: true,
//                                 }}
//                                 ActionsComponent={TablePaginationActions}
//                                 onPageChange={handleChangePage}
//                                 onRowsPerPageChange={handleChangeRowsPerPage}
//                             />
//                         </TableRow>
//                     </TableFooter>
//                 </Table>
//             </TableContainer>
//     );
// }

// export default CompaniesTable;
import React, { useState, useEffect, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress } from '@mui/material';
import { firestore } from '../../../../firebase';
import { collection, getDocs, orderBy, query, limit, startAfter } from 'firebase/firestore';
import Row from './Row';

const PAGE_SIZE = 10;  // 1ページあたりのデータ数

const CompanyTable = () => {
    const [companies, setCompanies] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchCompanies = useCallback(async (startAfterDoc = null) => {
        setLoading(true);
        const companiesRef = collection(firestore, 'companies');
        let q = query(companiesRef, orderBy('name'), limit(PAGE_SIZE));
        if (startAfterDoc) {
            q = query(companiesRef, orderBy('name'), startAfter(startAfterDoc), limit(PAGE_SIZE));
        }
        const querySnapshot = await getDocs(q);
        const companiesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setCompanies(prevCompanies => [...prevCompanies, ...companiesData]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLoading(false);
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell align="center">サムネイル</TableCell>
                        <TableCell>名前</TableCell>
                        <TableCell>ウェブサイトURL</TableCell>
                        <TableCell align="right">Chunk数</TableCell>
                        <TableCell align="right">作成日</TableCell>
                        <TableCell align="center">アクション</TableCell>
                        <TableCell align="center">アクション</TableCell>
                        <TableCell align="center">アクション</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company) => (
                        <Row key={company.id} row={company} />
                    ))}
                    {loading && (
                        <TableRow>
                            <TableCell colSpan={9} align="center">
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {!loading && lastVisible && (
                <Button onClick={() => fetchCompanies(lastVisible)}>Load More</Button>
            )}
        </TableContainer>
    );
};

export default CompanyTable;