import React, { useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import { Dialog, DialogContent, Typography, Fade } from '@mui/material';

const FinishDialog = ({ open, onClose }) => {
    const [animation, setAnimation] = useState('/cracker.json');
    const [textVisible, setTextVisible] = useState(false);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogContent
                sx={{
                    position: 'relative',
                    height: '300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent'
                }}
            >
                <Fade in={textVisible} timeout={500}>
                    <Typography
                        variant="h5"
                        sx={{
                            // fontWeight: 'bold',
                            position: 'absolute',
                            zIndex: 1,
                            textAlign: 'center',
                            
                        }}
                    >
                        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>ツアーが終了しました</Typography>
                        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>AIの回答を見よう</Typography>
                    </Typography>
                </Fade>
                <Player
                    autoplay
                    src={animation}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 0
                    }}
                    onEvent={(event) => {
                        if (event === 'complete') {
                            setAnimation('/confetti.json');
                            setTextVisible(true); // テキストを表示するためにstateを更新
                        }
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default FinishDialog;
