import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableRow, Collapse, IconButton, Box, Typography, Button, Stack } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TuneIcon from '@mui/icons-material/Tune';

import { useSnackbar } from '../../../../Context/SnackbarContext';

import { useCallApi } from '../../../../Hooks/useCallApi';
import { firestore } from '../../../../firebase';
import { doc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
import { DELETE_BUCKET_FILES, SCRAPE_AND_SAVE_TO_STORAGE, FETCH_DIVIDE_AND_STORE_CHUNKS, CREATE_INDEX_FROM_CHUNKS } from './../../../../utils/apiEndpoint';

const Row = ({ row }) => {
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    const callApi = useCallApi();
    // 日付をフォーマットする関数
    const formatDate = (date) => {
        return date ? new Date(date.seconds * 1000).toISOString().split('T')[0] : 'N/A';
    };

    // データ移動の関数
    const moveDataToDisplayCollection = async () => {
        const companyRef = doc(firestore, `preProcessCompanies/${row.id}`);
        const docSnapshot = await getDoc(companyRef);

        if (docSnapshot.exists) {
            // companiesコレクションにドキュメントをコピー
            const newCompanyRef = doc(firestore, `companies/${row.id}`);
            await setDoc(newCompanyRef, docSnapshot.data());

            // 元のドキュメントを削除
            await deleteDoc(companyRef);

            showSnackbar({ message: "データが移動されました", type: 'success' });
        } else {
            showSnackbar({ message: "移動するデータが見つかりません", type: 'error' });
        }
    };

    // データの種類に応じて削除するパスを指定
    const clearBucket = async (dataType) => {
        let file_path;
        switch (dataType) {
            case 'scrapeData':
                file_path = `plane_text/${row.id}`;
                break;
            case 'chunkData':
                file_path = `chunks/${row.id}`;
                break;
            case 'indexData':
                file_path = `embedding_index/${row.id}.json`;
                break;
            default:
                showSnackbar({ message: "不正なデータタイプが指定されました", type: 'error' });
                return;
        }

        // APIエンドポイントのURL
        // const url = `http://127.0.0.1:5001/ciel-dac23/asia-northeast1/delete_bucket_files`;
        const url = DELETE_BUCKET_FILES;
        const params = { file_path: file_path };

        // APIを呼び出してデータを削除
        const result = await callApi(url, params)

        if (result.success) {
            // 成功時の処理
            showSnackbar({ message: "削除に成功しました", type: 'success' });
        } else {
            showSnackbar({ message: `エラーが発生しました: ${result.error.message}`, type: 'error' });
        }
    };

    // const handleAction = async (actionType) => {
    //     const url = `http://127.0.0.1:5001/ciel-dac23/asia-northeast1/${actionType}`;
    //     const params = { company_id: row.id, ...(actionType === 'scrape_and_save_to_storage' && { company_url: row.websiteUrl }) };

    //     const result = await callApi(url, params);

    //     if (result.success) {
    //         // 成功時の処理
    //         showSnackbar({ message: `${actionType}が完了しました`, type: 'success' });
    //     } else {
    //         // エラー時の処理
    //         showSnackbar({ message: `エラーが発生しました: ${result.error.message}`, type: 'error' });
    //     }
    // };

    const handleAction = async (actionType) => {
        let url;
        let params;
        switch (actionType) {
            case 'scrape_and_save_to_storage':
                url = `${SCRAPE_AND_SAVE_TO_STORAGE}`;
                break;
            case 'fetch_divide_and_store_chunks':
                url = `${FETCH_DIVIDE_AND_STORE_CHUNKS}`;
                break;
            case 'create_index_from_chunks':
                url = CREATE_INDEX_FROM_CHUNKS;
                break;
            default:
                throw new Error(`Unknown action type: ${actionType}`);
        }
        params = { company_id: row.id, ...(actionType === 'scrape_and_save_to_storage' && { company_url: row.websiteUrl }) };

        const result = await callApi(url, params);

        if (result.success) {
            // 成功時の処理
            showSnackbar({ message: `${actionType}が完了しました`, type: 'success' });
        } else {
            // エラー時の処理
            showSnackbar({ message: `エラーが発生しました: ${result.error.message}`, type: 'error' });
        }
    };


    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row?.name}</TableCell>
                <TableCell>{row?.websiteUrl}</TableCell>
                <TableCell align="right">{row?.status}</TableCell>
                <TableCell align="right">{row?.error || 'N/A'}</TableCell>
                <TableCell align="right">{formatDate(row?.createdAt)}</TableCell>
                <TableCell align="right">{formatDate(row?.updatedAt)}</TableCell>
                <TableCell align="center">
                    <IconButton color="primary" disabled onClick={() => console.log('edit')}>
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">詳細情報</Typography>
                            <Table size="small" aria-label="details">
                                <TableBody>
                                    {/* ここに企業の詳細情報を追加 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">ID</TableCell>
                                        <TableCell align="right">{row?.id || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">企業名</TableCell>
                                        <TableCell align="right">{row?.name || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">ウェブサイトURL</TableCell>
                                        <TableCell align="right">{row?.websiteUrl || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">エラー</TableCell>
                                        <TableCell align="right">{row?.error || 'N/A'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">追加状況</TableCell>
                                        <TableCell align="right">{row?.status || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 作成日 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">作成日</TableCell>
                                        <TableCell align="right">{formatDate(row?.createdAt) || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 更新日 */}
                                    <TableRow>
                                        <TableCell component="th" scope="row">更新日</TableCell>
                                        <TableCell align="right">{formatDate(row?.updatedAt) || 'N/A'}</TableCell>
                                    </TableRow>
                                    {/* 他のフィールドも同様に追加 */}
                                </TableBody>
                            </Table>
                            <Stack spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                <Stack direction="row" spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                    {/* 既存のボタン */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={moveDataToDisplayCollection}
                                        disabled={!row.status === 'インデックス作成完了'} // 条件に応じてボタンを無効化
                                    >
                                        データ移動
                                    </Button>
                                </Stack>
                                <Stack direction="row" spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => clearBucket('scrapeData')} // スクレイピングデータの削除
                                        disabled={!((row.status === 'スクレイピング開始' || row.status.includes('スクレイピング失敗') || row.status.includes('一括処理開始')) && row.error)}
                                    >
                                        <DeleteIcon sx={{ marginRight: 1 }} />
                                        スクレイピングデータ削除
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => clearBucket('chunkData')} // チャンクデータの削除
                                        disabled={!((row.status.includes('スクレイピング完了') || row.status === 'チャンク作成開始' || row.status.includes('チャンク作成失敗')) && row.error)}
                                    >
                                        <PlaylistAddIcon sx={{ marginRight: 1 }} />
                                        チャンクデータ削除
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => clearBucket('indexData')} // インデックスデータの削除
                                        disabled={!((row.status === 'チャンク作成完了' || row.status === 'インデックス作成開始' || row.status.includes('インデックス作成失敗')) && row.error)}
                                    >
                                        <TuneIcon sx={{ marginRight: 1 }} />
                                        インデックスデータ削除
                                    </Button>
                                </Stack>
                                <Stack direction="row" spacing={2} sx={{ marginTop: 2, marginBottom: 2, justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleAction('scrape_and_save_to_storage')}
                                        disabled={!((row.status === 'スクレイピング開始' || row.status.includes('スクレイピング失敗') || row.status.includes('一括処理開始')) && row.error)} // スクレイピング失敗時にエラーメッセージがある場合に有効化
                                    >
                                        <SearchIcon sx={{ marginRight: 1 }} />
                                        スクレイピング
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleAction('fetch_divide_and_store_chunks')}
                                        disabled={!((row.status.includes('スクレイピング完了') || row.status === 'チャンク作成開始' || row.status.includes('チャンク作成失敗')) && row.error)} // チャンク作成失敗時にエラーメッセージがある場合に有効化
                                    >
                                        <PlaylistAddIcon sx={{ marginRight: 1 }} />
                                        チャンク作成
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleAction('create_index_from_chunks')}
                                        disabled={!((row.status === 'チャンク作成完了' || row.status === 'インデックス作成開始' || row.status.includes('インデックス作成失敗')) && row.error)} // インデックス作成失敗時にエラーメッセージがある場合に有効化
                                    >
                                        <TuneIcon sx={{ marginRight: 1 }} />
                                        インデックス作成
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row;