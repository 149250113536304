// React and hooks
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Material-UI components
import { Box, Divider, Typography, Container, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import NotesIcon from '@mui/icons-material/Notes';

// Firebase imports
import { firestore } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

// Utility functions
import { getImageUrl } from '../../utils/getImageUrl';

// Context hooks
import { useSnackbar } from '../../Context/SnackbarContext';
import { useCompany } from '../../Context/CompanyContext';
import { useUser } from '../../Context/UserContext';

// hooks
import useApi from '../../Hooks/useApi';

// Local components
import ResponsiveContainer from '../../components/Layout/ResponsiveContainer';
import NavigationBar from '../../components/Layout/NavigationBar';
import ValueTable from '../../components/Company/ValueTable';
import CompanyInfoList from '../../components/Company/CompanyInfoList';
import CompanyTopNavigationContent from '../../components/Company/CompanyTopNavigationContent';
import SaveOnBlurTextField from '../../components/Input/SaveOnBlurTextField';
import ImageUploadModal from '../../components/Input/ImageUploadModal';
import LeftBottomFloatBtn from '../../components/Company/LeftBottomFloatBtn';
import Footer from '../../components/Layout/Footer';
import { LoadingButton } from '@mui/lab';


// Firestoreに企業情報を更新する関数
const updateCompanyInfo = async (companyId, updatedData) => {
    const companyDocRef = doc(firestore, "companies", companyId);
    try {
        await updateDoc(companyDocRef, updatedData);
    } catch (error) {
        console.error("Error updating document: ", error);
    }
};

const initialFormData = {
    catchphrase: 'キャッチフレーズ',
    companyDetail: '会社概要',
    values: [
        { valueId: 1, description: '' },
        { valueId: 2, description: '' },
        { valueId: 3, description: '' },
    ]
};

const CompanyHome = () => {
    const [formData, setFormData] = useState(initialFormData);
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(initialFormData);
    const [imageUrl, setImageUrl] = useState('');
    const [planName, setPlanName] = useState('');
    const { id: companyId } = useParams();
    const { showSnackbar } = useSnackbar();
    const { company } = useCompany();
    const { user } = useUser();
    const { isLoading, error, postData } = useApi("https://create-ai-message-normal-frnrslgk3q-an.a.run.app");
    // ユーザーが企業ユーザーかつ役割がadminである場合にtrue、それ以外の場合はfalseを設定
    const hasEditPermission = user?.userType === 'company' && user?.companyId === companyId;

    // fetch company data from Firestore when companyId is changed
    useEffect(() => {
        const fetchCompanyInfo = async () => {
            const docRef = doc(firestore, "companies", companyId);
            try {
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const id = docSnap.id;
                    setCompanyInfo({ id, ...docSnap.data() });
                }
            } catch (error) {
                console.error("Error getting document:", error);
                // エラーハンドリングを行う
            }
        };

        if (companyId) {
            fetchCompanyInfo();
        }
    }, [companyId]);

    useEffect(() => {
        if (companyInfo) {
            const newFormData = {
                catchphrase: companyInfo.catchphrase || initialFormData.catchphrase,
                companyDetail: companyInfo.companyDetail || initialFormData.companyDetail,
                values: companyInfo.values || initialFormData.values,
            };
            setFormData(newFormData);
        }
    }, [companyInfo]);

    useEffect(() => {
        setFormData(company);
    }, [company]);

    // 画像のURLを取得
    useEffect(() => {
        const imagePath = `company_images/${companyId}/thumbnail_400x400.webp`;
        const defaultImageUrl = 'https://sus-shokunin.com/wordpress/wp-content/uploads/2016/08/noimage.jpg'; // デフォルト画像のパスを設定

        const fetchImageUrl = async () => {
            const url = await getImageUrl(imagePath);
            if (url) {
                setImageUrl(url); // 取得したURLを設定
            } else {
                setImageUrl(defaultImageUrl); // デフォルト画像のURLを設定
            }
        };

        fetchImageUrl();
    }, [companyId]);

    // formDataの変更を検知
    useEffect(() => {
        const isChanged = JSON.stringify(formData) !== JSON.stringify(initialFormData);
        setIsFormChanged(isChanged);
    }, [formData]);

    useEffect(() => {
        if (error) {
            showSnackbar({ message: error?.message, type: 'error', title: 'エラーが発生しました' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (companyInfo?.planPrice === 'price_1PAUU6GBe8AZCWiIPqYf1X2N') {
            // トライアルプラン
            setPlanName('トライアルプラン');
        } else if (companyInfo?.planPrice === 'price_1PAUWzGBe8AZCWiI1Q6J9Q2v') {
            // スタンダードプラン
            setPlanName('スタンダードプラン');
        } else {
            // 未定義
            setPlanName('未定義');
        }
    }, [companyInfo]);

    const handleChangeValues = ({ type, index, value }) => {
        setFormData(prevFormData => {
            // valuesが未定義の場合は空の配列を使用
            // const newValues = prevFormData.values ? [...prevFormData.values] : [];
            const newValues = prevFormData.values ? [...prevFormData.values] : initialFormData.values;

            // 新しい要素を追加する際に、両方のフィールドに適切な初期値を設定
            const defaultValue = { valueId: 'none', description: '' };

            if (newValues.length > index) {
                // 既存の要素を更新
                if (type === 'valueId') {
                    newValues[index] = { ...newValues[index], valueId: value };
                } else if (type === 'description') {
                    newValues[index] = { ...newValues[index], description: value };
                }
            } else {
                // 新しい要素を追加
                const newValue = { ...defaultValue, [type]: value };
                newValues.push(newValue);
            }

            return { ...prevFormData, values: newValues };
        });
    };

    // handleSubmit 関数での Firestore への更新処理
    const handleSubmit = async () => {
        if (!isFormChanged) {
            return;
        }

        await updateCompanyInfo(companyId, {
            ...formData,
            // その他更新したいフィールド
        });

        showSnackbar({ message: "企業情報を更新しました", type: 'success', title: '更新成功' });
    };

    // 企業HOMEの画像カードクリック時の処理
    const handleCardClick = () => {
        if (hasEditPermission) {
            setImageModalOpen(true);
        }
    };

    // Firebase Storageに画像をアップロードし、そのURLを取得してFirestoreに保存する関数
    const handleImageSave = async (selectedFile) => {
        if (!selectedFile) return;

        // const storage = getStorage();
        // const firestore = getFirestore();
        // const storageRef = ref(storage, `images/${selectedFile.name}`);
        // const docRef = doc(firestore, 'images', selectedFile.name); // Firestoreのドキュメント参照

        try {
            // 1. Firebase Storageに画像をアップロード
            // const snapshot = await uploadBytes(storageRef, selectedFile);

            // 2. アップロードされた画像のURLを取得
            // const url = await getDownloadURL(snapshot.ref);

            // 3. 取得したURLをFirestoreに保存
            // await setDoc(docRef, { url, name: selectedFile.name });

            // 成功のメッセージを表示
            showSnackbar({ message: "画像が更新されました", type: 'success', title: '更新成功' });
        } catch (error) {
            // エラー処理
            console.error("Error uploading image: ", error);
            showSnackbar({ message: "画像の更新に失敗しました", type: 'error', title: '更新失敗' });
        }
    };

    const handleButtonClick = async (textFieldId, valueLabel) => {
        let requestData = {};
        if (textFieldId === 'catchphrase') {
            requestData = {
                text: "この企業の魅力を一言で表すキャッチコピーを生成してください。",
                company_id: companyId,
                company_name: companyInfo?.name,
                messages: [
                    { "role": "system", "content": `あなたは${companyInfo.name}の採用ページを作成するライターです` },
                    { "role": "system", "content": "求職者が入社したいと思えるような会社であることを宣伝する簡潔な文言を作成します。" },
                    { "role": "system", "content": "会社名は含めないようにしてください。" },
                    { "role": "system", "content": 'ダブルクオテーションでキャッチフレーズを挟まない' },
                    { "role": "assistant", "content": "combined_prompt_placeholder" },
                    { "role": "user", "content": "user_input_placeholder" }
                ]
            };
        } else {
            requestData = {
                text: `この企業の大切にしている価値観の1つは${valueLabel}です．この価値観についての説明文を書いてください。`,
                company_id: companyId,
                company_name: companyInfo?.name,
                messages: [
                    { "role": "system", "content": `あなたは${companyInfo.name}の採用ページを作成するライターです` },
                    { "role": "system", "content": "求職者が入社したいと思えるような会社であることを宣伝する簡潔な文言を作成します。" },
                    { "role": "system", "content": "会社名は含めないようにしてください。" },
                    { "role": "system", "content": 'ダブルクオテーションで生成文を挟まない' },
                    { "role": "assistant", "content": "combined_prompt_placeholder" },
                    { "role": "user", "content": '80文字程度にしてください' },
                    { "role": "user", "content": "user_input_placeholder" }
                ]
            };
        }

        const responses = await postData(requestData);
        if (!isLoading) {
            if (textFieldId === 'catchphrase') {
                setFormData(prev => ({ ...prev, [textFieldId]: responses?.response?.choices[0]?.message?.content })); // API応答をcatchphraseに保存
            } else {
                handleChangeValues({ type: 'description', index: parseInt(textFieldId.replace('value', '')) - 1, value: responses?.response?.choices[0]?.message?.content });
            }
        } else {
        }
    };

    const [focusStatus, setFocusStatus] = useState({
        'catchphrase': false,
        'value1': false,
        'value2': false,
        'value3': false
    });// フォーカス状態を管理するためのステート（キーはテキストフィールドのID、値はブール値）

    const handleFocus = (id) => {
        setFocusStatus(prev => ({ ...prev, [id]: true }));
    };

    const handleBlur = (id) => {
        setFocusStatus(prev => ({ ...prev, [id]: false }));
    };

    return (
        <>
            <ImageUploadModal
                open={imageModalOpen}
                handleClose={() => setImageModalOpen(false)}
                handleSave={handleImageSave}
            />

            <NavigationBar />

            <ResponsiveContainer>
                <CompanyTopNavigationContent hasEditPermission={hasEditPermission} company={companyInfo} />

                <Box sx={{
                    maxWidth: '100%',
                    maxHeight: 400,
                    overflow: 'hidden',
                    marginX: { xs: 0, sm: 0 }, // パディングを設定
                    // borderRadius: {xs: '8px', sm: '0px'}, // カードの角を丸める
                    cursor: hasEditPermission ? 'pointer' : 'default', // 編集権限がある場合のみポインターに変更
                }}
                    onClick={handleCardClick}
                >
                    <Box
                        component="img"
                        src={imageUrl || companyInfo?.imagePath}
                        alt="サムネイル"
                        sx={{
                            width: '100%', // 幅を100%に設定
                            height: { xs: 200, sm: 400 }, // 高さを固定値に設定
                            borderRadius: '8px', // カードの角を丸める
                            objectFit: 'cover', // コンテナを覆うように画像を調整
                            objectPosition: 'center', // 画像の中心をコンテナの中心に合わせる
                            transition: 'transform .5s ease', // トランジション効果を追加
                            '&:hover': {
                                transform: hasEditPermission ? 'scale(1.05)' : 'none', // 編集権限がある場合のみズームイン効果を適用
                                boxShadow: '0 4px 20px rgba(0,0,0,0.5)', // オプションで影を強調
                            },
                        }}
                    />
                </Box>
            </ResponsiveContainer>

            <Container maxWidth="md" sx={{ mt: { xs: 2, sm: 6 }, mb: { xs: 19, sm: 4 } }}>
                {hasEditPermission ? (
                    // 編集権限がある場合、編集可能なフィールドを表示
                    <Box sx={{ position: 'relative', width: '100%' }}>
                        <SaveOnBlurTextField
                            initialValue={formData?.catchphrase || companyInfo?.catchphrase}
                            onSave={(value) => setFormData(prev => ({ ...prev, catchphrase: value }))} // 保存時の処理を設定
                            onFocus={() => handleFocus('catchphrase')}
                            onBlur={() => handleBlur('catchphrase')}
                            TypographyProps={{
                                variant: "h3",
                                sx: { fontWeight: "bold" },
                                fontSize: { xs: '1.5rem', sm: '3rem' }
                            }}
                        />
                        {!focusStatus?.catchphrase && (
                            <Tooltip title={ planName === 'スタンダードプラン' ? 'AIで生成' : 'このプランでは使えません'} placement="top" arrow
                                slotProps={{
                                    popper: {
                                        sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginTop: '0px',
                                            },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginBottom: '6px',
                                            },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginLeft: '0px',
                                            },
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                            {
                                                marginRight: '0px',
                                            },
                                        },
                                    },
                                }}>

                                <LoadingButton
                                    onClick={planName === 'スタンダードプラン' ? (() => { handleButtonClick('catchphrase') }) : null}
                                    variant="contained"
                                    sx={{
                                        position: 'absolute',
                                        right: 0,  // テキストフィールドの右端にボタンを配置
                                        bottom: 0,  // テキストフィールドの中央に来るように調整
                                        borderRadius: 5,
                                        padding: 1,
                                        minWidth: 0,
                                        bgcolor: planName === 'スタンダードプラン' ? 'bgcolor.primary' : 'text.disabled',
                                        '&:hover': {
                                            bgcolor: planName === 'スタンダードプラン' ? 'primary.dark' : 'text.secondary',  // ホバー時の背景色
                                        }
                                    }}
                                    loading={isLoading}
                                >
                                    <NotesIcon fontSize='small' />
                                </LoadingButton>
                            </Tooltip>
                        )}
                    </Box>
                ) : (
                    // 編集権限がない場合、ただのテキストとして表示
                    <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', fontSize: { xs: '1.7rem', sm: '3rem' } }}>
                        {formData?.catchphrase || companyInfo?.catchphrase}
                    </Typography>
                )}

                {hasEditPermission ? (
                    <SaveOnBlurTextField
                        initialValue={formData?.companyDetail || companyInfo?.companyDetail}
                        onSave={(value) => setFormData(prev => ({ ...prev, companyDetail: value }))} // 保存時の処理を設定
                        TypographyProps={{
                            variant: "body1",
                            sx: { fontWeight: "bold" }
                        }}
                        multiline={true}
                        rows={6}
                        maxRows={6}
                        greyBackground={true}
                    />
                ) : (
                    <Typography variant="body1" sx={{ mt: { xs: 2, sm: 4 } }}>
                        {formData?.companyDetail || companyInfo?.companyDetail}
                    </Typography>
                )}

                <Box sx={{ mt: 12 }}>
                    <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 'bold', mb: 1 }}>価値観</Typography>
                    <Divider />

                    <ValueTable
                        hasEditPermission={hasEditPermission}
                        onSave={handleChangeValues}
                        companyValues={formData?.values}
                        handleButtonClick={handleButtonClick}
                        focusStatus={focusStatus}
                        isLoading={isLoading}
                        planName={planName}
                    />
                </Box>

                <CompanyInfoList hasEditPermission={hasEditPermission} company={companyInfo} companyId={companyId} />
                {hasEditPermission && (
                    <LeftBottomFloatBtn handleSubmit={handleSubmit} disabled={!isFormChanged} />
                )}

                {/* {!hasEditPermission && (
                    <ScrollFooterBar
                        avatarSrc="/static/images/avatar/1.jpg"
                        companyName={companyInfo?.name}
                        companyId={companyId}
                        message="この会社に共感したら、担当者と話してみませんか？"
                    />
                )} */}

            </Container >
            <Footer />
        </>
    );
}

export default CompanyHome;

