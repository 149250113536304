import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, RadioGroup, FormControlLabel, Radio, TextField, Typography } from '@mui/material';

function ApplicantContactModal({ isOpen, setIsOpen, companyId }) {
    const [selectedOption, setSelectedOption] = useState('');
    const [text, setText] = useState('');

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        handleClose(); // ダイアログを閉じる
    };

    return (
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>採用担当へ直接その気持ちを伝えよう</DialogTitle>
                <DialogContent>
                    <RadioGroup
                        value={selectedOption}
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        <FormControlLabel value="visit" control={<Radio />} label="職場見学してみたい" />
                        <FormControlLabel value="intern" control={<Radio />} label="インターンしてみたい" />
                        <FormControlLabel value="talk" control={<Radio />} label="話を聞いてみたい" />
                    </RadioGroup>
                    <TextField
                        margin="dense"
                        label="追加のコメント"
                        type="text"
                        fullWidth
                        variant="outlined"
                        multiline
                        rows={4}
                        inputProps={{ maxLength: 200 }}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        
                    />
                    <Typography variant="caption" display="block" style={{ textAlign: 'right' }}>
                        {text.length}/200
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>キャンセル</Button>
                    <Button onClick={handleSubmit} variant="contained">送信</Button>
                </DialogActions>
            </Dialog>
    );
}

export default ApplicantContactModal;
