import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { firestore } from '../../firebase';
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';


// 疑似ランダムID生成関数
const generateRandomId = (companyId) => {
    return companyId + uuidv4();
};

const AuthIdDialog = ({ companyId, open, onClose }) => {
    const [newRole, setNewRole] = useState('');

    const authId = generateRandomId(companyId); // authIdを自動生成
    const handleAddAuthId = async () => {
        if (!companyId) return;

        const companyRef = doc(firestore, 'companies', companyId);
        const authIdsCollectionRef = collection(companyRef, 'authIds');
        try {
            await addDoc(authIdsCollectionRef, {
                authId,
                role: newRole,
                usedCount: 0,
                createdAt: serverTimestamp(),
                isDeleted: false
            });
            setNewRole('');
            onClose(); // ダイアログを閉じる
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>認証IDの管理</DialogTitle>
            <DialogContent>
                <TextField
                    label="認証ID"
                    fullWidth
                    value={authId}
                    disabled
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="role-select-label">役割</InputLabel>
                    <Select
                        labelId="role-select-label"
                        id="role-select"
                        value={newRole}
                        label="役割"
                        onChange={(e) => setNewRole(e.target.value)}
                    >
                        <MenuItem value={'admin'}>管理者</MenuItem>
                        <MenuItem value={'editor'}>編集ユーザー</MenuItem>
                        <MenuItem value={'chat_user'}>チャットユーザー</MenuItem>
                        <MenuItem value={'viewer'}>閲覧ユーザー</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleAddAuthId}>追加</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AuthIdDialog;
