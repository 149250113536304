import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { set } from 'react-hook-form';
import { useCompany } from '../../Context/CompanyContext';

const EditCompanyModal = ({ open, handleClose, handleSave }) => {
    const [info, setInfo] = useState();
    const [daysInMonth, setDaysInMonth] = useState([]);

    const { company } = useCompany();

    const handleChange = (e) => {
        const { name, value } = e.target;
        // info内の対応するフィールドを更新
        setInfo(prev => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        setInfo(company);
    }, [company]);

    // 選択された年月に基づいて、その月の日数を更新
    useEffect(() => {
        const year = info?.establishYear;
        const month = info?.establishMonth;
        if (month && year) {
            const days = new Date(year, month, 0).getDate();
            setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
            // 現在選択されている日がその月に存在しない場合はリセット
            if (info?.establishDay > days) {
                setInfo(prev => ({ ...prev, establishDay: '' }));
            }
        }
    }, [info]);

    // 年のセレクトボックス
    const YearSelect = () => (
        <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>年</InputLabel>
            <Select name="establishYear" value={info?.establishYear || ''} label="年" onChange={handleChange}>
                {Array.from({ length: 11 }, (_, i) => 2020 + i).map((year) => (
                    <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    // 月のセレクトボックス
    const MonthSelect = () => (
        <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>月</InputLabel>
            <Select name="establishMonth" value={info?.establishMonth || ''} label="月" onChange={handleChange}>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                    <MenuItem key={month} value={month}>{month}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    // 日のセレクトボックス
    const DaySelect = () => (
        <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>日</InputLabel>
            <Select name="establishDay" value={info?.establishDay || ''} label="日" onChange={handleChange}>
                {daysInMonth.map((day) => (
                    <MenuItem key={day} value={day}>{day}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>会社情報を編集</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    会社情報を編集してください。
                </DialogContentText>
                <TextField autoFocus margin="dense" name="name" label="会社名" type="text" fullWidth variant="outlined" value={info?.name || ''} onChange={handleChange} sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
                    <Typography>設立年月</Typography>
                    <YearSelect />
                    <MonthSelect />
                    <DaySelect />
                </Box>
                <TextField margin="dense" name="employeeCount" label="メンバー数" type="number" fullWidth variant="outlined" value={info?.employeeCount || ''} onChange={handleChange} sx={{ mb: 2 }} />
                {/* <TextField margin="dense" name="international" label="海外進出" type="text" fullWidth variant="outlined" value={info.international || ''} onChange={handleChange} sx={{ mb: 2 }}  /> */}
                <TextField margin="dense" name="address" label="住所" type="text" fullWidth variant="outlined" value={info?.address || ''} onChange={handleChange} sx={{ mb: 2 }} />
                <TextField margin="dense" name="websiteUrl" label="ウェブサイト" type="url" fullWidth variant="outlined" value={info?.websiteUrl || ''} onChange={handleChange} sx={{ mb: 2 }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>キャンセル</Button>
                <Button onClick={() => handleSave(info)}>保存</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCompanyModal;