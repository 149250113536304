// React and React Router imports
import React, { useState, useEffect } from 'react';

// MUI components and icons
import {
    Box, Button, Dialog, DialogContent, Container,
    FormControl, Checkbox,
    InputLabel, MenuItem, Select, TextField, Typography, Autocomplete, Chip,
    Step, StepLabel, Stepper, StepContent
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// Firebase imports
import { firestore, auth } from '../../firebase';
// import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { doc, updateDoc, getDocs, query, collection, where, serverTimestamp, addDoc, Timestamp } from "firebase/firestore";
import { EmailAuthProvider, linkWithCredential } from 'firebase/auth';

// Hook form imports
import { useForm, Controller } from 'react-hook-form';

// Utils and helpers
import { getFirebaseErrorMessage } from '../../utils/firebaseErrors';
import { ASPIRATIONS_OPTIONS, INTERESTING_WORK_CATEGORY_OPTIONS, INTERESTING_LEARN_CATEGORY_OPTIONS } from '../../utils/options';

// Contexts
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';

import GifteeImage from '../../assets/images/9brands_pattern3_600x600.png'
import RegistrationDialog from '../Input/RegistrationDialog';

import { Player } from '@lottiefiles/react-lottie-player';

import { gifteeMailTemplate } from '../../utils/template';

import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LockIconOutlined from '@mui/icons-material/LockOutlined';
import PhoneIconOutlined from '@mui/icons-material/PhoneOutlined';
import {
    RadioGroup, FormControlLabel, Radio, FormLabel, Grid, InputAdornment,
} from '@mui/material';
import { EDUCATION_OPTIONS } from '../../utils/options';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ja from 'dayjs/locale/ja';
import { calculateAge } from '../../utils/helpers/calculateAge';

const isOidcLineWithEmail = (auth && auth?.currentUser?.providerData[0].providerId === 'oidc.line');

// メッセージ配列
let messages = [];
if (isOidcLineWithEmail) {
    messages = [
        "giftee Boxを送信するためにメールアドレス、パスワード、電話番号、苗字、名前、生年月日、性別を入力してください",
        "キャッチフレーズを設定すると平均10%程度スカウトをもらいやすくなります",
        "自己紹介は企業の採用担当者の70%が重視しています",
        "就職か進学かを選択することでスカウトをもらいやすくなります",
        "これからしたいと思っていること全て選択しましょう"
    ];
} else {
    messages = [
        "キャッチフレーズを設定すると平均10%程度スカウトをもらいやすくなります",
        "自己紹介は企業の採用担当者の70%が重視しています",
        "就職か進学かを選択することでスカウトをもらいやすくなります",
        "これからしたいと思っていること全て選択しましょう"
    ];
}

function DisplayMessage({ index }) {
    const message = messages[index] || "メッセージが見つかりません";
    return <Typography sx={{ mb: 2 }}>{message}</Typography>;
}

function GifteeCampaignDialog({ open, onClose, onOpen }) {
    const { user } = useUser();
    const [activeStep, setActiveStep] = useState(0);
    const [isStepValid, setIsStepValid] = useState(false);
    const [step, setStep] = useState(0);
    const [showRegistrationDialog, setShowRegistrationDialog] = useState(false);
    const [animation, setAnimation] = useState('/cracker.json');
    const { register, handleSubmit, control, formState, watch, getValues } = useForm({
        mode: "onChange",
        defaultValues: {
            email: user?.email || '',
            password: '',
            phoneNumber: user?.phoneNumber || '',
            lastName: user?.lastName || '',
            firstName: user?.firstName || '',
            userType: user?.userType || '',
            birthDate: user?.birthDate || null,
            gender: user?.gender || '',
            catchphrase: user?.catchphrase || '',
            profile: user?.profile || '',
            aspirations: user?.aspirations || '',
            interestField: user && 'interestField' in user ? user.interestField.map(item => ({ value: item, label: item })) : [],
        }
    });
    const { errors } = formState;
    // 関数の取得
    const { showSnackbar } = useSnackbar();

    // watch関数を使用して各フィールドを監視
    const catchphrase = watch("catchphrase");
    const profile = watch("profile");
    const aspirations = watch("aspirations");
    const interestField = watch("interestField");

    useEffect(() => {
        if (isOidcLineWithEmail) {
            setActiveStep(0);
        } else {
            setActiveStep(1);
        }
    }, []);

    // ステップの有効性を確認
    useEffect(() => {
        const values = getValues();
        let valid = true;

        // 条件に合わせてステップ0をスキップ
        // const adjustedStep = isOidcLineWithEmail ? activeStep + 1 : activeStep;

        if (isOidcLineWithEmail) {
            switch (activeStep) {
                case 0:
                    // step0 の有効性チェック（メールアドレス、パスワード、電話番号などの検証）
                    valid = Boolean(
                        values.email &&
                        /^\S+@\S+\.\S+$/.test(values.email) && // メールアドレスのパターンチェック
                        values.password &&
                        values.password.length >= 6 && // パスワードが6文字以上か
                        values.phoneNumber &&
                        /^(\+?\d[.]*){7,13}$/.test(values.phoneNumber) && // 電話番号のパターンチェック
                        values.lastName &&
                        values.firstName &&
                        values.userType &&
                        values.birthDate && // 生年月日が選択されているか
                        values.gender
                    );
                    break;
                case 1:
                    valid = Boolean(values.catchphrase);
                    break;
                case 2:
                    valid = Boolean(values.profile);
                    break;
                case 3:
                    valid = Boolean(values.aspirations);
                    break;
                case 4:
                    valid = values.interestField.length > 0;
                    break;
                default:
                    valid = false;
            }
        } else {
            switch (activeStep) {
                case 0:
                    valid = Boolean(values.catchphrase);
                    break;
                case 1:
                    valid = Boolean(values.profile);
                    break;
                case 2:
                    valid = Boolean(values.aspirations);
                    break;
                case 3:
                    valid = values.interestField.length > 0;
                    break;
                default:
                    valid = false;
            }
        }

        setIsStepValid(valid);
    }, [activeStep, catchphrase, profile, aspirations, interestField, getValues]);

    const onSubmit = async (data) => {
        try {
            const userDocRef = doc(firestore, "users", user?.uid);

            // Firestoreにユーザー情報を保存
            const interestFieldName = data.aspirations === 'education' ? 'interestsEducationTags' : 'interestsEmploymentTags';
            const interestFieldData = data.interestField.map(item => item.value);

            if (isOidcLineWithEmail) {
                await updateDoc(userDocRef, {
                    catchphrase: data.catchphrase,
                    profile: data.profile,
                    aspirations: data.aspirations,
                    [interestFieldName]: interestFieldData,
                    updatedAt: serverTimestamp(),
                    hasCatchphrase: true,
                    hasProfile: true,
                    hasGoals: true,
                    isCampaignParticipant: true,
                });
            } else {
                const birthDateTimestamp = Timestamp.fromDate(new Date(data.birthDate));
                // EmailAuthProviderのcredentialメソッドを使用して、メールアドレスとパスワードを使用して認証情報を作成(authにメールアドレス認証を追加)
                const credential = EmailAuthProvider.credential(data.email, data.password);
                await linkWithCredential(auth?.currentUser, credential);
                // ユーザー情報を更新
                await updateDoc(userDocRef, {
                    email: data.email, // 追加されたメールアドレス
                    phoneNumber: data.phoneNumber, // 追加された電話番号
                    firstName: data.firstName, // 追加された名前
                    lastName: data.lastName, // 追加された苗字
                    birthDate: birthDateTimestamp, // 追加された生年月日
                    age: calculateAge(new Date(data.birthDate)), // 追加された年齢
                    gender: data.gender, // 追加された性別
                    userType: data.userType, // 追加された種別
                    catchphrase: data.catchphrase,
                    profile: data.profile,
                    aspirations: data.aspirations,
                    [interestFieldName]: interestFieldData,
                    updatedAt: serverTimestamp(),
                    hasCatchphrase: true,
                    hasProfile: true,
                    hasGoals: true,
                    isCampaignParticipant: true,
                });
            }

            // isUsed=falseのcampaignコレクションのドキュメントを取得
            const campaignQuery = query(collection(firestore, "campaign"), where("isUsed", "==", false));
            const campaignSnapshot = await getDocs(campaignQuery);

            if (!campaignSnapshot.empty) {
                const campaignDoc = campaignSnapshot.docs[0];  // 最初のドキュメントを取得
                const campaignData = campaignDoc.data();
                const gifteeBoxUrl = campaignData.giftee_box_url; // URLの取得

                // userコレクションにisUsedCampaignとしてIDを保存
                await updateDoc(userDocRef, {
                    isUsedCampaign: campaignDoc.id
                });

                // campaignコレクションのドキュメントを更新してisUsed=trueにする
                await updateDoc(campaignDoc.ref, {
                    isUsed: true
                });

                // mailコレクションにメール送信のためのドキュメントを追加
                await addDoc(collection(firestore, "mail"), {
                    toUids: [user?.uid],
                    // message: {
                    //     subject: "キャンペーンURLのご案内",
                    //     text: `以下のURLからgiftee Boxを受け取れます: ${campaignData.giftee_box_url}`,
                    // }
                    message: {
                        subject: '【Ciel】giftee Boxをお届けします',
                        html: gifteeMailTemplate(gifteeBoxUrl)
                    }
                });
            } else {
                throw new Error('No available campaign found.');
            }

        } catch (error) {
            const errorMessage = getFirebaseErrorMessage({
                defaultErrorMessage: 'アカウントの更新に失敗しました。もう一度試してください。',
                errorCode: error.code
            });
            showSnackbar({ message: errorMessage, type: 'error', title: '登録失敗' });
        } finally {
            setStep(2);
        }
    };

    const handleNext = () => {
        const values = getValues(); // 現在のフォームの値を取得
        let isValidStep = true; // ステップが有効かどうかを保持する変数

        // 現在のアクティブステップに応じてバリデーションを実行
        if (isOidcLineWithEmail) {
            switch (activeStep) {
                case 0:
                    if (!values.email || !/^\S+@\S+\.\S+$/.test(values.email)) {
                        isValidStep = false;
                        showSnackbar({ message: '有効なメールアドレスを入力してください。', type: 'error' }); // エラーメッセージを表示
                    }
                    if (!values.password || values.password.length < 6) {
                        isValidStep = false;
                        showSnackbar({ message: 'パスワードは6文字以上である必要があります。', type: 'error' });
                    }
                    if (!values.phoneNumber || !/^(\+?\d[- .]*){7,13}$/.test(values.phoneNumber)) {
                        isValidStep = false;
                        showSnackbar({ message: '有効な電話番号を入力してください。(ハイフンなし)', type: 'error' });
                    }
                    if (!values.lastName) {
                        isValidStep = false;
                        showSnackbar({ message: '苗字を入力してください。', type: 'error' });
                    }
                    if (!values.firstName) {
                        isValidStep = false;
                        showSnackbar({ message: '名前を入力してください。', type: 'error' });
                    }
                    if (!values.userType) {
                        isValidStep = false;
                        showSnackbar({ message: '種別を選択してください。', type: 'error' });
                    }
                    if (!values.birthDate) {
                        isValidStep = false;
                        showSnackbar({ message: '生年月日を選択してください。', type: 'error' });
                    }
                    if (!values.gender) {
                        isValidStep = false;
                        showSnackbar({ message: '性別を選択してください。', type: 'error' });
                    }
                    break;
                case 1:
                    if (!values.catchphrase) {
                        isValidStep = false;
                        showSnackbar({ message: 'キャッチフレーズを入力してください。', type: 'error' });
                    }
                    break;
                case 2:
                    if (!values.profile) {
                        isValidStep = false;
                        showSnackbar({ message: '自己紹介を入力してください。', type: 'error' });
                    }
                    break;
                case 3:
                    if (!values.aspirations) {
                        isValidStep = false;
                        showSnackbar({ message: '今後の予定を選択してください。', type: 'error' });
                    }
                    break;
                case 4:
                    if (values.interestField.length === 0) {
                        isValidStep = false;
                        showSnackbar({ message: '興味のある分野を選択してください。', type: 'error' });
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (activeStep) {
                case 0:
                    if (!values.catchphrase) {
                        isValidStep = false;
                        showSnackbar({ message: 'キャッチフレーズを入力してください。', type: 'error' });
                    }
                    break;
                case 1:
                    if (!values.profile) {
                        isValidStep = false;
                        showSnackbar({ message: '自己紹介を入力してください。', type: 'error' });
                    }
                    break;
                case 2:
                    if (!values.aspirations) {
                        isValidStep = false;
                        showSnackbar({ message: '今後の予定を選択してください。', type: 'error' });
                    }
                    break;
                case 3:
                    if (values.interestField.length === 0) {
                        isValidStep = false;
                        showSnackbar({ message: '興味のある分野を選択してください。', type: 'error' });
                    }
                    break;
                default:
                    break;
            }
        }

        if (isValidStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1); // 次のステップへ進む
        }
    };


    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    let steps = []
    if (isOidcLineWithEmail) {
        steps = [
            {
                label: '基本情報を入力',
                description: (
                    <>
                        <TextField
                            label="メールアドレス"
                            variant="outlined"
                            type="email"
                            fullWidth
                            margin="normal"
                            {...register("email", { required: "メールアドレスは必須です", pattern: /^\S+@\S+\.\S+$/ })}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIconOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            label="パスワード（6文字以上）"
                            variant="outlined"
                            type="password"
                            fullWidth
                            margin="normal"
                            {...register("password", { required: "パスワードは必須です", minLength: { value: 6, message: "パスワードは6文字以上である必要があります" } })}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIconOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            label="電話番号（ハイフンなし）"
                            variant="outlined"
                            fullWidth
                            type="tel"
                            margin="normal"
                            {...register("phoneNumber", {
                                required: "電話番号は必須です",
                                pattern: {
                                    value: /^(\+?\d[- .]*){7,13}$/,
                                    message: "無効な電話番号です"
                                }
                            })}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneIconOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid container spacing={2}> {/* コンテナGrid */}
                            <Grid item xs={6}> {/* 各TextFieldをラップするGridアイテム */}
                                <TextField
                                    label="苗字"
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    margin="normal"
                                    {...register("lastName", { required: "苗字は必須です" })}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                />
                            </Grid>
                            <Grid item xs={6}> {/* 各TextFieldをラップするGridアイテム */}
                                <TextField
                                    label="名前"
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="normal"
                                    {...register("firstName", { required: "名前は必須です" })}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                />
                            </Grid>
                        </Grid>

                        <FormControl fullWidth margin="normal">
                            <InputLabel id="userType-label">種別</InputLabel>
                            <Controller
                                name="userType"
                                control={control}
                                rules={{ required: "種別を選択してください" }}
                                render={({ field }) => (
                                    <Select
                                        labelId="userType-label"
                                        id="userType"
                                        label="種別"
                                        {...field}
                                    >
                                        <MenuItem value="">
                                            <em>未選択</em>
                                        </MenuItem>
                                        {EDUCATION_OPTIONS.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.userType && <Typography color="error">{errors.userType.message}</Typography>}
                        </FormControl>


                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={ja}
                            dateFormats={{
                                monthAndYear: 'YYYY年MM月',
                                normalDate: 'YYYY年MM月DD日',
                                keyboardDate: 'YYYY年MM月DD日',
                            }}
                            localeText={{
                                previousMonth: '前へ',
                                nextMonth: '次へ',
                                cancelText: "キャンセル",
                                okText: "選択",
                            }}
                        >
                            <FormControl component="fieldset" margin="normal" fullWidth>
                                <Controller
                                    name="birthDate"
                                    control={control}
                                    rules={{ required: "生年月日は必須です" }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <DatePicker
                                            views={['year', 'month', 'day']}
                                            openTo="year" // 年から選べるようにする
                                            format="YYYY年MM月DD日" // input後のform内の表示を変える
                                            label="生年月日"
                                            value={value}
                                            onChange={onChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    error={!!error}
                                                    helperText={error ? error.message : null}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            )}
                                            DialogProps={{
                                                sx: {
                                                    '& .PrivatePickersToolbar-dateTitleContainer .MuiTypography-root': {
                                                        fontSize: '1.5rem',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </LocalizationProvider>

                        <FormControl component="fieldset" margin="normal" fullWidth>
                            <FormLabel component="legend">性別</FormLabel>
                            <Controller
                                name="gender"
                                control={control}
                                rules={{ required: "性別を選択してください" }}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel value="male" control={<Radio />} label="男性" />
                                        <FormControlLabel value="female" control={<Radio />} label="女性" />
                                        <FormControlLabel value="other" control={<Radio />} label="その他" />
                                    </RadioGroup>
                                )}
                            />
                            {errors.gender && <Typography color="error">{errors.gender.message}</Typography>}
                        </FormControl>
                    </>
                ),
            },
            {
                label: '自分のキャッチフレーズを設定',
                description: (
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        name="catchphrase"
                        label="キャッチコピーや好きな言葉"
                        {...register("catchphrase", {
                            required: "キャッチコピーや好きな言葉を入力してください",
                        })}
                        error={Boolean(errors.catchphrase)}
                        helperText={errors.catchphrase?.message}
                    />
                ),
            },
            {
                label: '自己紹介を設定',
                description: (
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='profile'
                        label='自己紹介'
                        {...register('profile', {
                            required: '自己紹介を入力してください',
                        })}
                        error={Boolean(errors.profile)}
                        helperText={errors.profile?.message}
                        multiline
                        rows={3}
                    />
                ),
            },
            // 進路の予定を入力
            {
                label: '今後の予定を設定',
                description: (
                    <Controller
                        control={control} // useForm から destructuring した control を使用
                        name="aspirations"
                        rules={{ required: "今後の予定を選択してください" }}
                        render={({ field }) => (
                            <FormControl fullWidth error={Boolean(errors.aspirations)}>
                                <InputLabel id="aspirations-label">今後の予定</InputLabel>
                                <Select
                                    labelId="aspirations-label"
                                    label="今後の予定"
                                    {...field}
                                >
                                    {ASPIRATIONS_OPTIONS.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                ),
            },
            {
                label: '興味のある分野を設定',
                description: (
                    <Controller
                        name="interestField"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                multiple
                                disableCloseOnSelect
                                options={watch('aspirations') === 'education' ? INTERESTING_LEARN_CATEGORY_OPTIONS : INTERESTING_WORK_CATEGORY_OPTIONS}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(_, newValue) => field.onChange(newValue)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} color='primary' label={option.label} {...getTagProps({ index })} />
                                    ))
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={selected}
                                            style={{ marginRight: 8 }}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="興味のある分野"
                                        error={Boolean(errors.interestField)}
                                        helperText={errors.interestField ? '興味のある分野を選択してください' : null}
                                    />
                                )}
                            />
                        )}
                    />
                )
            },
            // Define additional steps here
        ];
    } else {
        steps = [
            {
                label: '自分のキャッチフレーズを設定',
                description: (
                    <TextField
                        variant="filled"
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        name="catchphrase"
                        label="キャッチコピーや好きな言葉"
                        {...register("catchphrase", {
                            required: "キャッチコピーや好きな言葉を入力してください",
                        })}
                        error={Boolean(errors.catchphrase)}
                        helperText={errors.catchphrase?.message}
                    />
                ),
            },
            {
                label: '自己紹介を設定',
                description: (
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='profile'
                        label='自己紹介'
                        {...register('profile', {
                            required: '自己紹介を入力してください',
                        })}
                        error={Boolean(errors.profile)}
                        helperText={errors.profile?.message}
                        multiline
                        rows={3}
                    />
                ),
            },
            // 進路の予定を入力
            {
                label: '今後の予定を設定',
                description: (
                    <Controller
                        control={control} // useForm から destructuring した control を使用
                        name="aspirations"
                        rules={{ required: "今後の予定を選択してください" }}
                        render={({ field }) => (
                            <FormControl fullWidth error={Boolean(errors.aspirations)}>
                                <InputLabel id="aspirations-label">今後の予定</InputLabel>
                                <Select
                                    labelId="aspirations-label"
                                    label="今後の予定"
                                    {...field}
                                >
                                    {ASPIRATIONS_OPTIONS.map((option, index) => (
                                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />
                ),
            },
            {
                label: '興味のある分野を設定',
                description: (
                    <Controller
                        name="interestField"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                multiple
                                disableCloseOnSelect
                                options={watch('aspirations') === 'education' ? INTERESTING_LEARN_CATEGORY_OPTIONS : INTERESTING_WORK_CATEGORY_OPTIONS}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                onChange={(_, newValue) => field.onChange(newValue)}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip key={index} color='primary' label={option.label} {...getTagProps({ index })} />
                                    ))
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                            checked={selected}
                                            style={{ marginRight: 8 }}
                                        />
                                        {option.label}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="興味のある分野"
                                        error={Boolean(errors.interestField)}
                                        helperText={errors.interestField ? '興味のある分野を選択してください' : null}
                                    />
                                )}
                            />
                        )}
                    />
                )
            },
            // Define additional steps here
        ];
    }

    return (
        <>
            <RegistrationDialog
                open={showRegistrationDialog}
                onClose={() => setShowRegistrationDialog(false)}
                afterAction={() => {
                    setStep(0);
                    onOpen();
                }}
            />

            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogContent>
                    <Container component="main" sx={{ my: { xs: 1, sm: 2 } }}>
                        {step === 0 && (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography component="h1" variant="h4" align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
                                        条件を達成して<br />giftee Boxを獲得
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <Box
                                        component="img"
                                        src={GifteeImage}
                                        alt="giftee Box"
                                        sx={{
                                            width: { xs: '100%', sm: '90%' },
                                            maxWidth: '600px'
                                        }}
                                    />
                                </Box>
                                {!user ? (
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setShowRegistrationDialog(true);
                                            onClose();
                                        }}
                                        fullWidth
                                        sx={{ mt: 2, borderRadius: '50px', py: 2, fontSize: { xs: '1rem', sm: '1.25rem' }, fontWeight: 'bold' }}
                                        disabled={user}
                                    >
                                        まずはログイン
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={() => setStep(1)}
                                        fullWidth
                                        sx={{ mt: 2, borderRadius: '50px', py: 2, fontSize: { xs: '1rem', sm: '1.25rem' }, fontWeight: 'bold' }}
                                        disabled={!user}
                                    >
                                        さっそく始める
                                    </Button>
                                )}
                                <Typography component="h2" variant="body2" sx={{ mt: 2, textAlign: 'center', fontSize: { xs: '0.75rem', sm: '1rem' } }}>
                                    およそ3分ほどで入力は終わります。
                                </Typography>
                                {/* 注意書き */}
                                <Typography component="h2" variant="caption" sx={{ mt: 2, textAlign: 'left', fontSize: { xs: '0.5rem', sm: '0.75rem' } }}>
                                    本プロモーション・キャンペーンは株式会社Cielによる提供です。 本キャンペーンについてのお問い合わせはAmazonではお受けしておりません。 株式会社Ciel HPのお問い合わせフォームまでお願いいたします。<br />
                                    Amazon、Amazon.co.jp およびそれらのロゴは Amazon.com, Inc.またはその関連会社の商標です。
                                </Typography>
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <Typography component="h2" variant="h5" align="center" sx={{ mb: 2, fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                                    達成してgiftee Boxをもらおう
                                </Typography>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Stepper activeStep={activeStep} orientation="vertical">
                                        {steps.map((step, index) => (
                                            <Step key={step.label}>
                                                <StepLabel>{step.label}</StepLabel>
                                                <StepContent>
                                                    {DisplayMessage({ index })}
                                                    <Typography>{step.description}</Typography>
                                                    <Box sx={{ mb: 2 }}>
                                                        <div>
                                                            <Button
                                                                variant="contained"
                                                                onClick={index === steps.length - 1 ? handleSubmit(onSubmit) : handleNext}
                                                                sx={{ mt: 1, mr: 1, fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                                                disabled={!isStepValid}
                                                            >
                                                                {index === steps.length - 1 ? '保存' : '次へ'}
                                                            </Button>
                                                            <Button
                                                                disabled={index === 0}
                                                                onClick={handleBack}
                                                                sx={{ mt: 1, mr: 1, fontSize: { xs: '0.875rem', sm: '1rem' } }}
                                                            >
                                                                戻る
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </form>
                            </>
                        )}
                        {step === 2 && (
                            <Box sx={{ position: 'relative' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography component="h1" variant="h4" align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } }}>
                                        おめでとうございます！
                                    </Typography>
                                </Box>

                                <Player
                                    autoplay
                                    src={animation}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        zIndex: 0
                                    }}
                                    onEvent={(event) => {
                                        if (event === 'complete') {
                                            setAnimation('/confetti.json');
                                        }
                                    }}
                                />

                                <Typography component="h2" variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                                    メールでgiftee Boxをお送りしました。<br />
                                    お届けまでしばらくお待ちください。
                                </Typography>

                                {/* 注意書き */}
                                <Typography component="h2" variant="caption" sx={{ mt: 2, textAlign: 'center', fontSize: { xs: '0.5rem', sm: '0.75rem' } }}>
                                    ご不明な点やお問い合わせは、お気軽にHPのお問い合わせよりお問い合わせください。
                                </Typography>
                            </Box>
                        )}
                    </Container>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default GifteeCampaignDialog;