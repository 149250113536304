import React from 'react';
import { Box, Card, CardMedia, Typography } from '@mui/material';

const Contents = ({company}) => {
    return (
        <>
            <Typography variant="body1" color='black' sx={{ mt: 4 }}>
                {company?.companyDetail}
            </Typography>
            <Typography variant="h5" color='text.primary' sx={{ fontWeight: 'bold', mt: 7 }}>
                何をやっているのか？
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginY: 4 }}>
                <Card sx={{ maxWidth: '48%', maxHeight: 400, overflow: 'hidden' }}>
                    <CardMedia
                        component="img"
                        image="https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="サムネイル"
                        sx={{
                            width: '100%', // 幅を100%に設定
                            // height: 400, // 高さを固定値に設定
                            objectFit: 'cover', // コンテナを覆うように画像を調整
                            objectPosition: 'center' // 画像の中心をコンテナの中心に合わせる
                        }}
                    />
                </Card>
                <Card sx={{ maxWidth: '48%', maxHeight: 400, overflow: 'hidden' }}>
                    <CardMedia
                        component="img"
                        image="https://images.unsplash.com/photo-1557804506-669a67965ba0?q=80&w=4374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        alt="サムネイル"
                        sx={{
                            width: '100%', // 幅を100%に設定
                            // height: 400, // 高さを固定値に設定
                            objectFit: 'cover', // コンテナを覆うように画像を調整
                            objectPosition: 'center' // 画像の中心をコンテナの中心に合わせる
                        }}
                    />
                </Card>
            </Box>
            <Typography variant="body1" color='black' sx={{ mt: 4 }}>
                {company?.whatWeDo}
            </Typography>

            <Typography variant="h5" color='text.primary' sx={{ fontWeight: 'bold', mt: 7 }}>
                なぜやっているのか？
            </Typography>
            <Typography variant="body1" color='black' sx={{ mt: 4 }}>
                {company?.whyWeDoIt}
            </Typography>

            <Typography variant="h5" color='text.primary' sx={{ fontWeight: 'bold', mt: 7 }}>
                どうやってやっているのか？
            </Typography>
            <Typography variant="body1" color='black' sx={{ mt: 4 }}>
                {company?.howWeDoIt}
            </Typography>

            {/* <Typography variant="h5" color='text.primary' sx={{ fontWeight: 'bold', mt: 7 }}>
                こんなことをやります
            </Typography> */}
            {/* <Typography variant="body1" color='black' sx={{ mt: 4 }}>
                
            </Typography> */}
        </>
    );
}

export default Contents;