import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const SaveOnBlurSelect = ({
    initialValue = '',
    onSave,
    options = [], // 選択肢の配列 [{ value: '', label: '' }]
    label = '', // セレクトボックスのラベル
    TypographyProps = {}, // TypographyPropsは直接使用されます
}) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        // initialValueが変更された時にvalueを更新する
        setValue(initialValue);
    }, [initialValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleBlur = () => {
        onSave(value);
        // ここでFirestoreに保存する処理を呼び出すなど
    };

    return (
        <FormControl variant="standard" sx={{
            ...TypographyProps.sx, width: 200,
        }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
            // ここでの追加のスタイル指定は不要
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SaveOnBlurSelect;
