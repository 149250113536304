// React and related hooks
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Material-UI core components and utilities
import {
    AppBar, Box, Toolbar, Typography, IconButton, Drawer,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Divider, Button, Menu, MenuItem, Avatar, Link as MuiLink,
    useMediaQuery, styled, useTheme
} from '@mui/material';

// Material-UI icons
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Context or hooks imports
import { useUser } from '../../Context/UserContext';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
}));

const AppBarShift = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

export default function NavigationBar() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [anchorEl, setAnchorEl] = useState(null);

    const { user, signOut } = useUser();
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        signOut(); // ログアウト関数の呼び出し
        handleClose(); // メニューを閉じる
    };

    // メニューアイテムの生成
    const getMenuItems = () => {
        let baseItems = [
            { text: '企業一覧', icon: <HomeIcon />, path: '/' },
            { text: 'Chat', icon: <ChatIcon />, path: '/chat/null' },
            { text: 'プロフィール', icon: <PersonIcon />, path: `/profile/${user?.documentId}` },
        ];

        // ユーザータイプに応じて追加のアイテムを条件分岐で追加
        if (user?.userType === 'company') {
            baseItems = baseItems.concat([
                { text: 'ダッシュボード', icon: <DashboardIcon />, path: `/company/${user?.companyId}/dashboard` },
            ]);
        }
        // 他のユーザータイプに応じた条件分岐とメニューアイテムの追加をここに記述

        return baseItems;
    };

    const menuItems = getMenuItems();

    const handleClick = () => {
        if (user?.userType === 'company' && user?.companyId) {
            navigate(`/company/${user.companyId}/dashboard`);
        } else {
            navigate('/');
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBarShift position="fixed" open={open} color="inherit">
                <Toolbar variant={isSm ? 'regular' : "dense"}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        <MuiLink
                            component="button"
                            onClick={handleClick}
                            color="inherit"
                            underline="none"
                            style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
                        >
                            Ciel
                        </MuiLink>
                    </Typography>
                    {user ? (
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                {user.userIconUrl ? <Avatar src={user?.userIconUrl} sx={{ width: 32, height: 32 }} alt="UserIcon" /> : <AccountCircle />}
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose} component={RouterLink} to={`/profile/${user.documentId}`}>プロフィール</MenuItem>
                                <MenuItem onClick={handleClose} component={RouterLink} to={`/profile/edit/${user.documentId}`}>設定</MenuItem>
                                <MenuItem onClick={handleLogout}>ログアウト</MenuItem> {/* ログアウトボタンの追加 */}
                            </Menu>
                        </div>
                    ) : (
                        <>
                            <Button color="inherit" component={RouterLink} to="/signup">登録</Button>
                            <Button color="inherit" component={RouterLink} to="/login">ログイン</Button>
                        </>
                    )}
                </Toolbar>
            </AppBarShift>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {menuItems.map((item) => (
                        item.text !== 'プロフィール' && item.text !== 'Chat' ? (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton component={RouterLink} to={item.path} onClick={handleDrawerClose}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ) : (
                            user && (
                                <ListItem key={item.text} disablePadding>
                                    <ListItemButton component={RouterLink} to={item.path} onClick={handleDrawerClose}>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        )
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}

