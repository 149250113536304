import React, { useState, useEffect } from 'react';
import { IconButton, Button, Typography, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import Link from '@mui/material/Link';
import ModeIcon from '@mui/icons-material/Mode';

import HighlightCardEditModal from './HighlightCardEditModal';

const HighlightCardModal = ({ open, handleClose, onSave, data, category, onDelete, isDisabled }) => {
    const [openEdit, setOpenEdit] = useState(false);
    const [formData, setFormData] = useState({ ...data });

    useEffect(() => {
        setFormData({ ...data });
    }, [data]);

    const handleOpenEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="detail-modal-title"
                aria-describedby="detail-modal-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="detail-modal-title">{formData.title}</DialogTitle>
                <DialogContent dividers>
                    {formData?.url && (
                        <Typography variant="body1" gutterBottom>
                            URL: <Link href={formData?.url} target="_blank" rel="noopener noreferrer">{formData.url}</Link>
                        </Typography>
                    )}
                    {formData?.description && (
                        <Typography variant="body2" gutterBottom>
                            説明: {formData?.description}
                        </Typography>
                    )}
                    {formData?.date && (
                        <Typography variant="body2" gutterBottom>
                            期間: {formData?.date}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    {isDisabled && (
                        <IconButton onClick={handleOpenEdit} color="primary" aria-label="details">
                            <ModeIcon />
                        </IconButton>
                    )}
                    <Button onClick={handleClose} color="primary" variant='outlined'>
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <HighlightCardEditModal openEdit={openEdit} handleClose={handleCloseEdit} onSave={onSave} data={formData} modalType="更新" category={category}

                onDelete={onDelete} />
        </>
    );
};

export default HighlightCardModal;