// React and hooks
import React, { useState, useRef } from 'react';

// Third-party components and utilities
import AvatarEditor from 'react-avatar-editor';

// Material-UI components
import { Dialog, DialogContent, Stack, Box, Slider, Button } from '@mui/material';

// Firebase services and utilities
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';

// Local imports
import { firestore } from '../../firebase';


const ProfileImageUpload = ({ imageModalOpen, setImageModalOpen, image, width, height, savePath, collectionName, docId, saveUrlFieldName, saveUrlStateFieldName }) => {
    const [scale, setScale] = useState(1);
    const editorRef = useRef(null);

    const handleScaleChange = (event, newValue) => {
        setScale(newValue);
    };

    const handleImageSave = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas();
            canvas.toBlob(blob => {
                const storage = getStorage();
                const storageRef = ref(storage, savePath); // パスを適宜変更
                uploadBytes(storageRef, blob).then(snapshot => {
                    setImageModalOpen(false);
                    // アップロードした画像のURLを取得
                    getDownloadURL(snapshot.ref).then((downloadURL) => {
                        // Firestoreの企業ドキュメントに画像URLを保存
                        const companyDocRef = doc(firestore, collectionName, docId);

                        updateDoc(companyDocRef, {
                            [saveUrlFieldName]: downloadURL,  // ここで画像URLを保存
                            [saveUrlStateFieldName]: true
                        }).then(() => {
                            setImageModalOpen(false);
                        }).catch((error) => {
                            console.error("Error updating document: ", error);
                        });
                    }).catch((error) => {
                        console.error("Error getting download URL: ", error);
                    });
                }).catch((error) => {
                    console.error("Error uploading file to Firebase Storage: ", error);
                });
            });
        }
    };

    return (
        <Dialog
            open={imageModalOpen}
            onClose={() => setImageModalOpen(false)}
        >
            <DialogContent>
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
                    <Box sx={{ borderRadius: '8px', backgroundColor: 'white' }}>
                        <AvatarEditor
                            ref={editorRef}
                            image={image}
                            width={width}
                            height={height}
                            border={50}
                            borderRadius={height}  // 100% で完全な円形にする
                            color={[205, 205, 205, 0.6]} // RGBA
                            scale={scale}
                            backgroundColor={'#ddd'}
                        />
                    </Box>
                    <Slider
                        min={0.1}
                        max={2}
                        step={0.01}
                        value={scale}
                        onChange={handleScaleChange}
                        aria-labelledby="continuous-slider"
                    />
                    <Button onClick={handleImageSave} variant='contained' fullWidth>保存</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
};

export default ProfileImageUpload;