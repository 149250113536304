import React from "react";
// Router
import { BrowserRouter, Routes, Route } from "react-router-dom";
// Style
import CssBaseline from '@mui/material/CssBaseline';
import theme from "./utils/theme";
import './App.css';
// Pages
import CompanyList from "./page/CompanyList";
import CompanyDetail from "./page/CompanyDetail";
import Chat from "./page/Chat";
import CompanyHome from "./page/companyInfoPage/CompanyHome";
import CompanyNewsList from "./page/companyInfoPage/CompanyNewsList";
import Profile from "./page/Profile";
import ProfileEdit from "./page/ProfileEdit";
import AdminDashboard from "./page/admin/Dashboard";
import Dashboard from "./page/company/Dashboard";
import EditPage from "./page/company/EditPage";
import UserSearch from "./page/company/UserSearch";
import Login from "./page/auth/Login";
import Company from "./page/auth/Company";
import Student from "./page/auth/Student";
import CompanyOurs from "./page/companyInfoPage/CompanyOurs";
import ParentalAuthPage from "./page/auth/ParentalAuthPage";
import Learning from "./page/company/Learning";
import ResetPassword from "./page/auth/ResetPassword";
// Context
import { SnackbarProvider } from './Context/SnackbarContext';
import { UserProvider } from './Context/UserContext';
import { CompanyProvider } from './Context/CompanyContext';
import { TourProvider } from "./Context/TourContext";
import { ThemeProvider } from '@mui/material/styles';
// Components
import CustomSnackbar from './components/Notification/CustomSnackbar';
import ScrollToTop from './components/ScrollToTop';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import PrivateRoute from "./components/Layout/PrivateRoute";

import 'dayjs/locale/ja'; // 日本語ロケールをインポート
dayjs.locale('ja'); // dayjsのロケールを日本語に設定

function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<SnackbarProvider>
					<UserProvider>
						<CompanyProvider>
							<LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}
								localeText={{
									previousMonth: "前月を表示", // < のツールチップ
									nextMonth: "次月を表示", // > のツールチップ
								}}
							>
								<TourProvider>
									<CssBaseline />
									<ScrollToTop />
									<CustomSnackbar />
									<Routes>
										<Route path="/" element={<CompanyList />} />
										<Route path="/parental-auth/:docId" element={<ParentalAuthPage />} />
										<Route path="/post/:type/:id" element={<CompanyDetail />} />
										<Route element={<PrivateRoute allowedTypes={['company', 'admin', 'student', 'highSchool', 'vocationalSchool', 'university', 'graduateSchool', 'workingProfessional', 'other',]} />}>
											<Route path="/chat/:id" element={<Chat />} />
										</Route>


										<Route path="/company/:id/home" element={<CompanyHome />} />
										<Route path="/company/:id/ours" element={<CompanyOurs />} />
										<Route path="/company/:id/news" element={<CompanyNewsList />} />

										{/* <Route path="/company/recruitments" element={<CompanyNewsList />} /> */}

										<Route path="/profile/:id" element={<Profile />} />
										<Route path="/profile/edit/:id" element={<ProfileEdit />} />

										{/* Auth Routes */}
										<Route path="/login" element={<Login />} />
										<Route path="/auth/action" element={<ResetPassword />} />
										<Route path="/signup" element={<Student />} />
										<Route path="/company/signup" element={<Company />} />

										{/* Company Routes */}
										<Route element={<PrivateRoute allowedTypes={['company', 'admin']} />}>
											<Route path="/company/:id/dashboard" element={<Dashboard />} />
											<Route path="/company/edit" element={<EditPage />} />
											<Route path="/company/:id/search" element={<UserSearch />} />
											<Route path="/company/:id/learning" element={<Learning />} />
										</Route>

										{/* Admin Routes */}
										<Route element={<PrivateRoute allowedTypes={['admin']} />}>
											<Route path="/admin/dashboard" element={<AdminDashboard />} />
										</Route>
									</Routes>
								</TourProvider>
							</LocalizationProvider>
						</CompanyProvider>
					</UserProvider>
				</SnackbarProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
