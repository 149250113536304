import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../../Context/UserContext';
import { useSnackbar } from '../../Context/SnackbarContext';

const PrivateRoute = ({ allowedTypes }) => {
    const { user } = useUser();
    const { showSnackbar } = useSnackbar();

    if (!user) {
        // ログインしていなければログインページへリダイレクト
        showSnackbar({ message: "ログインしください", type: 'error' });
        return <Navigate to="/login" />;
    }

    if (allowedTypes && !allowedTypes.includes(user.userType)) {
        // ユーザータイプが許可されていなければトップページへリダイレクト
        showSnackbar({ message: "エラーが発生しました", type: 'error' });
        return <Navigate to="/" />;
    }

    return <Outlet />; // 子コンポーネントを表示
};

export default PrivateRoute;
